import React from 'react'
import { apiInstance } from '../../config/api'
import { IconHeart, IconPlus, IconThumbUp } from '@tabler/icons';
import { createStyles } from "@mantine/core";
import { notifications } from '@mantine/notifications';

const useStyles = createStyles((theme) => ({

    like: {
        color: theme.colors.red[6],
        backfaceVisibility: 'hidden',
        backgroundColor: theme.colors.red[0],
    },


}));

const FavouriteButton = ({ likedUserId, isFavorite, onToggleFavorite }) => {
    const { classes, theme } = useStyles();
    const handleToggleFavorite = async () => {
        try {
            if (isFavorite) {
                // Remove the user from favorites
                const response = await apiInstance.post('like/favourites/remove_from_favorites/', { liked_user_id: likedUserId });

                if (response.status === 201 || response.status === 200) {
                    // Update the state and trigger a callback
                    notifications.show({
                        title: 'Removed Tutor from Favourite',
                        message: 'Successfully removed',
                        color: 'red',
                        autoClose: true,
                    })

                }
            } else {
                // Add the user to favorites
                const response = await apiInstance.post('like/favourites/add_to_favorites/', { liked_user_id: likedUserId });
                if (response.status === 201 || response.status === 200) {
                    // Update the state and trigger a callback
                    notifications.show({
                        title: 'Added Tutor to Favourite',
                        message: 'Successfully Added',
                        color: 'green',
                        autoClose: true,

                    })

                }
            }
            // Update the state and trigger a callback
            onToggleFavorite();
        } catch (error) {
            console.error('Error:', error);
            notifications.show({
                title: 'Error adding to Favourite',
                message: error.response.data.error,
                color: 'red',
                autoClose: true,

            })

        }
    };

    return (
        <div className="text-gray-900 font-bold text-xl mb-2">
            <button>
              <IconThumbUp
                className={`${isFavorite  ? 'text-red-500' : 'text-black-500'}`}
                size={35}
                onClick={handleToggleFavorite}
            />  
            </button>
            
        </div>
    );
};


export default FavouriteButton