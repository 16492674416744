import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  redirect,
} from "react-router-dom";
import ProfileCreateRoute from './router/ProfileCreateRoute';
import AuthRoute from './router/AuthRoute';
import MainRoute from './router/MainRoute';
import { Helmet } from "react-helmet";
import TutoringRoute from './router/TutoringRoute';
import { AuthProvider } from './context/auth-context';
import PrivateRoute from './router/PrivateRoute';
import ComingSoon from './pages/ComingSoon';
import ClassRoomRoute from './router/ClassRoomRoute';
import MyRoute from './router/MyRoute';

function App() {
  const isLoggedIn = localStorage.getItem('jwtToken') ? true : false;

  const wrapPrivateRoute = (element, user, redirect) => {
    return (
      <PrivateRoute user={user} redirect={redirect}>
        {element}
      </PrivateRoute>
    );
  };
  return (
    <AuthProvider >
  
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tantorial | Learn and Earn</title>
          <link rel="icon" href="/assets/images/logo.png"
            type="image/x-icon" />
          <link rel="canonical" href="http://www.tantorial.com" />
        </Helmet>
        <Router>
          <Routes>
            <Route path="/*" element={<MainRoute />} />
            <Route path="/auth/*" element={<AuthRoute />} />
            <Route path="/profile/*" element={wrapPrivateRoute(<ProfileCreateRoute />, isLoggedIn, 'profile')} />
            <Route path="/tutoring/*" element={wrapPrivateRoute(<TutoringRoute />, isLoggedIn, 'tutoring')} />
            <Route path="/classroom/*" element={wrapPrivateRoute(<ClassRoomRoute />, isLoggedIn, 'classroom')} />
            <Route path="/my/*" element={wrapPrivateRoute(<MyRoute />, isLoggedIn, 'my')} />
            {/* <Route path="/" element={<ComingSoon />} /> */}
          </Routes>
        </Router>
 
    </AuthProvider >
  );
}

export default App;
