import React, { useState, useEffect } from "react";
import {
  Container,
  Modal,
} from "@mantine/core";

import { apiInstance } from '../../config/api';
import ProfileBack from "../../components/ProfileBack";
import Location from "../../components/userprofile/create/Location";
import AddButton from "../../components/partials/AddButton";
import LocationTable from "../../components/userprofile/datatable/LocationTable";
import { useDisclosure } from "@mantine/hooks";

function TutoringLocationAdd() {
  const [opened, { open, close }] = useDisclosure(false);
  const [myLocation, setMyLocation] = useState([]);

  useEffect(() => {
    apiInstance.get("profile/location/?me=true")
      .then((res) => {
        console.log(res);
        const locationList = res.data.results;
        setMyLocation(locationList);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <div>
      <ProfileBack />
      <AddButton openModal={open} text="Add New Location" />
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}>
        <Location onClose={close}
          setData={setMyLocation}
        />
      </Modal>
      <LocationTable data={myLocation} setData={setMyLocation} />
    </div>
  );
}

export default TutoringLocationAdd;
