import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import AddButton from '../components/partials/AddButton';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { apiInstance } from '../config/api';
import MyStudentTable from '../components/myStudent/MyStudentTable';
import AddStudents from '../components/myStudent/AddStudents';

function MyStudentsPage() {
  const [opened, { open, close }] = useDisclosure(false);
  const [students, setStudents] = useState([])


  useEffect(() => {
    apiInstance.get('tutorclass/studentclass/?me=true')
      .then((res) => {
        console.log(res);
        setStudents(res.data.results);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
    , []);

  return (
    <div>
      <AddButton openModal={open} text="Add A New Student" />
      <Modal opened={opened} onClose={close}

        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,

        }}
        size="80vh"
        maxHeight={900}
        title="Add A new student"
        style={{ maxHeight: 900 }}
        >
        <AddStudents onClose={close} setData={setStudents} />
      </Modal>
      {
        students.length ? (

          <h3>
            <MyStudentTable data={students} setData={setStudents} />
          </h3>
        ) : (
          <div className="h-screen flex flex-col items-center mt-12 ">
            <img
              src="https://static.preply.com/space/images/no-tutorings-state.dafce7c556bcf76bcca0f9adc27a720c.png" // Replace with the path to your Disney-themed logo or image
              alt="Tantorial"
              className="w-64 mb-8"
            />
            <h4 className="text-2xl font-bold mb-2">Your Classes will appear here</h4>
            <p className="text-lg mb-4">Find students and teach lessons to get started</p>
            <Link to="/" className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-4 rounded-full hover:shadow-md transition duration-300 ease-in-out">
              Add Students to your class
            </Link>
          </div>
        )
      }

    </div>

  )
}

export default MyStudentsPage