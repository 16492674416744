import React from 'react'
import { Link } from 'react-router-dom';


function PageNotFound() {
  return (
      <div className="h-screen flex flex-col items-center mt-12 ">
          <img
              src="assets/images/logo.png" // Replace with the path to your Disney-themed logo or image
              alt="TanTorial - Learn and Earn"
              className="w-32 mb-8"
          />
          <p className="text-3xl sm:text-2xl font-bold mb-2">Oops! 404 - Not Found</p>
          <p className="text-lg mb-4">The tutor you're looking for doesn't exist here.</p>
          <Link to="/" className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-4 rounded-full hover:shadow-md transition duration-300 ease-in-out">
              Go Back to Tantorial
          </Link>
      </div>
  )
}

export default PageNotFound