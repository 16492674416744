import { useEffect, useState } from 'react';
import axios from 'axios';
import {API_URL} from '../config/api';

const useApiData = (url, callBack) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_URL + url);
                const result = response.data.results || response.data;
                setData(result);
                setLoading(false);
            } catch (error) {
                console.error('API request error:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [url, callBack]);

    return { data, loading };
};

export default useApiData;
