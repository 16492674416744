import React from 'react'
import { Accordion, rem } from '@mantine/core';
import { IconPhoto, } from '@tabler/icons';
import moment from 'moment';

function Schedule({ availability }) {
    const convertTime = (timeString) => {
        const time = moment(timeString, 'HH:mm:ss');
        return time.format('h:m a');
    };
    return (
        <div className="bg-white p-3 rounded-lg shadow-lg mt-3 py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            <p className='text-2xl font-bold'>
                Tutor Schedule
            </p>
            <div className='py-2'>
                <Accordion variant="contained">
                    {
                        availability?.map((item, index) => {
                            return (

                                    <Accordion.Item key={index} value={`${index}`}>
                                        <Accordion.Control
                                            icon={
                                                <IconPhoto
                                                    style={{ color: 'var(--mantine-color-red-filled', width: rem(20), height: rem(20) }}
                                                />
                                            }
                                        >
                                            <p className='text-1xl font-bold'>
                                                {item.day}
                                            </p>
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                            <div className="education-item mx-3">
                                                <h4 className="font-semibold text-md mb-1">From: {convertTime(item.start_time)}</h4>
                                                <p className="font-semibold text-md mb-1">To: {convertTime( item.end_time)}</p>
                                                <p className="font-semibold text-md mb-1">Mode: {item?.mode?.toUpperCase()}</p>
                                                <hr className='my-3' />
                                            </div>
                                        </Accordion.Panel>
                                    </Accordion.Item>

                            
                            )
                        })
                    }
                </Accordion>
            </div>
        </div>
    )
}

export default Schedule