import React from "react";
import { Grid, Box, Title, Text } from "@mantine/core";
import imgSvg from "../assets/agile.svg";

function AgileCard() {
  return (
    <Box className="md:px-5 px-8">
      <Grid>
        <Grid.Col md={6}>
          <img src={imgSvg} alt="Agile" />
        </Grid.Col>
        <Grid.Col md={5}>
          <Title
            order={2}
            fz={70}
            className="mt-8 pt-8 px-8 md:text-5xl font-bold tracking-tight text-cyan-500 text-2xl "
          >
            What is Tantorial
          </Title>
          <Text className="mt-8 mt-6 md:text-2xl text-lg leading-8 text-gray-600 text-justify">
            Tantorial is an online tutoring platform that connects learners with
            experienced tutors across various academic subjects and skill
            development areas.
            <br />
            <br />
            It is an innovative and effective online tutoring platform
            that caters to the diverse needs of learners seeking personalized
            and engaging learning experiences.
            <br />
            <br />
            It provides a seamless and intuitive user
            experience for both learners and tutors, allowing them to connect
            and engage in personalized and effective online tutoring sessions.
          </Text>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default AgileCard;
