import React, { useState, useEffect } from "react";
import { apiInstance } from "../../../config/api";
import DataTable from "react-data-table-component";
import Modals from "../../partials/Modals";
import { notifications } from "@mantine/notifications";
import { useAuth } from "../../../context/auth-context";
import { useNavigate,Link } from "react-router-dom";

const customStyles = {
  rows: {
    style: {
      minWidth: "auto",
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      fontSize: "1.1rem",
      fontWeight: "semi-bold",
    },
  },
};

function LessonTable({ data, setData }) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleLink = (id, tutor, me) => navigate(`/my/contract/${id}?tutor=${tutor}&id=${me}`);

  const onDelete = (id) => {
    apiInstance
      .delete(`schedule/shedule-lesson/${id}/`)
      .then((res) => {
        console.log(res);
        setData((prevData) =>
          prevData.map((item) => {
            if (item.id === res.data.id) {
              // Update all fields except user
              return {
                ...item,
                is_confirm: true,
                status: "Confirmed by tutor",
              };
            } else {
              // Leave user field unchanged for other items
              return item;
            }
          })
        );
        notifications.show({
          title: "Item Deleted",
          message: "Successfully Deleted Item",
          color: "green",
          autoClose: true,
        });
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: "Error Deleting Item",
          message: err.response.data.error,
          color: "red",
          autoClose: true,
        });
      });
  };

  const decline = (id) => {
    apiInstance
      .patch(`schedule/shedule-lesson/${id}/decline_lesson/`)
      .then((res) => {
        console.log(res);
        setData((prevData) =>
          prevData.map((item) => {
            if (item.id === res.data.id) {
              // Update all fields except user
              return {
                ...item,
                is_confirm: true,
                status: "Confirmed by tutor",
              };
            } else {
              // Leave user field unchanged for other items
              return item;
            }
          })
        );
        // return updatedData;
        notifications.show({
          title: "Lesson Declined",
          message: "Successfully Declined this Lesson",
          color: "green",
          autoClose: true,
        });
        setOpenDecline(false);
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: "Error Declining this Lesson",
          message: err.response.data.error,
          color: "red",
          autoClose: true,
        });
      });
  };

  const confirm = (id) => {
    apiInstance
      .patch(`schedule/shedule-lesson/${id}/confirm_lesson/`)
      .then((res) => {
        console.log(res);
        setData((prevData) =>
          prevData.map((item) => {
            if (item.id === res.data.id) {
              // Update all fields except user
              return {
                ...item,
                is_confirm: true,
                status: "Confirmed by tutor",
              };
            } else {
              // Leave user field unchanged for other items
              return item;
            }
          })
        );
        // return updatedData;
        notifications.show({
          title: "Lesson Accepted",
          message: "Successfully Accepted this Lesson",
          color: "green",
          autoClose: true,
        });
        setOpenModal(false);
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: "Error Accepting this Lesson",
          message: err.response.data.error,
          color: "red",
          autoClose: true,
        });
      });
  };

  const openModalfxn = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const opendelModal = (item) => {
    setSelectedItem(item);
    setDeleteModal(true);
  };

  const openDeclineModal = (item) => {
    setSelectedItem(item);
    setOpenDecline(true);
  };

  let currencyFormater = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "XAF",
  });

  const columns = [
    {
      name: user?.is_tutor ? "Learner" : "Tutor" + " Name",
      selector: (row) =>
        user?.is_tutor
          ? row.learner.first_name + " " + row.learner.last_name
          : row.tutor.first_name + " " + row.tutor.last_name,
      sortable: true,
      width: "auto",
    },
    {
      name: "Amount",
      selector: (row) => currencyFormater.format(row.amount),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Mode",
      selector: (row) => row.mode,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) =>
        row.is_confirm ? (
          <button
            // onClick={() => confirm(row.id)}
            onClick={() => handleLink(row.id, row.tutor.id, row.learner.id)}
            className="ring ring-offset-1 text-green-600 ring-2 ring-green-500 group relative flex items-center justify-center rounded-md border py-1 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
          >
            View Contract
          </button>
        ) : !user?.is_tutor ? (
          row.status.toLowerCase() === "declined by tutor" ? (
            <button className="w-full text-indigo-600 relative rounded-md  py-1 px-4 text-sm font-medium uppercase focus:outline-none sm:py-6">
              {row.status}
            </button>
          ) : (
            <div>
              <button
                onClick={() => openModalfxn(row?.id)}
                className="w-full ring ring-offset-1 text-indigo-600 ring-2 ring-indigo-500  relative  rounded-md border py-1 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:py-6"
              >
                Confirm
              </button>
              <button
                onClick={() => openDeclineModal(row?.id)}
                className="w-full ring my-2 ring-offset-1 text-red-600 ring-2 ring-red-500  rounded-md border py-1 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:py-6"
              >
                Decline
              </button>
            </div>
          )
        ) : row.status.toLowerCase() === "declined by tutor" ? (
          <button className="w-full text-indigo-600 relative rounded-md  py-1 px-4 text-sm font-medium uppercase focus:outline-none sm:py-6">
            {row.status}
          </button>
        ) : (
          <button
            onClick={() => opendelModal(row?.id)}
            className="ring ring-offset-1 text-red-600 ring-2 ring-red-500 group relative flex items-center justify-center rounded-md border py-1 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
          >
            Cancel Lesson?
          </button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );
  return (
    <section className="my-4 mx-3">
      <Modals
        open={openModal}
        setOpen={setOpenModal}
        title="Confirm Lesson"
        content="Are you sure you want to confirm this lesson?"
        action={() => confirm(selectedItem)}
        action_title="Confirm"
      />

      <Modals
        open={deleteModal}
        setOpen={setDeleteModal}
        title="Delete Lesson"
        content="Are you sure you want to delete this lesson?"
        action={() => onDelete(selectedItem)}
        action_title="Delete"
      />

      <Modals
        open={openDecline}
        setOpen={setOpenDecline}
        title="Decline Lesson"
        content="Are you sure you want to decline this lesson?"
        action={() => decline(selectedItem)}
        action_title="Decline"
      />
      <DataTable
        direction="auto"
        responsive
        highlightOnHover
        pointerOnHover
        columns={columns}
        data={data}
        striped
        pagination
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        persistTableHead
        paginationServer
        paginationPerPage={10}
        progressPending={data.length ? false : true}
        customStyles={customStyles}
      />
    </section>
  );
}

export default LessonTable;
