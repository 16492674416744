import React from 'react';
import FullCalendar from '@fullcalendar/react'; // Import FullCalendar
import dayGridPlugin from '@fullcalendar/daygrid'; // Import the dayGrid plugin
import interactionPlugin from '@fullcalendar/interaction'; // Import the interaction plugin
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'

const UpcomingEvent = () => {
    const calendarRef = React.createRef(); // Create a ref

    const handleEventClick = (info) => {
        // Handle event click action here
        console.log('Event clicked:', info.event);
    };

    return (
        <section className="bg-gray-100 py-12">
            <div className="container mx-auto">
                <h2 className="text-3xl font-semibold text-center mb-6">Upcoming Sessions</h2>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, interactionPlugin]} // Include plugins
                    header={{
                        left: 'prev',
                        center: 'title',
                        right: 'next',
                    }}

                    events={[
                        // Your event data goes here
                        {
                            title: 'Event 1',
                            start: '2024-03-15',
                            end: '2024-03-15',
                            className: 'event-1',
                        },
                        {
                            title: 'Event 2',
                            start: '2023-04-10',
                            end: '2023-04-10',
                            className: 'event-2',
                        },
                        {
                            title: 'Event 3',
                            start: '2023-05-05',
                            end: '2023-05-05',
                            className: 'event-3',
                        },
                    ]}
                    eventClick={handleEventClick} // Handle event click
                />
            </div>
        </section>
    );
};

export default UpcomingEvent;
