import React, { useState } from 'react'
import {
  createStyles,
  Paper,
  Title,
  Text,
  TextInput,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  rem,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config/api';
import axios from 'axios';
import { notifications } from '@mantine/notifications';


const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
}));


export default function ForgotPassword() {
  const { classes } = useStyles();
  const [email, setEmail] = useState("")
  const [sent, setSent] = useState(false)
  const [error, setError] = useState("")


  const resend_email = (e) => (
    axios.post(`${API_URL}auth/users/reset_password/`, { email: email })
      .then(response => {
        setSent(true)
        notifications.show({
          title: 'Password Reset Link Sent',
          message: 'Please Check your E-mail inbox to reset your password',
          color: 'green',
          autoClose: true,
        })
        setEmail("")
      })
      .catch(error => {
        setError(error.response.data)
        notifications.show({
          title: 'Error',
          message: error.response.data,
          color: 'red',
          autoClose: true,
        })
        setInterval(() => {
          setError("")
        }
          , 10000)

      })
  )


  return (
    <Container size={460} my={30}>
      <Title className={classes.title} align="center">
        Forgot your password?
      </Title>
      <Text c="dimmed" fz="sm" ta="center">
        Enter your email to get a reset link
      </Text>
      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <TextInput label="Your email" placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required />
        <Text c="red" fz="sm">
          {error}
        </Text>
        <Text c="green" fz="sm" >
          {sent ? "Email Sent" : ""}
        </Text>
        <Group position="apart" mt="lg" className={classes.controls}>
          <button
            onClick={resend_email}
            className="block w-full bg-indigo-600 text-white font-semibold py-2 px-4 transition duration-300 hover:bg-indigo-70">
            Send reset link
          </button>
          <Anchor color="gray" size="sm" className={classes.control}>
            <Center inline>
              <IconArrowLeft size={rem(12)} stroke={1.5} />
              <Box ml={5}>
                <Link to="login" style={{ textDecoration: "none" }}>
                  Back to the login page
                </Link>
              </Box>

            </Center>
          </Anchor>
        </Group>
      </Paper>
    </Container>
  );
}