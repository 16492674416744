import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../config/api'
import { notifications } from '@mantine/notifications';
import Select from 'react-select';

function CreatClassRoom({ onClose, setData }) {
    const [classroomData, setClassroomData] = useState({
        title: '',
        description: '',
        duration: 0,
        free: false,
        trial: false,
        durationUnit: 'months',
        end_of_trial: true,
        payment_plan: '',
        price: 0,
        is_active: true,
        enrolled: 0,
        deleted: true,
        archived: true,
    });

    const [paymentPlan, setPaymentPlan] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setClassroomData({
            ...classroomData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    useEffect(() => {
        apiInstance.get('misc/payment-plan/')
            .then((res) => {
                console.log(res);
                setPaymentPlan(res.data.results);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    const fixedData = (data) => {
        return data.map((item) => ({
            value: item.id,
            label: item.title,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        apiInstance.post('tutorclass/tutorclass/', classroomData)
            .then((res) => {
                console.log(res.data);
                setData((prevData) => [...prevData, res.data]);
                notifications.show({
                    title: 'Classroom Created',
                    message: 'Successfully Created Classroom',
                    color: 'green',
                    autoClose: true,
                })
                onClose();
            })
            .catch((err) => {
                console.log(err.response);
                notifications.show({
                    title: 'Error Creating Classroom',
                    message: err.response.data.error,
                    color: 'red',
                    autoClose: true,
                })
            });

    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white rounded-md">
            <h2 className="text-2xl font-semibold mb-1">Create Classroom</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-1">
                    <label htmlFor="title" className="block text-gray-600">Class name:</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={classroomData.title}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                    />
                </div>
                <div className="mb-1">
                    <label htmlFor="description" className="block text-gray-600">Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={classroomData.description}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        required
                    />
                </div>
                <div className="mb-1">
                    <label htmlFor="duration" className="block text-gray-600">Duration:</label>
                    <div className="flex w-full">
                        <input
                            type="number"
                            id="duration"
                            name="duration"
                            value={classroomData.duration}
                            onChange={handleChange}
                            className="w-1/2 p-2 border rounded-l-md"
                            required
                        />
                        <select
                            id="durationUnit"
                            name="durationUnit"
                            value={classroomData.durationUnit}
                            onChange={handleChange}
                            className="w-1/4 p-2 border border-l-0 rounded-r-md"
                        >
                            <option value="years">Years</option>
                            <option value="months">Months</option>
                            <option value="weeks">Weeks</option>
                            <option value="days">Days</option>
                        </select>
                    </div>
                </div>
                <div className="mb-1">
                    <label htmlFor="free" className="block text-gray-600">
                        <input
                            type="checkbox"
                            id="free"
                            name="free"
                            checked={classroomData.free}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Free
                    </label>
                </div>
                {
                    classroomData.free === false && (
                       <>
                            <div className="mb-1">
                                <label htmlFor="price" className="block text-gray-600">Price:</label>
                                <input
                                    type="number"
                                    id="price"
                                    name="price"
                                    value={classroomData.price}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded-md"
                                />
                            </div>
                            <div className='mb-1'>
                                <label htmlFor="price" className="block text-gray-600">Payment Plan:</label>
                                <Select
                                    name="Payment Plan  "
                                    options={
                                        fixedData(paymentPlan)
                                    }
                                    isSearchable
                                    placeholder="Level"
                                    onChange={(e) => {
                                        setClassroomData({
                                            ...classroomData,
                                            payment_plan: e.value,
                                        })
                                    }
                                    }

                                />
                            </div>
                       </>
                    )
                }

                <div className="mb-1">
                    <label htmlFor="free" className="block text-gray-600">
                        <input
                            type="checkbox"
                            id="trial"
                            name="trial"
                            checked={classroomData.trial}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Trial
                    </label>
                </div>
                {
                    classroomData.trial === true && (
                        <div className="mb-1">
                            <label htmlFor="trial" className="block text-gray-600">
                                Trial Duration:
                            </label>
                            <input
                                type="number"
                                id="end_of_trial"
                                name="end_of_trial"
                                value={classroomData.end_of_trial}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-md"

                            />
                        </div>
                    )
                }
                <div className="mt-4">
                    <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                        Create Classroom
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreatClassRoom