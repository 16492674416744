import React from "react";
import { Link } from "react-router-dom";
function ProductMosst() {

  return (
    <div className="bg-indigo-200">
      <div className="mx-auto py-10 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative  overflow-hidden bg-gray-900 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-40 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-4 max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
             Why Tutor on Tantorial?
              <br />
            </h2>
            <p className="mt-6 text-lg text-justify leading-8 text-gray-300">
              Tantorial is a platform that connects students with tutors. We
              provide a platform for students to find tutors, schedule lessons,
              and pay for lessons all in one place. We also provide a platform
              for tutors to find students, schedule lessons, and get paid for
              lessons all in one place.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <Link
                to='/home'
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Start Tutoring Now
              </Link>
              <Link
                to='/home'
                className="text-sm font-semibold leading-6 text-white"
              >
                See All tutor <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src="https://scontent-lhr6-1.xx.fbcdn.net/v/t39.30808-6/402405045_2499291690252968_624877518984506534_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeFD2cbLyPdU5MVFB4GwUrzhv2wKrybrX66_bAqvJutfrnyZjr_WbIQogAhOHn3yTVfeRuI8Dlq83xykSEnAbJg5&_nc_ohc=DNQWG9CUnfYAX9os2-0&_nc_ht=scontent-lhr6-1.xx&oh=00_AfBcqzI-b9mLmhMk09i75H4Xt1z6wFh5K5jwiaV0t_b3sA&oe=6562E9AD"
              alt="App screenshot"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductMosst;
