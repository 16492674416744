import React, { useEffect, useState } from "react";


const categories = [
    {
        title: 'Students',
        count: '20K',
    },
    {
        title: 'Subjects',
        count: '100+'
    },
    {
        title: 'Lessons',
        count: '100K',
    },
    {
        title: "Tutors",
        count: '1K',
    },
    {
        title: "Pay Out",
        count: '100M+',
    },


]

export default function Stats() {

    return (
        <div className="bg-blue-700 py-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-lg grid-cols-2  items-center gap-x-8 gap-y-10 sm:max-w-xl  sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    {
                        categories.map((category) => (
                            <div className="max-h-full text-center mx-auto">
                                <p className="font-extrabold text-light text-4xl text-center w-full object-contain">
                                    {category.count}
                                </p>
                                <p className="text-xl font-bold text-light text-center w-full object-contain">
                                    {category.title}
                                </p>
                            </div>


                        ))
                    }

                </div>
            </div>
        </div>
    )
}
