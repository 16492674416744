import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "@mantine/form";
import {
  Select,
  Button,
  Grid,
  LoadingOverlay,
  Group,
  Box,
  Container,
  Title,
  Paper,
  Checkbox,
  TextInput,
  FileInput,
  Textarea,
  rem,
  Text,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import CustomLoader from "../../components/partials/CustomLoader";
import { apiInstance } from "../../config/api";
import { useAuth } from "../../context/auth-context";
import ProfileBack from "../../components/ProfileBack";
import { useNavigate } from "react-router-dom";

function ProfileSummaryAdd() {
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [mySummary, setMySummary] = useState({});
  const [edit, setEdit] = useState(false);
  const [summary, setSummary] = useState("");
  const [accomplishment, setAccomplishment] = useState("");

  // Use useEffect to update the state when mySummary changes
  useEffect(() => {
    setSummary(mySummary?.profile_summary || "");
    setAccomplishment(mySummary?.accomplishment || "");
  }, [mySummary]);

  const on_save = (event) => {
    event.preventDefault();
    apiInstance
      .post(`profile/profilesummary/`, {
        profile_summary: summary,
        accomplishment: accomplishment,
  
      })
      .then((response) => {
        console.log(response);
        const results = response?.data;
        setMySummary(results);
        setSubmitting(false);
        setEdit(false);
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    setSubmitting(true);
    apiInstance
      .get(`profile/profilesummary/?me=true  `)
      .then((response) => {
        console.log(response);
        const results = response?.data?.results;
        setMySummary(results[0]);
        setSubmitting(false);

      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
      });
  }, []);

  console.log(mySummary);

  return (
    <Container>
      <ProfileBack />
      <Text
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
        fz="xl"
      >
        Provide Details About Yourself
      </Text>
      <Box position="relative">
        <CustomLoader visible={submitting} />
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          {edit ? (
            <form onSubmit={on_save}>
              <Grid mt={20}>
                <Grid.Col sm={12} md={12}>
                  <Textarea
                    label="Personal Statement"
                    placeholder="Give a brief description of yourself"
                    value={summary}
                    onChange={(event) => setSummary(event.currentTarget.value)}
                    radius="md"
                    size="md"
                    autosize
                  />
                  <Textarea
                    label="Accomplishment"
                    placeholder={accomplishment}
                    value={accomplishment}
                    onChange={(event) =>
                      setAccomplishment(event.currentTarget.value)
                    }
                    radius="md"
                    size="md"
                    autosize
                  />
                </Grid.Col>
              </Grid>
              <Group>
                <button
                  type="submit"
                  className="bg-indigo-500 my-3 py-2 hover:bg-indigo-600 px-3 py-2 text-lg font-semibold text-white py-2 px-4 rounded w-full"
                >
                  Save
                </button>
              </Group>
            </form>
          ) : (
            <div>
              <h1 className="text-2xl font-bold text-gray-800">
                Personal Summary
              </h1>

              <p className="text-base text-2xl text-gray-700 mt-2" 
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
              >
                {mySummary?.profile_summary || "No Summary Added"}
              </p>
              <div className="my-4"></div>
              <h1 className="text-2xl font-bold text-gray-800">
                Accomplishment
              </h1>
              <p className="text-base text-2xl text-gray-700 mt-2"
              style={{
                whiteSpace: "pre-wrap",
              }}
              >
                {mySummary?.accomplishment || "No Accomplishment Added"}
              </p>
              <div className="my-4"></div>
              <button
                onClick={() => setEdit(true)}
                className="bg-indigo-500 my-3 py-2 px-3 text-lg font-semibold text-white rounded w-full hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-400 focus:ring-opacity-50"
              >
                Edit
              </button>
            </div>
          )}
        </Paper>
      </Box>
    </Container>
  );
}

export default ProfileSummaryAdd;
