import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';
import Select from 'react-select';

function WorkExperience({ onClose, setData }) {
  const [myWork, setMyWork] = useState(
    {
      country: '',
      company: '',
      position: '',
      summary: '',
      start_date: '',
      end_date: '',
      still_working: false,
    }
  );

 // eslint-disable-line react-hooks/exhaustive-deps


  const handleSubmit = (e) => {
    e.preventDefault();
    // setSubmitting(true);
    apiInstance.post('profile/workhistory/', myWork)
      .then((res) => {
        console.log(res.data);
        setData((prevData) => [...prevData, res.data]);
        notifications.show({
          title: 'Item Created',
          message: 'Successfully Created Item',
          color: 'green',
          autoClose: true,
        })
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Add Item',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMyWork({
      ...myWork,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Add Work History</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Country:</label>
          <input type="text" id="country" name="country" value={myWork.country} onChange={handleChange} className="w-full p-2 border rounded-md" />
    
        </div>

        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Establishment:</label>
          <input
            type="text"
            id="company"
            name="company"
            value={myWork.company}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Position:</label>
          <input type="text" id="position" name="position" value={myWork.position} onChange={handleChange} className="w-full p-2 border rounded-md" />

        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Description:</label>
          <textarea type="text" id="summary" name="summary" value={myWork.summary} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Start Date:</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            value={myWork.start_date}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className="mb-1">
          <input type="checkbox"
            value={myWork.still_working}
            name="still_working"
            onChange={
              (e) => {
                setMyWork({
                  ...myWork,
                  still_working: e.target.checked,
                })
              }

            }
            id="myCheckbox" className="rounded-lg p-2 text-indigo-500 bg-white border-2 border-indigo-400 hover:bg-indigo-100" />
          <span className="ml-2 text-sm text-gray-600">Still Working</span>
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">End Date:</label>
          <input
            type="date"
            id="end_date"
            disabled={myWork.still_working}
            name="end_date"
            value={myWork.end_date}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>

        <div className="mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Add Education
          </button>
        </div>
      </form>
    </div>
  )
}

export default WorkExperience