import React from 'react'
import CertificationsAdd from '../pages/profileadd/CertificationsAdd'
import EducationAdd from '../pages/profileadd/EducationAdd'
import WorkHistoryAdd from '../pages/profileadd/WorkHistoryAdd'
import PersonalProfileAdd from '../pages/profileadd/PersonalProfileAdd'
import TutoringLocationAdd from '../pages/profileadd/TutoringLocationAdd'
import TutoringSubjectAdd from '../pages/profileadd/TutoringSubjectAdd'
import TutoringScheduleAdd from '../pages/profileadd/TutoringScheduleAdd'
import ProfileSummaryAdd from '../pages/profileadd/ProfileSummaryAdd'
import RateAdd from '../pages/profileadd/RateAdd';
import { Route, Routes } from "react-router-dom";
// import MainProfileLayout from '../layout/profile/MainProfileLayout'
import MainLayout from '../layout/home/MainLayout'
import ProfileHome from '../pages/profileadd/ProfileHome'
import ChangePassword from '../pages/auth/ChangePassword'




function ProfileCreateRoute() {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route index element={<ProfileHome />} />
                <Route path='personal' element={<PersonalProfileAdd />} />
                <Route path="certification" element={<CertificationsAdd />} />
                <Route path="education" element={<EducationAdd />} />
                <Route path="work-history" element={<WorkHistoryAdd />} />
                <Route path="personal-profile" element={<PersonalProfileAdd />} />
                <Route path="tutoring-location" element={<TutoringLocationAdd />} />
                <Route path="tutoring-subject" element={<TutoringSubjectAdd />} />
                <Route path="tutoring-schedule" element={<TutoringScheduleAdd />} />
                <Route path="profile-summary" element={<ProfileSummaryAdd />} />
                <Route path="reset-password" element={<ChangePassword />} />
                <Route path="rate" element={<RateAdd />} />
            </Route>
        </Routes>

    )
}

export default ProfileCreateRoute