import React from 'react'
import { Route, Routes } from "react-router-dom";
import ClassDashboard from '../components/classroom/ClassDashboard';
import Classroom from '../pages/Classroom';
import MainLayout from '../layout/home/MainLayout'

function ClassRoomRoute() {
  return (
    <Routes>
    <Route path="/" element={<MainLayout />}>
        <Route index element={<Classroom />} />
        <Route path='/:id/:name' element={<ClassDashboard />} />
    </Route>
</Routes>
  )
}

export default ClassRoomRoute