import React, { useState } from 'react'
import {
  Container,
  Text,
  Title,
  Center,
  Grid,
  Card,
  Image,
  Badge,
  Button,
  Group,
  createStyles,
  NumberInput,
  NumberInputHandlers,
  ActionIcon,
  rem,
  Select,
  Radio, Paper, Box
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconPlus, IconMinus, IconChevronDown } from '@tabler/icons';

const LANGUAGE = [
  { value: 'english', label: 'English' },
  { value: 'french', label: 'French' },
]

const LEVEL = [
  { value: 'nursery', label: 'Nursery School' },
  { value: 'primary', label: 'Primary School' },
  { value: 'secondary', label: 'Secondary and High School' },
]

const CLASS = [
  { value: 'class1', label: 'Class 1' },
  { value: 'class2', label: 'Class 2' },
  { value: 'class3', label: 'Class 3' },
  { value: 'class4', label: 'Class 4' },
  { value: 'class5', label: 'Class 5' },
  { value: 'class6', label: 'Class 6' },
  { value: 'class7', label: 'Class 7' }
]

function KidLearner() {
  const navigate = useNavigate()
  const [childClass, setChildClass] = useState([
    {
      child_class: "",
      child_language: "",
      child_level: "",
      child_goal: "",
      child_subject: "",
      child_more: "",
    },
  ])


  const [ChildCount, setChildCount] = useState(1);
  const [tutoringType, setTutoringType] = useState('in-person');
  const [formErrors, setFormErrors] = useState([]);


  const handleNextClick = () => {
    navigate('/tutoring/learner-need', {
      state: {
        childClass,
        ChildCount,
        tutoringType,
      },
    });
  };

  return (
    <Container>
      <Center>
        <Text size={30} fw={700} fs="italic">
          Let us Find the best tutor for You
        </Text>
      </Center>
      <Text size={25} fw={600} fs="italic">
        Tell us about 
      </Text>
      <Grid my={20}>           
      </Grid>
      <Radio.Group
        name="tutoring-type"
        label="Do you prefer in-person or online lessons?"
        size="lg"
        value={tutoringType}
        onChange={setTutoringType}
      >
        <Group mt="xs">
          <Radio value="in-person" label="In-Person" size="lg" />
          <Radio value="online" label="Online" size="lg" />
        </Group>
      </Radio.Group>
      <Button mt={20}
        color="teal"
        radius="lg"
        size="lg"
        uppercase
        onClick={handleNextClick}
      >
        Next
      </Button>
    </Container >
  )
}

export default KidLearner