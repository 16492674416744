import { UserIcon } from '@heroicons/react/24/outline'
import React from 'react'

function TutorLessonCount({ numberOfStudents, numberOfLessons}) {
  return (
      <div className="flex py-1">
          <p className="text-sm font-semibold text-gray-600 flex items-center">
              <UserIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-black-400"
                  aria-hidden="true"
              />
              {numberOfStudents} Students {" -> "}
          </p>
          <p className="text-sm font-semibold text-gray-600 flex items-center">
              {numberOfLessons} lessons
          </p>
      </div>
  )
}

export default TutorLessonCount