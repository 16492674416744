import React from 'react'
import CategoryFilter from '../components/partials/CategoryFilter'
import StudentHomePageHeader from '../components/partials/StudentHomePageHeader'
import FilterNav from '../components/partials/FilterNav'
import CategoryClouds from '../components/partials/CategoryClouds'
import { Link } from 'react-router-dom'


function StudentHomePage() {



    return (
        <>
            {/* <StudentHomePageHeader /> */}
            <FilterNav />
            <CategoryClouds />
            <Link to='/tutoring' className="block w-full text-center text-2xl bg-indigo-600 text-white font-bold transition duration-300 hover:bg-indigo-70 animate-bounce">
                Tell Us What You Need
            </Link>
            <CategoryFilter />

        </>

    )
}

export default StudentHomePage