import React, { useEffect, useState } from "react";
import useApiData from "../../hooks/useApiData";


export default function CategoryClouds() {
    const { data: categories, loading: categoriesLoading } = useApiData(`category/category/`);
    return (
        <div className="bg-white py-12 ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="text-center text-2xl font-semibold leading-8 text-gray-900">
                    Get the Best tutor today for
                </h2>
                <div className="mx-auto mt-5 grid max-w-lg grid-cols-2  items-center gap-x-8 gap-y-10 sm:max-w-xl  sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    {
                        categories.map((category) => (
                            <>
                                <p className="max-h-12 font-semibold text-xl animate-pulse w-full object-contain">
                                    {category.name}
                                </p>
                            </>


                        ))
                    }

                </div>
            </div>
        </div>
    )
}
