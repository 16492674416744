import { IconBrandFacebook, IconBrandGoogle, IconBrandInstagram, IconBrandTwitter } from '@tabler/icons'
import React from 'react'
import { Link } from 'react-router-dom'

const SM = [
  {
    name: 'Facebook',
    icon: <IconBrandFacebook />,
    link: 'https://www.facebook.com/tantorial'
  },
  {
    name: 'Twitter',
    icon: <IconBrandTwitter />,
    link: 'https://twitter.com/tantorial'
  },
  {
    name: 'Instagram',
    icon: <IconBrandInstagram />,
    link: 'https://www.instagram.com/tantorial'
  },
]



function FooterHome() {
  return (
    <footer class="footer-1 bg-gray-100 py-2 sm:py-12">
      <div class="container mx-auto px-4">
        <div class="sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
          <div class="px-4 sm:w-1/4 md:w-1/4 xl:w-1/6">
            <h5 class="text-xl font-bold mb-6">Features</h5>
            <ul class="list-none footer-links">
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Subjects</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Levels</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Category</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Regions</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Payment</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Lessons</Link>
              </li>
            </ul>
          </div>
          <div class="px-4 sm:w-1/4 md:w-1/4 xl:w-1/6 mt-8 sm:mt-0">
            <h5 class="text-xl font-bold mb-6">Resources</h5>
            <ul class="list-none footer-links">
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Students</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Guardians</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Tutorial centers</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Report center</Link>
              </li>
            </ul>
          </div>
          <div class="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0">
            <h5 class="text-xl font-bold mb-6">About</h5>
            <ul class="list-none footer-links">
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Team</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Locations</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Privacy Policy</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Terms and Conditions </Link>
              </li>
            </ul>
          </div>
          <div class="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6 mt-8 md:mt-0">
            <h5 class="text-xl font-bold mb-6">Help</h5>
            <ul class="list-none footer-links">
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Support</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Help Center</Link>
              </li>
              <li class="mb-2">
                <Link to='/' class="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div class="px-4 mt-1 sm:w-1/3 xl:w-1/6 sm:mx-auto xl:mt-0 xl:ml-auto">
            <h5 class="text-xl font-bold mb-6 sm:text-center xl:text-left">Stay connected</h5>
            <div class="flex sm:justify-center xl:justify-start">
              {
                SM?.map((item, index) => (
                  <a key={index} rel='noreferrer' target='_blank' href={item.link} to class="w-8 mx-2 h-8 border border-2 border-gray-400 rounded-full text-center py-1 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600">
                    {item.icon }
                  </a>
                ))

              }
            </div>
          </div>
        </div>

        {/* <div class="sm:flex sm:flex-wrap sm:-mx-4 mt-6 pt-6 sm:mt-12 sm:pt-12 border-t">
          <div class="px-4 sm:w-1/2 md:w-1/4 mt-4 md:mt-0">
            <h6 class="font-bold mb-2">Address</h6>
            <address class="not-italic mb-4 text-sm">
              123 Biyem-assi.<br/>
                Yaounde, Cameroon
            </address>
          </div>
          <div class="px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
            <Link to='/home' class="px-4 py-2 bg-purple-800 hover:bg-purple-900 rounded text-white">Get Started</Link>
          </div>
        </div> */}
      </div>
    </footer>
  )
}

export default FooterHome