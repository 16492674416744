import React from 'react'
import { Route, Routes } from "react-router-dom";
// import MainProfileLayout from '../layout/profile/MainProfileLayout'
import MainLayout from '../layout/home/MainLayout'

import Lesson from '../pages/Lesson';
import Wallet from '../pages/Wallet';
import MyStudents from '../pages/MyStudents';
import MyReviews from '../pages/MyReviews';
import TutorCalender from '../pages/TutorCalender';
import Messages from '../pages/message/Messages';
import FavouritePage from '../pages/FavouritePage';
import MyTutoringProfile from '../pages/tutoringProfile/MyTutoringProfile'
import ContractPage from '../pages/ContractPage'



function MyRoute() {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route path="lesson" element={<Lesson />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="mystudent" element={<MyStudents />} />
                <Route path="myreview" element={<MyReviews />} />
                <Route path="calender" element={<TutorCalender />} />
                <Route path="messages" element={<Messages />} />
                <Route path="favourite" element={<FavouritePage />} />
                <Route path='tutoring-profiles' element={<MyTutoringProfile />} />
                <Route path='contract/:id' element={<ContractPage />} />
            </Route>
        </Routes>

    )
}

export default MyRoute