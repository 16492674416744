import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import MainHeader from "../../components/partials/MainHeader";


function MainLayout(props) {
    return (
        <div>
            <MainHeader  />
            <div className="relative">
                <Outlet /> 
            </div>
        </div>


    );
}

export default MainLayout;
