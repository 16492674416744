import React from "react";
import {
  CurrencyDollarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";

const Wallet = () => {
  const totalEarned = 1000;
  const totalWithdrawn = 500;
  const totalDeposited = 300;
  const withdrawHistory = [
    { date: "2023-01-15", amount: 200 },
    { date: "2023-02-10", amount: 100 },
  ];

  const handleWithdraw = () => {
    // Handle withdraw action here
    // You can show a modal or form for withdrawal
  };

  const handleDeposit = () => {
    // Handle deposit action here
    // You can show a modal or form for deposit
  };

  return (
    <div className="mx-2 mt-6">
      <h1 className="text-3xl font-semibold mb-4 text-center">My Wallet</h1>
      <div className="container flex flex-col bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-6 flex items-center">
          <h2 className="text-xl font-semibold">Amount Earned</h2>
          <p className="text-2xl font-bold text-green-600 ml-4">
          <ChevronDownIcon className="w-6 h-6 inline-block" /> $
            {totalEarned}
          </p>
        </div>
        <div className="mb-6 flex items-center">
          <h2 className="text-xl font-semibold">Escrow Balance</h2>
          <p className="text-2xl font-bold text-purple-600 ml-4">
          <ChevronDownIcon className="w-6 h-6 inline-block" /> $
            {totalEarned}
          </p>
        </div>
        <div className="mb-6 flex items-center">
          <h2 className="text-xl font-semibold">Amount Spend</h2>
          <p className="text-2xl font-bold text-gray-600 ml-4">
          <ChevronDownIcon className="w-6 h-6 inline-block" /> $
            {totalEarned}
          </p>
        </div>
        <div className="mb-6 flex items-center">
          <h2 className="text-xl font-semibold">Amount Withdrawn</h2>
          <p className="text-2xl font-bold text-red-600 ml-4">
            <ChevronDownIcon className="w-6 h-6 inline-block" /> $
            {totalWithdrawn}
          </p>
          <button
            onClick={handleWithdraw}
            className="ml-4 mt-2 bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700"
          >
            Withdraw
          </button>
        </div>
        <div className="mb-6 flex items-center">
          <h2 className="text-xl font-semibold">Amount Deposited</h2>
          <p className="text-2xl font-bold text-blue-600 ml-4">
            <ChevronUpIcon className="w-6 h-6 inline-block" /> ${totalDeposited}
          </p>
          <button
            onClick={handleDeposit}
            className="ml-4 mt-2 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
          >
            Deposit
          </button>
        </div>
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Transactions History</h2>
        <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Description</th>
      <th scope="col">Amount</th>
      <th scope="col">Balance</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>2023-11-28</td>
      <td>Transaction 1</td>
      <td>$100.00</td>
      <td>$1,000.00</td>
    </tr>
    <tr>
      <td>2023-11-27</td>
      <td>Transaction 2</td>
      <td>-$50.00</td>
      <td>$950.00</td>
    </tr>
    <tr>
      <td>2023-11-26</td>
      <td>Transaction 3</td>
      <td>$25.00</td>
      <td>$975.00</td>
    </tr>
  </tbody>
</table>

      </div>
    </div>
  );
};

export default Wallet;
