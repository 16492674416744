import React from 'react'
import { Accordion, rem } from '@mantine/core';
import { IconPhoto, } from '@tabler/icons';



function Subjects({ subjects }) {
    return (
        <div className="bg-white p-3 rounded-lg shadow-lg mt-3 py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            <p className='text-2xl font-bold'>
                Tutor Subjects
            </p>
            <div className='py-4'>
                <Accordion variant="contained">
                    {
                        subjects?.map((item, index) => {
                            return (
                                    <Accordion.Item key={index} value={`${index}`}>
                                        <Accordion.Control
                                            icon={
                                                <IconPhoto
                                                    style={{ color: 'var(--mantine-color-red-filled', width: rem(20), height: rem(20) }}
                                                />
                                            }
                                        >
                                            <p className='text-1xl font-bold'>
                                                {item?.subject?.title}
                                            </p>
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                            <div className="education-item mt-2 mx-3">
                                                <h4 className="font-semibold text-md mb-1">{item.category?.name}</h4>
                                                <p className="text-gray-600">{item.subcategory?.name}</p>
                                                <p className="text-gray-600">{item?.level?.title} - {item?.language?.title}
                                                </p>
                                                <hr className='my-3' />
                                            </div>
                                            {/* <div className='flex flex-col mx-3'>
                                                <div className='flex flex-row md:flex-col'>


                                                    <div className='flex flex-col w-1/4'>
                                                        <p className='text-sm font-bold'>Category</p>
                                                        <p className='text-sm'>{item.category?.name}</p>
                                                    </div>
                                                    <div className='flex flex-col w-1/4'>
                                                        <p className='text-sm font-bold'>Subcatetegory</p>
                                                        <p className='text-sm'>{item.subcategory?.name}</p>
                                                    </div>
                                                    <div className='flex flex-col w-1/4'>
                                                        <p className='text-sm font-bold'>Level</p>
                                                        <p className='text-sm font-semibold'>{item?.level?.title}</p>
                                                    </div>
                                                    <div className='flex flex-col w-1/4'>
                                                        <p className='text-sm font-bold'>Language</p>
                                                        <p className='text-sm font-semibold'>{item?.language?.title}</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </Accordion.Panel>
                                    </Accordion.Item>

                                
                            )
                        })
                    }
                </Accordion>
            </div>
        </div>
    )
}

export default Subjects