import React from 'react'

function AddButton({openModal, text}) {
  return (
    <div className='mx-5'>
          <button 
              onClick={openModal}
          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-sm">
                {text}
        </button>
    </div>
  )
}

export default AddButton