import { Fragment, useEffect, useState } from 'react'
import {  Transition, Menu } from '@headlessui/react'
import {  BellAlertIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/outline'
import Cart from './Cart'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth-context';
import { apiInstance } from '../../config/api'
import NotificationDropdown from './NotificationDropdown'


const navMenu = [
    { name: 'Your Profile', link: '/profile' },
    { name: 'My Lessons', link: '/my/lesson' },
    { name: 'My Classroom', link: '/classroom' },
    // { name: 'My Assessments', link: '/profile/assessments'},
    { name: 'My Students', link: '/my/mystudent' },
    { name: 'Calender', link: '/my/calender' },
    // { name: 'My Account', link: '' },
    { name: 'My Wallet', link: '/my/wallet' },
    { name: 'My Reviews', link: '/my/myreview' },
    // { name: 'My Messages', link: '/profile/messages' },

    // { name: 'Statistics', link: '' },
    // { name: 'Escrow', link: '' },
    { name: 'Saved', link: '/my/favourite' },
    // { name: 'Settings', link: '/profile/settings' },

    // { name: '', link: '' },
    // { name: '', link: '' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MainHeader() {
    const { user, logout} = useAuth();
    const navigate = useNavigate();
    const [cartOpen, setCartOpen] = useState(false)

    const handleLogout = () => {
        logout();
        navigate('/auth/login');
    }

    const handleLogin = () => {
        navigate('/auth/login');
    }

    const [userProfile, setUserProfile] = useState({});


    const getUserProfile = async () => {
        try {
            const res = await apiInstance.get("profile/profilepersonal/?me=true");
            const userProfile = res.data.results[0];
            setUserProfile(userProfile);
            console.log(res.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        getUserProfile();
    }
    // eslint-disable-next-line
    , [])


    return (

        // <div className="fixed top-0 left-0 w-full bg-white shadow-lg z-10">
        <div className='sticky top-0 absolute z-10'>
            <header className="relative bg-white ">
                <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                    Get the best Tutors Today!
                </p>

                <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="border-b border-gray-200">
                        <div className="flex h-16 items-center">
                            <div className="ml-4 flex lg:ml-0">
                                <Link to='/'>
                                    <span className="sr-only">Tantorial</span>
                                    <img
                                        className="h-12 w-auto"
                                        src="/assets/images/logo.png"
                                        alt=""
                                    />
                                </Link>
                            </div>

                            <div className="ml-auto flex items-center">
                                {
                                    user?.tutor ? (<div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                        <Link to='/all-students' className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                            Find Students
                                        </Link>
                                        <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                                    </div>)
                                        : (<div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                            <Link to='/home' className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                                Find Tutors
                                            </Link>

                                            <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                                        </div>)
                                }
                                {/* <div className="flex lg:ml-6">
                                    <a href="/" className="flex items-center text-gray-700 hover:text-gray-800">
                                        <img
                                            src="https://tailwindui.com/img/flags/flag-canada.svg"
                                            alt=""
                                            className="block h-auto w-5 flex-shrink-0"
                                        />
                                        <span className="ml-3 block text-sm font-medium">ENG</span>
                                        <span className="sr-only">, change Language</span>
                                    </a>
                                </div> */}

                                {/* Cart */}

                                {
                                    user ? (
                                        <>
                                            {/* <div className="ml-4 flow-root lg:ml-6">
                                                <div className="group -m-2 flex items-center p-2">
                                                    <ChatBubbleLeftIcon
                                                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                        aria-hidden="true"
                                                        onClick={() => setCartOpen(true)}
                                                    />
                                                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">0</span>
                                                    <span className="sr-only">Interested Profiles</span>
                                                </div>
                                            </div> */}
                                            <div className="ml-4 flow-root lg:ml-6">
                                                <div className="group -m-2 flex items-center p-2">
                                                    <BellAlertIcon
                                                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                        aria-hidden="true"

                                                    />
                                                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">0</span>
                                                    <span className="sr-only">Notification</span>
                                                </div>
                                            </div>

                                            <Menu as="div" className="relative ml-3">
                                                <div>
                                                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                        <span className="absolute -inset-1.5" />
                                                        <span className="sr-only">Open user menu</span>
                                                        <img
                                                            className="h-8 w-8 rounded-full"
                                                            src={userProfile?.profile_pic}
                                                            alt=""
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to='/profile'
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    {userProfile?.first_name} {userProfile?.last_name}
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        {
                                                            navMenu?.map((item, index) => (
                                                                <Menu.Item key={index}>
                                                                    {({ active }) => (
                                                                        <Link
                                                                            to={item.link}
                                                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                        >
                                                                            {item.name}
                                                                        </Link>
                                                                    )}
                                                                </Menu.Item>
                                                            ))
                                                        }
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={handleLogout}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    Logout
                                                                </button>
                                                            )}
                                                        </Menu.Item>

                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </>
                                    )
                                        : (
                                            <>
                                                <button
                                                    onClick={handleLogin}
                                                    className="ml-4 bg-gray-600 text-white px-4 py-2 rounded-md text-sm font-bold font-medium">
                                                    Login/Signup
                                                </button>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                    </div>

                    <Cart open={cartOpen} setOpen={setCartOpen} />
                </nav>
            </header>
        </div>
    )
}
