import React, { useState, useEffect } from "react";
import {
  Container,
  Modal,
} from "@mantine/core";

import { apiInstance } from '../../config/api';
import ProfileBack from "../../components/ProfileBack";
import Certification from "../../components/userprofile/create/Certification";
import AddButton from "../../components/partials/AddButton";
import CertificationTable from "../../components/userprofile/datatable/CertificationTable";
import { useDisclosure } from "@mantine/hooks";

function CertificationAdd() {
  const [opened, { open, close }] = useDisclosure(false);
  const [myCertification, setMyCertification] = useState([]);

  useEffect(() => {
    apiInstance.get("profile/certification/?me=true")
      .then((res) => {
        console.log(res);
        const certificationList = res.data.results;
        setMyCertification(certificationList);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <div>
      <ProfileBack />
      <AddButton openModal={open} text="Add New Certification" />
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}>
        <Certification onClose={close}
          setData={setMyCertification}
        />
      </Modal>
      <CertificationTable data={myCertification} setData={setMyCertification} />
    </div>
  );
}

export default CertificationAdd;
