import React, { useEffect, useState } from "react";


const categories = [
    {
        title: 'Teach you way',
        description: 'Publish the profile you want, in the way you want, and always have control of your own profile',
    },
    {
        title: 'Inspire Leaners',
        description: 'Teach what you know and help learners explore their interests, gain new skills, and advance their careers.'
    },
    {
        title: 'Get Rewarded',
        description: 'Expand your professional network, build your expertise, and earn money on each paid enrollment, it’s your choice.',
    },
    {
        title: "Impact your Community",
        description: 'Help learners gain skills and pursue their goals by sharing your knowledge.',
    }

]

export default function Clouds() {

    return (
        <div className="bg-white py-12 ">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="text-center text-3xl font-bold leading-8 text-gray-900">
                    So many reasons to start
                </h2>
                <div className="mx-auto mt-5 grid max-w-lg grid-cols-1  items-center gap-x-8 gap-y-10 sm:max-w-xl  sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                    {
                        categories.map((category) => (
                            <div className="max-h-full">
                                <p className=" font-bold text-2xl w-full object-contain">
                                    {category.title}
                                </p>
                                <p className="text-base w-full object-contain mt-2">
                                    {category.description}
                                </p>
                            </div>


                        ))
                    }

                </div>
            </div>
        </div>
    )
}
