import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "@mantine/form";
import {

  Grid,
  Group,
  Box,
  Container,
  Paper,
  Checkbox,
  TextInput,
  rem,
  Text,
} from "@mantine/core";
import Select from 'react-select';
import {
  IconCamera
} from "@tabler/icons";
import CustomLoader from "../../components/partials/CustomLoader";
import { apiInstance } from '../../config/api';
import ProfileBack from "../../components/ProfileBack";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";


function PersonalProfileAdd() {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const [allCountry, setAllCountry] = useState([]);
  const [allRegion, setAllRegion] = useState([]);
  const [allTown, setAllTown] = useState([]);
  const [allGender, setAllGender] = useState([]);
  const [allTitle, setAllTitle] = useState([]);
  const [userProfile, setUserProfile] = useState({});

  const [country, setCountry] = useState(userProfile?.country ?? '');
  const [region, setRegion] = useState(userProfile?.region ?? '');
  const [town, setTown] = useState(userProfile?.town ?? '');
  const [gender, setGender] = useState(userProfile?.gender ?? '');
  const [title, setTitle] = useState(userProfile?.title ?? '');
  const [showWhatsapp, setShowWhatsapp] = useState(userProfile?.show_whatsapp_number ?? '')
  const [formValues, setFormValues] = useState({
    first_name: userProfile?.first_name ?? '',
    last_name: userProfile?.last_name ?? '',
    phone_number: userProfile?.phone_number ?? '',
    whatsapp_number: userProfile?.whatsapp_number ?? '',
    address: userProfile?.address ?? '',
    date_of_birth: userProfile?.date_of_birth ?? '',
    profile_pic: userProfile?.profile_pic ?? '',
    deleted: userProfile?.deleted ?? '',
    archived: userProfile?.archived ?? '',
  });

  const handleInputChange = (name) => (e) => {
    const value = e.target.value
    setFormValues({ ...formValues, [name]: value   });
  };


  const demoProfile = formValues?.profile_pic ?? "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg";
  const [Imagefile, setImageFile] = useState(demoProfile);
  const [Image, setImage] = useState({base64: '', file: ''});


  function handleChangeProfile(e) {
    console.log(e.target.files);
    setImage(e.target.files[0])
    setImageFile(URL.createObjectURL(e.target.files[0]));
  }

  const isFile = (input) => "File" in window && input instanceof File;
  const isChanged = (input) => input.length > 0;


  const on_save = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    isFile(Image) && formData.append('profile_pic', Image)
    formData.append('first_name', formValues.first_name)
    formData.append('last_name', formValues.last_name)
    formData.append('title', title?.value)
    formData.append('phone_number', formValues.phone_number)
    formData.append('whatsapp_number', formValues.whatsapp_number)
    formData.append('show_whatsapp_number', showWhatsapp)
    formData.append('country', country?.value)
    formData.append('date_of_birth', formValues.date_of_birth)
    formData.append('gender', gender?.value)
    formData.append('region', region?.value)
    formData.append('town', town?.value)
    formData.append('address', formValues.address)
   

    apiInstance.post("profile/profilepersonal/", formData)
      .then((res) => {
        console.log(res);
        setSubmitting(false);
        localStorage.setItem('userProfile', JSON.stringify(res.data));
        console.log("Profile Added");
        navigate('/profile')
        
      })
      .catch((err) => {
        console.log(err);
        alert("Profile Not Added");
        setSubmitting(false);
      });
  }

  useEffect(() => {
    apiInstance.get( "misc/gender/")
      .then((res) => {
        console.log(res);
        const genderList = res.data.results;
        setAllGender(genderList.map((item) => ({ value: item.title.toUpperCase(), label: item.title.toUpperCase() })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    apiInstance.get( "profile/profilepersonal/?me=true")
      .then((res) => {
        console.log(res);
        const userProfile = res.data.results[0];
        setFormValues(userProfile);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    apiInstance.get( "misc/title/")
      .then((res) => {
        console.log(res);
        const titleList = res.data.results;
        setAllTitle(titleList.map((item) => ({ value: item.title.toUpperCase(), label: item.title.toUpperCase() })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    apiInstance.get( "misc/country/")
      .then((res) => {
        console.log(res);
       const  countryList = res.data.results;
        setAllCountry(countryList.map((item) => ({ value: item.id, label: item.title })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    apiInstance.get( `misc/region/?country=${country.value}`)
      .then((res) => {
        console.log(res);
       const  regionList = res.data.results;
        setAllRegion(regionList.map((item) => ({ value: item.id, label: item.title })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [country]);

  useEffect(() => {
    apiInstance.get( `misc/town/?region=${region.value}`)
      .then((res) => {
        console.log(res);
        const townList = res.data.results;
        setAllTown(townList.map((item) => ({ value: item.id, label: item.title })));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [region]);


  return (
    <Container>
      <ProfileBack />
      <Text
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
        fz='xl'
      >
        Provide Your Personal Details 
      </Text>
      <Box position="relative">
        <CustomLoader visible={submitting} />
        <Paper withBorder shadow="md" p={30} radius="md">
          <form onSubmit={on_save}>
            <Grid mt={20}>
              <Grid.Col md={12}>
                <Group position="center" style={{ position: 'relative' }}>
                  {Imagefile && <img src={Imagefile} width="200" style={{ borderRadius: '100%' }} alt="Profile picture" />}
                  <label htmlFor="file" style={{ position: 'absolute', bottom: '40%', left: '50%', transform: 'translateX(-50%)' }}>
                    <IconCamera size={rem(50)} />
                    <span>Add Image</span>
                  </label>
                  <input
                    id="file"
                    type="file"
                    onChange={handleChangeProfile}
                    style={{ display: 'none' }}
                    accept="image/*"
                  />
                </Group>
              </Grid.Col>

              <Grid.Col sm={12} md={6}>
                <label className="font-semibold mx-1">
                  Gender
                </label>
                <Select
                  options={allGender}
                  placeholder='Select Your Gender'
                  defaultValue={gender}
                  onChange={setGender}
                />
              </Grid.Col>

              <Grid.Col sm={12} md={6}>
                <label className="font-semibold mx-1">
                  Title
                </label>
                <Select
                  placeholder="Select a title"
                  options={allTitle}
                  onChange={
                    setTitle
                  }
                />
              </Grid.Col>
              <Grid.Col sm={12} md={12}>
                <TextInput
                  label="First Name"
                  placeholder="First Name"
                  name="first_name"
                  value={formValues.first_name}
                  onChange={
                    handleInputChange('first_name')
                  }
                  radius="md"
                  size="md"
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col sm={12} md={12}>
                <TextInput
                  label="Last Name"
                  placeholder="Last Name"
                  value={formValues.last_name}
                  name = "last_name"
                  onChange={
                    handleInputChange("last_name")
                  }
                  radius="md"
                  size="md"
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <TextInput
                  label="Date of Birth"
                  type="date"
                  placeholder="Date of Birth"
                  name="date_of_birth"
                  value={formValues.date_of_birth}
                  onChange={
                    handleInputChange("date_of_birth")
                  }
                  radius="md"
                  size="md"
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                {/* {formValues.country}dd */}
                <label className="font-semibold mx-1">
                Country
                </label>
                <Select
                  placeholder="Select Country"
                  options={allCountry}
                  defaultValue={country}
                  onChange={
                    setCountry
                  }

                />
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <label className="font-semibold mx-1">
                Region
                </label>
                <Select
                  placeholder="Select Region"
                  options={allRegion}
                  onChange={
                    setRegion
                  }
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <label className="font-semibold mx-1">
                Town
                </label>
                <Select
                  label="Town"
                  placeholder="Select Town"
                  options={allTown}
                  onChange={
                   setTown
                  }
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <TextInput
                  label="Quater"
                  placeholder="Please Enter Where you leave"
                  type="text"
                  value={formValues.address}
                  name="address"
                  onChange={
                    handleInputChange("address")
                  }
                  radius="md"
                  size="md"
                  className="w-full"
                />
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <TextInput
                  label="Phone Number"
                  placeholder="Phone Number"
                  type="number"
                  value={formValues.phone_number}
                  name="phone_number"
                  onChange={
                    handleInputChange("phone_number")
                  }
                  radius="md"
                  size="md"
                  className="w-full"
                />
              </Grid.Col>

              <Grid.Col sm={12} md={6}>
                <TextInput
                  label="Whatsapp Number"
                  placeholder="Whatsapp Number"
                  type="number"
                  name="whatsapp_number"
                  value={formValues.whatsapp_number}
                  onChange={
                    handleInputChange("whatsapp_number")
                  }
                  radius="md"
                  size="md"
                  className="w-full"
                />
              </Grid.Col>

              <Grid.Col sm={12} md={6}>
                <Checkbox
                  label="Show Whatsapp Number on Profile"
                  checked={showWhatsapp}
                  
                  onChange={
                    (event) => setShowWhatsapp(event.currentTarget.checked)
                  }
                  color="lime"
                  radius="xl"
                  size="md"
                  className="mt-4"
                />
              </Grid.Col>
            </Grid>
            <Group>
              <button 
              type="submit"
              className="bg-indigo-500 my-3 py-2 hover:bg-indigo-600 px-3 py-2 text-lg font-semibold text-white py-2 px-4 rounded w-full">
                Save
              </button>

            </Group>
          </form>
        </Paper>
      </Box>

    </Container>
  );
}

export default PersonalProfileAdd