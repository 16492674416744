import React, { useState, useEffect } from 'react'
import StarRating from 'react-star-ratings';
import { apiInstance } from '../../config/api';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';

function Reviews() {
    const [reviewsAndRatings, setReviewsAndRatings] = useState([]);
    const [rating, setRating] = useState(1)
    const [review, setReview] = useState("")
    const { id } = useParams();

    useEffect(() => {
        const fetchReviewsAndRatings = async () => {
            try {
                const response = await apiInstance.get(`like/reviews/?profile=${id}`);
                if (response.status === 200) {
                    setReviewsAndRatings(response?.data?.results);
                   
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchReviewsAndRatings();
    }
        , [id]);

    const handleReviewSubmit = () => {
        const data = {
            rating: rating,
            content: review,
            profile: id,
        };

        apiInstance.post('like/reviews/', data)
            .then((res) => {
                setReviewsAndRatings([...reviewsAndRatings, res.data]);
                notifications.show({
                    title: 'Review Sent ',
                    message: 'Successfully reviewed',
                    color: 'green',
                    autoClose: true,
                })
                setRating(1)
                setReview("")
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <>
            <div className=" lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
                <div className="container mx-auto mt-6">
                    <h1 className="text-3xl font-semibold mb-4">Reviews and Ratings</h1>
                    {
                        reviewsAndRatings.length === 0 && (
                            <div className="bg-white p-6 rounded-lg shadow-lg">
                                <p className="text-gray-600 mb-2 text-2xl font-bold">No reviews yet</p>
                            </div>
                        )
                    }
                    <ul className="space-y-4">
                        {reviewsAndRatings.map((item) => (
                            <li key={item.id}>
                                <div className="bg-white p-3 rounded-lg shadow-lg">
                                    <p className="text-lg font-semibold mb-2">
                                        {item.tutorName} <span className="text-gray-600"> {`${item.user?.first_name} ${item.user?.last_name}`}</span> {" "}
                                        {dayjs(item.created_at).format('DD-MMM-YYYY HH:mm A')}
                                    </p>
                                    <p className="text-gray-600 mb-2">
                                        <StarRating
                                            rating={item.rating}
                                            starRatedColor="gold"
                                            numberOfStars={5}
                                            starDimension="20px"
                                            starSpacing="2px"
                                        />
                                    </p>
                                    <p className="text-gray-600 mb-2">{item.content}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full mx-auto rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Leave a Review</h2>
                    <div className="mb-4">
                        <StarRating
                            rating={rating} // Set the initial rating (0 for no rating)
                            starRatedColor="gold"
                            changeRating={(newRating) => setRating(newRating)} // Handle rating change
                            numberOfStars={5}
                            starDimension="25px"
                            starSpacing="2px"
                        />
                    </div>
                    <textarea
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        rows="4"
                        placeholder="Write your review here..."
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                    ></textarea>
                    <button
                        onClick={handleReviewSubmit}
                        className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700">
                        Submit Review
                    </button>
                </div>
            </div>
        </>


    )
}

export default Reviews