import React from "react";
import Hero from "../components/landing/Hero";
import HeaderHome from "../components/landing/HeaderHome";
import FirstTextCard from "../components/landing/FirstTextCard";
import AgileCard from "../components/landing/AgileCard";
import AutomationCard from "../components/landing/AutomationCard";
import ServiceCard from "../components/landing/ServiceCard";
import ProductMosst from "../components/landing/ProductMosst";
import Clouds from "../components/landing/Clouds";
import Stats from "../components/landing/Stats";
import FooterHome from "../components/landing/FooterHome";
import Testimonial from "../components/landing/Testimonial";
import Team from "../components/landing/Team";
import Contact from "../components/partials/Contact";
import PopularSubject from "../components/landing/PopularSubject";
import PopularTutors from "../components/landing/PopularTutors";
import Pricing from "../components/landing/Pricing";

export default function LandingPage() {
    return (
        <div>
            <HeaderHome />
            <Clouds />
            <Stats />
            <PopularSubject />
            <FirstTextCard />
            <AgileCard />
            <PopularTutors />
            <AutomationCard />
            <ServiceCard />
            <Testimonial />
            <Team />
            <ProductMosst />
            <Pricing />
            <Contact />
            <FooterHome />
        </div>
    );
};