import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL, apiInstance } from '../../../config/api';
import { useAuth } from '../../../context/auth-context';
import {
    Container,
    Text,
    Button,
    Group,
    NumberInput,
    Select,
    Radio, Paper, Tooltip, Divider, Checkbox
} from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';


function PreferTutor() {
    const navigate = useNavigate()
    const { state } = useLocation();
    const childClass = state?.childClass;
    const ChildCount = state?.ChildCount;
    const tutoringType = state?.tutoringType;
    const profileID = state?.profileID;
    const [genders, setGenders] = useState([])
    const [preferGender, setPreferGender] = useState('any');
    const [preferLanguage, setPreferLanguage] = useState('bilingual');
    const [minAge, setMinAge] = useState()
    const [maxAge, setMaxAge] = useState()
    const [educationLevel, setEducationLevel] = useState()
    const [ageApplicable, setAgeApplicable] = useState(false)
    const [degrees, setDegrees] = useState([])
    const [languages, setLanguages] = useState([])

    const handleBackClick = () => {
        window.history.back();
    };

    const handleNextClick = () => {
       apiInstance.post(`tutoringprofile/learner-tutor/`, {
            learner_profile: profileID,
            apply_age_range: ageApplicable,
            min_age: minAge,
            max_age: maxAge,
            tutor_primary_language: preferLanguage,
            tutor_education_level: educationLevel,
            tutor_gender: preferGender
        })
            .then((res) => {
                console.log(res)
                navigate('/tutoring/lesson-schedule', {
                    state: {
                        childClass,
                        ChildCount,
                        tutoringType,
                        profileID: profileID,
                    }
                })
            }
            )
            .catch((err) => {
                console.log(err)
            }
            )

    }


    useEffect(() => {
       apiInstance.get(`misc/degree/`)
            .then((res) => {
                const degreeList = res.data.results
                setDegrees(degreeList?.map((item) => ({ value: item.title, label: item.title.toUpperCase() })))
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
       apiInstance.get(`misc/gender/`)
            .then((res) => {

                setGenders(res.data.results)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
       apiInstance.get(`misc/language/`)
            .then((res) => {
                setLanguages(res.data.results)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    return (
        <Container mb={30}>
            <Text size={25} fw={700} fs="italic">
                Tutor Credentials
            </Text>
            <Text size={20} fs="italic">
                Tell us about your Prefer tutor
            </Text>
            <Paper shadow="xl" p="xl" mt={20} withBorder>
                <Radio.Group
                    name="tutoring-type"
                    label="Tutor Gender?"
                    size="lg"
                    value={preferGender}
                    onChange={setPreferGender}
                >
                    <Group mt="xs">
                        {
                            genders?.map((item) => (
                                <Radio value={`${item.title}`} label={item.title.toUpperCase()} size="lg" />
                            ))
                        }
                        <Radio value="All" label="Any Gender" size="lg" />
                    </Group>
                </Radio.Group>
                <Divider mt={20} mb={20} size="sm" />
                <Text my={20} size={20} fw={700} fs="italic">
                    Tutor Age Range
                </Text>
                <Checkbox
                    label="Age is not Applicable"
                    color="green"
                    radius="xl"
                    size="xl"
                    checked={ageApplicable}
                    onChange={(event) => setAgeApplicable(event.currentTarget.checked)}
                />
                <Group spacing="xl" mt="xs">

                    <Tooltip label="Min Age of Tutor">
                        <NumberInput
                            label="From Age"
                            size="lg"
                            value={minAge}
                            onChange={setMinAge}
                            min={16}
                            styles={{ input: { width: '100%', textAlign: 'center' } }}
                            disabled={ageApplicable}
                        />

                    </Tooltip>
                    <Tooltip label="Maximum Age of Tutor">
                        <NumberInput
                            label="Max Age"
                            size="lg"
                            value={maxAge}
                            onChange={setMaxAge}
                            min={minAge}
                            styles={{ input: { width: '100%', textAlign: 'center' } }}
                            disabled={ageApplicable}
                        />
                    </Tooltip>
                </Group>
                <Divider mt={20} mb={20} size="sm" />
                <Radio.Group
                    name="tutor-language"
                    label="Tutor Primary Language?"
                    size="lg"
                    value={preferLanguage}
                    onChange={setPreferLanguage}
                >
                    <Group mt="xs">
                        {
                            languages?.map((item) => (
                                <Radio value={`${item.title}`} label={item.title.toUpperCase()} size="lg" />
                            ))
                        }
                    </Group>
                </Radio.Group>
                <Divider mt={20} mb={20} size="sm" />
                <Select
                    label="Tutor Education Level"
                    placeholder="Education Level"
                    size='xl'
                    data={degrees}
                    value={educationLevel}
                    onChange={setEducationLevel}
                    styles={{ input: { textAlign: 'center' } }}
                />
            </Paper>
            <Group position="apart">
                <Button mt={20} variant="default" radius="lg" size="lg" onClick={handleBackClick} uppercase>
                    Back
                </Button>
                <Button mt={20} variant="default" radius="lg" size="lg" onClick={handleNextClick} uppercase>
                    Next
                </Button>
            </Group>
        </Container>
    )
}

export default PreferTutor