import React, {useState} from 'react'
import { Container, Text, Title, Center, Grid, Card, Image, Badge, Button, Group } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
const DATA = [
  {
    title: 'For myself',
    description: `Find a tutor today! ... 
    Want to tutor to learn subject, brush up your language skills
     or want upgrade your IT skills, we have got the best selection of Tutors for you. 
     Post your requirement for free and find an excellent tutor.`,
    image: 'https://images.unsplash.com/photo-1518549945084-3cde28941638?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWF0aCUyMG1hcmtldGluZ3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
    label: 'Tell Us more about yourself',
    route: '/tutoring/for-me',

  },
  {
    title: 'For my child',
    description: `Find a tutor today! ... Help your child to learn better! and Improve your child's grades with a tutor!`,
    image: 'https://images.unsplash.com/photo-1581090406932-7e0bfa0b1b2b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2NpZW5jZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
    label: 'Tell Us more about your child',
    route: '/tutoring/for-kids',
  }
]

function AboutLearner() {
  const navigate = useNavigate()
  return (
    <Container>
      <Center>
        <Title order={3}>
          Let us Find the best tutor for you!!
        </Title>
      </Center>
      <Grid my={20}>
        {
          DATA.map((data) => (
            <Grid.Col key={data.title} md={6} sm={12}>
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section component="a" href="#">
                  <Image
                    src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
                    height={160}
                    alt="Norway"
                  />
                </Card.Section>

                <Group position="apart" mt="md" mb="xs">
                
                  <Text weight={500}>
                    {data.title}
                  </Text>
        
                  <Badge color="pink" variant="light">
                    Start Now
                  </Badge>
                </Group>

                <Text size="sm" color="dark">
                 {data.description}
                </Text>
                <Button variant="light" color="blue" fullWidth mt="md" radius="md" onClick={() => navigate(data.route)}>
                  {
                    data.label
                  }
                </Button>
              </Card>
            </Grid.Col>
          ))
        }
      </Grid>

    </Container>
  )
}

export default AboutLearner