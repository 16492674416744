import React, { useState, useEffect } from "react";
import {
  Container,
  Modal,
} from "@mantine/core";

import { apiInstance } from '../../config/api';
import ProfileBack from "../../components/ProfileBack";
import Schedule from "../../components/userprofile/create/Schedule";
import AddButton from "../../components/partials/AddButton";
import ScheduleTable from "../../components/userprofile/datatable/ScheduleTable";
import { useDisclosure } from "@mantine/hooks";


function TutoringScheduleAdd() {
  const [opened, { open, close }] = useDisclosure(false);
  const [mySchedule, setMySchedule] = useState([]);

  useEffect(() => {
    apiInstance.get("profile/schedule/?me=true")
      .then((res) => {
        console.log(res);
        const scheduleList = res.data.results;
        setMySchedule(scheduleList);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <ProfileBack />
      <AddButton openModal={open} text="Add New Schedule" />
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}>
        <Schedule onClose={close}
          setData={setMySchedule}
        />
      </Modal>
      <ScheduleTable data={mySchedule} setData={setMySchedule} />
    </div>
  );
}

export default TutoringScheduleAdd;
