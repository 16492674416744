import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import BookLesson from "./book/BookLesson";



function CTACard({ profile }) {
  const [opened, { open, close }] = useDisclosure(false);

  let currencyFormater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'XAF',
  })

  return (


    <div className="mt-0 lg:mt-2 lg:row-span-3 lg:sticky top-20 lg:mt-0 bg-white p-4 rounded-lg shadow-lg">
    
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}>
        <BookLesson first_name={profile && profile?.profilepersonal[0]?.first_name}
          tutor_id={profile && profile?.profilepersonal[0]?.user?.id}
          onClose={close}
        />
      </Modal>
      <div className="mt-1">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">Prices: </h3>
          <p className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
              Explain this ?
            </p>
        </div>

        <RadioGroup className="mt-2">
          <RadioGroup.Label className="sr-only">Tutor Fee </RadioGroup.Label>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-2">
            {profile && profile?.paymentrate?.map((rate) => (
              <RadioGroup.Option
                key={rate.id}
                value={rate?.town}
                className=
                'ring ring-offset-1 text-indigo-600 ring-2 ring-indigo-500 group relative flex items-center justify-center rounded-md border py-1 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
              >

                <RadioGroup.Label as="span">{rate?.mode} -- {currencyFormater.format(rate?.amount)}/{rate?.charge} </RadioGroup.Label>

                <span
                  aria-hidden="true"
                  className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                >
                  <svg
                    className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    stroke="currentColor"
                  >
                    {/* <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" /> */}
                  </svg>
                </span>

              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      {/* Sizes */}
      <div className="mt-3">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">Locations </h3>
          <p className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            Explain this ?
          </p>
        </div>

        <RadioGroup className="mt-2">
          <RadioGroup.Label className="sr-only">Locations</RadioGroup.Label>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-2">
            {profile &&  profile?.tutoringlocation?.map((location) => (
              <RadioGroup.Option
                key={location.id}
                value={location?.town}
                className=
                'ring ring-offset-1 text-indigo-600 ring-2 ring-indigo-500 group relative flex items-center justify-center rounded-md border py-1 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
              >

                <RadioGroup.Label as="span">{location?.town},  {location?.quater}</RadioGroup.Label>

                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                    >
                      <svg
                        className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        stroke="currentColor"
                      >
                        {/* <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" /> */}
                      </svg>
                    </span>

              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      <button
        onClick={open}
        className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Book this tutor
      </button>
    </div>

  )
}

export default CTACard