import React, { useState } from 'react'
import {
    createStyles,
    Paper,
    Title,
    Text,
    PasswordInput,
    Container,
    Group,
    Anchor,
    Center,
    Box,
    rem,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { apiInstance } from '../../config/api';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth-context';


const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));


export default function ChangePassword() {
    const { classes } = useStyles();
    const { logout } = useAuth();
    const [currentPassword, setCurrentPassword] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [error, setError] = useState("")
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const uid = searchParams.get('uid');
    const token = searchParams.get('token');


    const submit_passwordchange = async (e) => (
        await apiInstance.post(`auth/users/set_password/`, {
            current_password: currentPassword,
            new_password: password,
            re_new_password: password2,
        })
            .then(response => {
                notifications.show({
                    title: 'Password Reset Successful',
                    message: 'Login with your new password',
                    color: 'green',
                    autoClose: true,
                })
                logout();
                navigate('/auth/login')
            })
            .catch(error => {
                setError("Current Password is incorrect")
                console.log(error)
                notifications.show({
                    title: 'Password Error',
                    message: "Current Password is incorrect",
                    color: 'red',
                    autoClose: true,
                })
                setInterval(() => {
                    setError("")
                }
                    , 10000)

            })

    )

    return (
        <Container size={460} my={30}>
            <Title className={classes.title} align="center">
                Change your password?
            </Title>
            <Text c="dimmed" fz="sm" ta="center">
                Enter your new password
            </Text>
            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                <PasswordInput
                    label="Current Password"
                    placeholder="Your Current password"
                    value={currentPassword}
                    onChange={(event) => setCurrentPassword(event.currentTarget.value)}
                    radius="md"
                    size="md"
                    withAsterisk
                />
                <Text c="red" fz="sm">
                    {error}
                </Text>

                <PasswordInput
                    label="New Password"
                    placeholder="Your New password"
                    value={password}
                    onChange={(event) => setPassword(event.currentTarget.value)}
                    error={(password && password.length < 6) && 'Password should include at least 6 characters'}
                    radius="md"
                    size="md"
                    withAsterisk
                />

                <PasswordInput
                    label="Confirm Password"
                    placeholder="Confirm password"
                    value={password2}
                    onChange={(event) => setPassword2(event.currentTarget.value)}
                    error={(password !== password2) && 'Password do not match'}
                    radius="md"
                    size="md"
                    withAsterisk
                />
                <Group position="apart" mt="lg" className={classes.controls}>

                    <button
                        disabled={(password !== password2) || (password.length < 6)}
                        onClick={submit_passwordchange}
                        className="block w-full bg-indigo-600 text-white font-semibold py-2 px-4 transition duration-300 hover:bg-indigo-70">
                        Change Password
                    </button>
                    <Anchor color="gray" size="sm" className={classes.control}>
                        <Center inline>
                            <IconArrowLeft size={rem(12)} stroke={1.5} />
                            <Box ml={5}>
                                <Link to="/profile" style={{ textDecoration: "none" }}>
                                    Back to profile
                                </Link>
                            </Box>
                        </Center>
                    </Anchor>
                </Group>
            </Paper>
        </Container>
    );
}