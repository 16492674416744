import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../config/api';
import { notifications } from '@mantine/notifications';


function ComingSoon() {
    const [contact, setContact] = useState('')
    const [loading, setLoading] = useState(false)
    const [countdownDate, setCountdownDate] = useState(new Date('Nov 25, 2023 00:00:00').getTime());
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new

                Date().getTime();
            const

                distance = countdownDate - now;

            setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

            if (distance < 0) {
                clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [countdownDate]);

    const handleSubmit = (e) => {

        e.preventDefault();
        setLoading(true)
        axios.post(`${API_URL}newsletters/contact/`, {
            contact: contact
        })
            .then(res => {
                console.log(res)
                notifications.show({
                    title: 'Thank you for trusting us',
                    message: 'We will notify you when we launch.',
                    color: 'green',
                    autoClose: true,
                })
                setContact('')
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }


    return (

        <div className="h-screen flex justify-center items-center px-2 bg-gradient-to-tr from-sky-300 via-sky-400 to-blue-500">

            <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="py-4 px-6">
                    <img className="mx-auto h-20 w-auto hover:animate-spin" src="/assets/images/TANTORIAL.png" alt="Tantorial" />

                    <div className="text-center">
                        <h1 className="text-3xl font-extrabold text-gray-900 text-orange-500 hover:animate-bounce">Welcome to TANTORIAL</h1>
                        <p className="mt-3 text-lg leading-6 text-gray-500">We are building a platform that will help you learn and earn.</p>
                    </div>
                    <div className="text-center mt-3">
                        <h1 className="text-3xl font-extrabold text-gray-900 animate-pulse">We are launching soon</h1>
                        <p className="mt-3 text-lg text-gray-500">Our website is under construction.</p>
                    </div>
                    <div className="py-4 px-6">
                        <div className="flex flex-wrap gap-2 justify-center items-center">
                            <div className="border rounded-lg px-4 py-2">
                                <div id="days" className="font-bold font-mono text-2xl text-gray-800">{days}d</div>
                            </div>
                            <div className="border rounded-lg px-4 py-2">
                                <div id="hours" className="font-bold font-mono text-2xl text-gray-800">{hours}h</div>
                            </div>
                            <div className="border rounded-lg px-4 py-2">
                                <div id="minutes" className="font-bold font-mono text-2xl text-gray-800">{minutes}m</div>
                            </div>
                            <div className="border rounded-lg px-4 py-2">
                                <div id="seconds" className="font-bold font-mono text-2xl text-gray-800">{seconds}s</div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <form className="mx-auto max-w-xs">
                            <div className="flex items-center border-b border-gray-500 py-2">
                                <input type="email"
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                                    placeholder="Email or phone number" />
                                <button
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    className="flex-shrink-0 bg-cyan-500 hover:bg-cyan-700 border-cyan-500 hover:border-cyan-700 text-sm border-4 text-white py-1 px-2 rounded"
                                    type="submit">
                                    Notify me
                                </button>
                            </div>
                        </form>
                        <p className="mt-2 text-center text-gray-500 text-xs">
                            We'll notify you when we launch.
                        </p>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default ComingSoon