import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';
import Select from 'react-select';

function EditSubject({ onClose, setData, editItem }) {
  const [submitting, setSubmitting] = useState(false);
  const [category, setCategory] = useState([])
  const [subCategory, setSubcategory] = useState([])
  const [language, setLanguage] = useState([])
  const [level, setLevel] = useState([])
  const [subject, setSubject] = useState([])


  const [mySubject, setMySubject] = useState([]);

  useEffect(() => {
    apiInstance.get('profile/subject/' + editItem?.id + '/')
      .then((res) => {
        console.log(res);
        setMySubject(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [editItem]);

  useEffect(() => {
    apiInstance.get("category/category/")
      .then((res) => {
        console.log(res);
        const list = res.data.results;
        setCategory(list);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    apiInstance.get("category/subcategory/?category=" + mySubject.category)
      .then((res) => {
        console.log(res);
        const list = res.data.results;
        setSubcategory(list);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, [mySubject.category]);

  useEffect(() => {
    apiInstance.get("misc/language/")
      .then((res) => {
        console.log(res);
        const list = res.data.results;
        setLanguage(list);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    apiInstance.get(`misc/level/?langauge=${mySubject.language}`)
      .then((res) => {
        console.log(res);
        const list = res.data.results;
        setLevel(list);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, [mySubject.language]);

  useEffect(() => {
    apiInstance.get(`misc/subject/?level=${mySubject.level}`)
      .then((res) => {
        console.log(res);
        const list = res.data.results;
        setSubject(list);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, [mySubject.level]);


  const fixedOptions = (data) => {
    return data.map((item) => ({
      value: item.id,
      label: item.name || item.title,
    }));
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { user, ...newData } = mySubject;
    apiInstance.patch('profile/subject/' + editItem?.id + '/', newData)
      .then((res) => {
        console.log(res.data);
        setData((prevData) => prevData.map((item) => item.id === res.data.id ? res.data : item))
        notifications.show({
          title: 'Item Edited',
          message: 'Successfully Edited Item',
          color: 'green',
          autoClose: true,
        })
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Editing Item',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMySubject({
      ...mySubject,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Edit Subject</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Category:</label>
          <Select
            options={fixedOptions(category)}
            onChange={(e) => setMySubject({ ...mySubject, category: e.value })}
          />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Subcategory:</label>
          <Select
            options={fixedOptions(subCategory)}
            onChange={(e) => setMySubject({ ...mySubject, subcategory: e.value })}
          />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Language:</label>
          <Select options={fixedOptions(language)} onChange={(e) => setMySubject({ ...mySubject, language: e.value })} />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Level:</label>
          <Select options={fixedOptions(level)} onChange={(e) => setMySubject({ ...mySubject, level: e.value })} />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Subject:</label>
          <Select options={fixedOptions(subject)} onChange={(e) => setMySubject({ ...mySubject, subject: e.value })} />
        </div>
        <div className="mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Edit Subject
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditSubject