import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { apiInstance } from '../config/api';
import LessonTable from '../components/userprofile/datatable/LessonTable';


function Lesson() {
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLessons = async () => {
            try {
                const response = await apiInstance.get('schedule/shedule-lesson/?mine=true');
                if (response.status === 200) {
                    setLessons(response?.data?.results);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        };
        fetchLessons();
    }, []);


    return (
        <>
            {
                lessons ? (
                    <LessonTable data={lessons} setData={setLessons} />
                ) : (
                    <div className="h-screen flex flex-col items-center mt-12 ">

                        <img
                            src="https://static.preply.com/space/images/no-tutorings-state.dafce7c556bcf76bcca0f9adc27a720c.png" // Replace with the path to your Disney-themed logo or image
                            alt="Tantorial"
                            className="w-64 mb-8"
                        />
                        <h4 className="text-2xl font-bold mb-2">Your Lesson will appear here</h4>
                        <p className="text-lg mb-4">Find students and teach lessons to get started</p>
                        <Link to="/" className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-4 rounded-full hover:shadow-md transition duration-300 ease-in-out">
                            Find Students
                        </Link>
                    </div>
                )
            }
        </>
    )
}

export default Lesson