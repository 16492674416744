import React from 'react'
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import ForgotPassword from '../pages/auth/ForgotPassword';
import SendActivationEmail from '../pages/auth/SendActivationEmail';
import AccountActivationPage from '../pages/auth/AccountActivationPage';
import ResetPasswordForm from '../pages/auth/ResetPasswordForm';
import ChangePassword from '../pages/auth/ChangePassword';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from "react-router-dom";
function AuthRoute() {
    return (
        <Routes>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="forgot-password/confirm" element={<ResetPasswordForm />} />
            <Route path="account/activated" element={<AccountActivationPage />} />
            <Route path="account/sendactivationemail" element={<SendActivationEmail />} />
            <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>

    )
}

export default AuthRoute