import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Notifications } from '@mantine/notifications';
import App from './App';
const container = document.getElementById('root');
const root = createRoot(container);


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Notifications position="top-right" zIndex={1000} />
      <App />
    </Provider>
  </React.StrictMode>
);
