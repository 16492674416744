import React, { useState, useEffect } from "react";
import {
  Container,
} from "@mantine/core";
import { apiInstance } from "../../config/api";
import ProfileBack from "../../components/ProfileBack";
import AddButton from "../../components/partials/AddButton";
import { useDisclosure } from "@mantine/hooks";
import WorkExperienceTable from "../../components/userprofile/datatable/WorkExperienceTable";
import WorkExperience from "../../components/userprofile/create/WorkExperience";
import { Modal } from "@mantine/core";

function WorkHistoryAdd() {
  const [opened, { open, close }] = useDisclosure(false);

  const [myWork, setMyWork] = useState([]);

  useEffect(() => {
    apiInstance
      .get("profile/workhistory/?me=true")
      .then((res) => {
        console.log(res);
        const workList = res.data.results;
        setMyWork(
          workList
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  return (
    <div>
      <ProfileBack />
      <AddButton openModal={open} text="Add New Work History" />
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}>
        <WorkExperience onClose={close}
          setData={setMyWork}
        />
      </Modal>
      <WorkExperienceTable data={myWork} setData={setMyWork} />
    </div>
  );
}

export default WorkHistoryAdd;
