import React, {useEffect, useState} from 'react'
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Title, Text } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import axios from 'axios'
import { API_URL } from '../../config/api'

function PopularSubject() {
    const [popularSubject, setPopularSubject] = useState([])
    const autoplay = useRef(Autoplay({ delay: 2000 }));

    useEffect(() => {
        axios.get(`${API_URL}misc/subject/?popular=true`)
        .then((res) => {
            setPopularSubject(res.data.results)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    , [])


  return (
    <div className='my-4 bg-gray-700'>
          <Title
              order={2}
              fz={70}
              className="my-8 pt-8 px-8 md:text-3xl text-center font-bold tracking-tight text-cyan-500 text-2xl "
          >
              Popular Subjects on Tantorial
          </Title>
          
          <Carousel
              withIndicators
              height={100}
              slideSize="20%"
              slideGap="md"
              loop
              dragFree
              plugins={[autoplay.current]}
              onMouseEnter={autoplay.current.stop}
              onMouseLeave={autoplay.current.reset}
              align="start"
              mx="auto"
              breakpoints={[
                  { maxWidth: "md", slideSize: "50%" },
                  { maxWidth: "sm", slideSize: "100%" },
              ]}
          >
              {popularSubject?.map((item, index) => {
                  return (
                      <Carousel.Slide
                          key={index}
                          gap="xl"
                          className="border-l-4 ml-4 pl-4 pr-4"
                      >
                          <Text className="font-bold text-white text-2xl mt-2">
                              {item.title}
                          </Text>
                      </Carousel.Slide>
                  );
              })}
          </Carousel>
    </div>
  )
}

export default PopularSubject