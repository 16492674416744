import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';

function Schedule({ onClose, setData }) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [mySchedule, setMySchedule] = useState(
    {
      day: '',
      start_time: '',
      end_time: '',
      mode: '',
    }
  );


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    apiInstance.post('profile/schedule/', mySchedule)
      .then((res) => {
        console.log(res.data);
        setData((prevData) => [...prevData, res.data]);
        notifications.show({
          title: 'Schedule Created',
          message: 'Successfully Created Schedule',
          color: 'green',
          autoClose: true,
        })
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Add Schedule',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMySchedule({
      ...mySchedule,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Add Schedule</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Day:</label>
          <input type="text" id="day" name="day" value={mySchedule.day} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Start Time:</label>
          <input
            type="time"
            id="start_time"
            name="start_time"
            value={mySchedule.start_time}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">End Time:</label>
          <input type="time" id="end_time" name="end_time" value={mySchedule.end_time} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Mode:</label>
          <select name="mode" id="mode" value={mySchedule.mode} onChange={handleChange} className="w-full p-2 border rounded-md">
            <option>Select Preferred Mode</option>
            <option value="online" >Online</option>
            <option value="in-person" >In Person</option>
          </select>
        </div>
        <div className="mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Add Schedule
          </button>
        </div>
      </form>
    </div>
  )
}

export default Schedule