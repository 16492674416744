import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';

function Certification({ onClose, setData }) {
  const [submitting, setSubmitting] = useState(false);
  const [myCertification, setMyCertification] = useState(
    {
      name_of_certification: '',
      name_of_organizaton: '',
      link_to_certification: '',
      date_of_completion: '',
      number_of_certificate: '',
    }
  );


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    apiInstance.post('profile/certification/', myCertification)
      .then((res) => {
        console.log(res.data);
        setData((prevData) => [...prevData, res.data]);
        notifications.show({
          title: 'Certification Created',
          message: 'Successfully Created Certification',
          color: 'green',
          autoClose: true,
        })
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Add Certification',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMyCertification({
      ...myCertification,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Add Certification</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Name of Certification:</label>
          <input type="text" id="country" name="name_of_certification" value={myCertification.name_of_certification} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Name of Issuing Organization:</label>
          <input
            type="text"
            id="school"
            name="name_of_organization"
            value={myCertification.name_of_organizaton}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Certification URL:</label>
          <input type="text" id="link_to_certification" name="link_to_certification" value={myCertification.link_to_certification} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Issuing Date:</label>
          <input type="date" id="date_of_completion" name="date_of_completion" value={myCertification.date_of_completion} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Certification ID:</label>
          <input
            type="text"
            id="number_of_certificate"
            name="number_of_certificate"
            value={myCertification.number_of_certificate}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        
        <div className="mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Add Certification
          </button>
        </div>
      </form>
    </div>
  )
}

export default Certification