import React, { useState, useEffect } from "react";
import { apiInstance } from "../../config/api";
import { notifications } from "@mantine/notifications";
import Select from "react-select";


function AddStudents({ onClose, setData }) {
  const [myClasses, setMyClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [requestData, setRequestData] = useState({
    student: "",
    tutor_class: "",
    });

  const fixedData = (data) => {
    return data.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  };

  const fixedProfile = (data) => {
    return data.map((item) => ({
      value: item.user.id,
      label: item.first_name + " " + item.last_name + "/" + item?.user?.email,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    apiInstance
      .post("tutorclass/studentclass/addStudent/addStudent/",requestData)
      .then((res) => {
        console.log(res.data);
        // setData((prevData) => [...prevData, res.data]);
        apiInstance.get('tutorclass/studentclass/?me=true')
        .then((res) => {
          console.log(res);
          setData(res.data.results);
        })
        .catch((err) => {
          console.log(err.response);
        });

        notifications.show({
          title: "Request Sent",
          message: res.data.message ,
          color: "green",
          autoClose: true,
        });
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: "Error Occured",
          message: err.response.data.error,
          color: "red",
          autoClose: true,
        });
      });
  };

  useEffect(() => {
    apiInstance
      .get("tutorclass/tutorclass/?me=true")
      .then((res) => {
        console.log(res);
        setMyClasses(res.data.results);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  useEffect(() => {
    apiInstance
      .get("profile/profilepersonal/?student=true")
      .then((res) => {
        console.log(res);
        setStudents(res.data.results);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
    , []);


  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Add Student to Class</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-1">
          <label htmlFor="price" className="block text-gray-600">
            Class:
          </label>
          <Select
            name="Class" 
            options={fixedData(myClasses)}
            isSearchable
            placeholder="Select Class"
            onChange={(e) => {
                setRequestData({
                ...requestData,
                tutor_class: e.value,
              });
            }}
          />
        </div>
        <div className="mb-1">
          <label htmlFor="price" className="block text-gray-600">
            Student Name/Email:
          </label>
          <Select
            name="Student Name/Email  "
            options={fixedProfile(students)}
            isSearchable
            placeholder="Select Student"
            onChange={(e) => {
                setRequestData({
                ...requestData,
                student: e.value,
              });
            }}
          />
        </div>

        <div className="mt-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Send Request
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddStudents;
