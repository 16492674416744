import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';

function Education({ onClose, setData }) {
    const [submitting, setSubmitting] = useState(false);
    const [myEducation, setMyEducation] = useState(
        {
            country: '',
            school: '',
            field_of_study: '',
            degree: '',
            start_date: '',
            end_date: '',
            still_studying: false,
        }
    );


    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
        apiInstance.post('profile/education/', myEducation)
            .then((res) => {
                console.log(res.data);
                setData((prevData) => [...prevData, res.data]);
                notifications.show({
                    title: 'Education Created',
                    message: 'Successfully Created Education',
                    color: 'green',
                    autoClose: true,
                })
                onClose();
            })
            .catch((err) => {
                console.log(err.response);
                notifications.show({
                    title: 'Error Add Education',
                    message: err.response.data.error,
                    color: 'red',
                    autoClose: true,
                })
            });
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setMyEducation({
            ...myEducation,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white rounded-md">
            <h2 className="text-2xl font-semibold mb-1">Add Education</h2>
            <form onSubmit={handleSubmit}>
                <div className='mb-1'>
                    <label htmlFor="price" className="block text-gray-600">Country:</label>
                    <input type="text" id="country" name="country" value={myEducation.country} onChange={handleChange} className="w-full p-2 border rounded-md" />
                </div>
                <div className="mb-1">
                    <label htmlFor="title" className="block text-gray-600">School:</label>
                    <input
                        type="text"
                        id="school"
                        name="school"
                        value={myEducation.school}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                    />
                </div>
                <div className='mb-1'>
                    <label htmlFor="price" className="block text-gray-600">Field of Study:</label>
                    <input type="text" id="field_of_study" name="field_of_study" value={myEducation.field_of_study} onChange={handleChange} className="w-full p-2 border rounded-md" />
                </div>
                <div className='mb-1'>
                    <label htmlFor="price" className="block text-gray-600">Degree:</label>
                    <input type="text" id="degree" name="degree" value={myEducation.degree} onChange={handleChange} className="w-full p-2 border rounded-md" />
                </div>
                <div className="mb-1">
                    <label htmlFor="title" className="block text-gray-600">Start Date:</label>
                    <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={myEducation.start_date}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                    />
                </div>
                <div className="mb-1">
                    <input type="checkbox"
                        value={myEducation.still_studying}
                        name="still_studying"
                        onChange={
                            (e) => {
                                setMyEducation({
                                    ...myEducation,
                                    still_studying: e.target.checked,
                                })
                            }
                        }
                        id="myCheckbox" className="rounded-lg p-2 text-indigo-500 bg-white border-2 border-indigo-400 hover:bg-indigo-100" />
                    <span className="ml-2 text-sm text-gray-600">Still Studying</span>
                </div>
                <div className="mb-1">
                    <label htmlFor="title" className="block text-gray-600">End Date:</label>
                    <input
                        type="date"
                        id="end_date"
                        disabled={myEducation.still_studying}
                        name="end_date"
                        value={myEducation.end_date}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                    />
                </div>
                <div className="mt-4">
                    <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                        Add Education
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Education