import React from 'react'
import ContactUsBtn from './ContactUsBtn'
import { Link } from 'react-router-dom';


function FirstTextCard() {
  return (
    <div className="bg-blue-100">
      <div className="mx-auto  sm:px-6 sm:py-10 ">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-4 max-w-md lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
              A revolutionary platform that connects learners with native language tutors for one-on-one lessons
              <br />

            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Get a personalized choice of tutors by answering a few quick questions
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <Link
                to='/tutoring'
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Tell Us What you want
              </Link>
              
              <Link
                to='/home'
                className="text-sm font-semibold leading-6 text-white"
              >
                See All tutor <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src="https://scontent-lhr8-1.xx.fbcdn.net/v/t39.30808-6/402485784_2500160946832709_2376069847489730322_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeFQA1qUwOzTifOhZ8uhCYONVLQx-dnR5aJUtDH52dHlogPVmgV6PAmg-lV-inULJkszujfh9MI78Upy6i-4c965&_nc_ohc=6lCqrytY9G0AX-kBpNe&_nc_ht=scontent-lhr8-1.xx&oh=00_AfB2rAVvyhwrEWc5MMZuc8y4y9EHwuvRyEGr4sstMjiGhg&oe=6563489D"
              alt="App screenshot"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstTextCard