import React, { useState, useEffect } from "react";
import { IconGenderFemale, IconGenderMale, IconHeart } from "@tabler/icons";
import { Badge, createStyles, rem } from "@mantine/core";
import StarRating from "react-star-ratings";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import {
    MapPinIcon,
    BookOpenIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { IMG_URL } from "../config/api";
import FavouriteButton from "./partials/FavouriteButton";
import Rating from "./partials/Rating";
import TutorLessonCount from "./partials/TutorLessonCount";


const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    },

    section: {
        borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
            }`,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        paddingBottom: theme.spacing.md,
    },

    label: {
        textTransform: "uppercase",
        fontSize: theme.fontSizes.xs,
        fontWeight: 700,
    },
}));

function calculateAge(dob) {
    const birthDate = dayjs(dob);
    const currentDate = dayjs();

    const age = currentDate.diff(birthDate, "year");
    return age + " years old";
}

export function ProfileCard({ profile }) {
    const { classes, theme } = useStyles();
    const navigate = useNavigate();
    const [isFavorite, setIsFavorite] = useState(profile?.isFavourite);
    // Replace with the actual user ID you want to add to favorites

    const toggleFavorite = () => {
        setIsFavorite((prevIsFavorite) => !prevIsFavorite);
    };


    let currencyFormater = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'XAF',
    })


    const subjects = profile?.user_data?.subject?.map((subject) => (
        <Badge
            color={theme.colorScheme === "dark" ? "dark" : "gray"}
            key={subject.id}
        >
            {subject?.subject?.title}
        </Badge>
    ));

    const days = profile?.user_data?.availability?.map((day) => (
        <Badge color={theme.colorScheme === "dark" ? "dark" : "gray"} key={day.id}>
            {day?.day}
        </Badge>
    ));

    const navigateToProfile = (id, name) => navigate(`userprofile/${id}/${name}`);

    return (
        <div className="container shadow-lg rounded-lg mb-4">
            <div className="w-full lg:flex">
                <div className="w-full lg:w-1/4 flex-none rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden mt-3">
                    <img
                        className="rounded-full mr-auto ml-auto"
                        src={`${IMG_URL}${profile?.user_data?.profilepersonal[0]?.profile_pic}`}
                        alt="name"
                        title="name ios"
                    />
                </div>
                <div className="w-full lg:w-3/4 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                    <div>
                        <div className="flex justify-between">
                            <div className="text-gray-900 flex font-bold text-xl mb-2">
                                {profile?.user_data?.profilepersonal[0]?.first_name} {". "}
                                {profile?.user_data?.profilepersonal[0]?.last_name?.charAt(0)}
                                <CheckBadgeIcon
                                    className="mx-2 mt-1 h-5 w-5 flex-shrink-0 text-blue-400"
                                    title="Verified"
                                />
                            </div>
                            <FavouriteButton
                                likedUserId={profile?.user_id}
                                isFavorite={isFavorite}
                                onToggleFavorite={toggleFavorite}
                            />
                        </div>

                        <p className="text-sm font-semibold text-gray-600 flex items-center py-1">
                            <BookOpenIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-black-400"
                                aria-hidden="true"
                            />
                            {profile?.user_data?.subject[0]?.category?.name}
                            {", "}
                            {profile?.user_data?.subject[0]?.subcategory?.name}
                            {", "}
                            {profile?.user_data?.subject[0]?.subject.title}
                        </p>
                        <TutorLessonCount
                            numberOfLessons={profile?.num_lesson}
                            numberOfStudents={profile?.num_student}
                        />
                        <div className="text-gray-900 font-semibold text-sm mb-2">
                            <p className="text-gray-600 flex py-1">
                                <MapPinIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-black-400"
                                    aria-hidden="true"
                                />
                                {profile?.user_data?.profilepersonal[0]?.region?.title}
                                {", "}
                                {profile?.user_data?.profilepersonal[0]?.town?.title}
                                {", "}
                                {profile?.user_data?.profilepersonal[0]?.address}
                            </p>
                        </div>
                        <p className="text-gray-700 text-base">
                            {profile?.user_data?.profilesummary[0]?.profile_summary.slice(
                                0,
                                120
                            )}
                            ...
                        </p>
                        <div>
                            <p className="text-gray-700 font-semibold text-base">Subjects</p>
                            {subjects}
                            <p className="text-gray-700 font-semibold text-base">
                                Available Days
                            </p>
                            {days}
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/2 bg-white rounded-b lg:rounded-b-none lg:rounded-r px-4 md:pt-4 flex flex-col justify-between leading-normal">
                    <div>
                        <Rating
                            rating={profile?.avg_rating}
                            numberOfReviews={profile?.num_reviews}
                        />
                        <div className="text-gray-900 font-bold text-lg mb-2">
                            <p className="text-gray-600 mb-2">
                                {profile?.user_data?.paymentrate[0]
                                    ? `${currencyFormater.format(profile?.user_data?.paymentrate[0]?.amount)}/${profile?.user_data?.paymentrate[0]?.charge} for ${profile?.user_data?.paymentrate[0]?.mode} lessons`
                                    : "No Rate"}
                            </p>
                        </div>
                        <div className="text-gray-900 font-semibold text-sm mb-2">
                            <p className="text-gray-600 mb-2 flex">
                                Gender: {profile?.user_data?.profilepersonal[0]?.gender}
                                {profile?.user_data?.profilepersonal[0]?.gender?.toLowerCase() ===
                                    "male" ? (
                                    <IconGenderMale />
                                ) : (
                                    <IconGenderFemale />
                                )}
                            </p>
                        </div>
                        <div className="text-gray-900 font-semibold text-sm mb-2">
                            <p className="text-gray-600 mb-2 flex">
                                Age:{" "}
                                {calculateAge(
                                    `${profile?.user_data?.profilepersonal[0]?.date_of_birth}`
                                )}
                            </p>
                        </div>
                        <button
                            onClick={() =>
                                navigateToProfile(
                                    profile?.user_id,
                                    `${profile?.user_data?.profilepersonal[0]?.first_name}`
                                )
                            }
                            className="w-full my-2 rounded-md bg-indigo-400 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            See Complete profile
                        </button>
                        <button className="w-full my-2 rounded-md bg-indigo-400 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                            Send a Message
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
