import React, {useEffect, useState} from 'react';
import useApiData from '../../hooks/useApiData'
import About from '../../components/tutorprofile/About'
import BasicInfo from '../../components/tutorprofile/BasicInfo'
import Reviews from '../../components/tutorprofile/Reviews'
import Resume from '../../components/tutorprofile/Resume'
import Schedule from '../../components/tutorprofile/Schedule'
import Subjects from '../../components/tutorprofile/Subjects'
import CTACard from '../../components/tutorprofile/CTACard'
import { useParams } from 'react-router-dom';
import { API_URL } from '../../config/api';
import axios from 'axios';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UserProfile() {
    const { id } = useParams();
    const [userProfile, setUserProfile] = React.useState(null);
    const [userProfileLoading, setUserProfileLoading] = React.useState(true);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_URL + `profile/userprofile/${id}/`)
                const result = response.data.results || response.data;
                setUserProfile(result);
                setUserProfileLoading(false);
            } catch (error) {
                console.error('API request error:', error);
                setUserProfileLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const breadcrumbs = [
        { id: 1, name: 'Home', href: '#' },
        { id: 2, name: 'Tutor', href: '#' },
        { id: 3, name: 'Profile', href: '#' },
    ]
    return (
        <div>
            <div className="pt-3">
                <nav aria-label="Breadcrumb">
                    <ol Show className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        {breadcrumbs?.map((breadcrumb) => (
                            <li key={breadcrumb.id}>
                                <div className="flex items-center">
                                    <a href={breadcrumb?.href} className="mr-2 text-sm font-medium text-gray-900">
                                        {breadcrumb?.name}
                                    </a>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        className="h-5 w-4 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <li className="text-sm">
                            <a href={userProfile?.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                {userProfile?.name} {userProfile?.profilepersonal?.first_name}
                            </a>
                        </li>
                    </ol>
                </nav>

                {
                    userProfileLoading ? (
                        <div className="flex justify-center items-center my-auto">
                            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                        </div>
                    ) : (
                        <>
                            <BasicInfo profile={userProfile} />
                            {/* Product info */}
                            <div className="mx-auto max-w-2xl px-1 pb-16 pt-2 sm:px-1 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                                <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                                    <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{userProfile?.name}</h1>
                                </div>
                                <CTACard profile={userProfile} />
                                <Subjects subjects={userProfile?.subject} />
                                {/* <About profile={userProfile?.profilesummary} /> */}
                                <Resume education={userProfile?.education}
                                    workHistory={userProfile?.workhistory}
                                    certifications={userProfile?.certification}
                                />
                                <Schedule availability={userProfile?.availability} />

                                <Reviews profile={userProfile?.profileviewed} />

                            </div>
                        </>
                    )
                }



            </div>
        </div>
    )
}
