import React, { useState, useEffect } from "react";
import {
    Container,
    Modal,
} from "@mantine/core";

import { apiInstance } from '../../config/api';
import ProfileBack from "../../components/ProfileBack";
import Rate from "../../components/userprofile/create/Rate";
import AddButton from "../../components/partials/AddButton";
import RateTable from "../../components/userprofile/datatable/RateTable";
import { useDisclosure } from "@mantine/hooks";

function RateAdd() {
    const [opened, { open, close }] = useDisclosure(false);
    const [myRate, setMyRate] = useState([]);

    useEffect(() => {
        apiInstance.get("profile/rate/?me=true")
            .then((res) => {
                console.log(res);
                const rateList = res.data.results;
                setMyRate(rateList);
            }
            )
            .catch((err) => {
                console.log(err);
            });
    }, []);


    return (
        <div>
            <ProfileBack />
            <AddButton openModal={open} text="Add New Rate" />
            <Modal opened={opened} onClose={close}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}>
                <Rate onClose={close}
                    setData={setMyRate}
                />
            </Modal>
            <RateTable data={myRate} setData={setMyRate} />
        </div>
    );
}

export default RateAdd;
