import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL, apiInstance } from '../../../config/api';
import { useAuth } from '../../../context/auth-context';
import {
    Container,
    Text,
    Title,
    Center,
    Grid,
    Button,
    Group,
    NumberInput,
    ActionIcon,
    rem,
    Select,
    Radio,
    Paper,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { IconPlus, IconMinus, IconChevronDown } from '@tabler/icons';


function KidLearner() {
    const navigate = useNavigate()
    const { user } = useAuth()
    const [LANGUAGE, setLANGUAGE] = useState([])
    const [LEVEL, setLEVEL] = useState([])
    const [CLASS, setCLASS] = useState([])
    const [ind, setInd] = useState(0)
    const [mode, setMode] = useState([])

    const [childClass, setChildClass] = useState([
        {
            child_class: "",
            child_language: "",
            child_level: "",
            child_goal: "",
            child_subject: "",
            child_more: "",
        },
    ])


    const [ChildCount, setChildCount] = useState(1);
    const [tutoringType, setTutoringType] = useState('');

    function handleAddField() {
        const values = [...childClass];
        values.push({
            child_class: "",
            child_language: "",
            child_level: "",
            child_goal: "",
            child_subject: "",
            child_more: "",
        });
        setChildClass(values);
        setChildCount(ChildCount + 1);
    }


    const handleRemoveField = () => {
        const values = [...childClass];
        values.splice(ChildCount - 1, 1);
        setChildClass(values);
        setChildCount(ChildCount - 1);
    };


    const handleInputChange = (event, fieldIndex, fieldName) => {
        const tmpFields = [...childClass];
        const value = event || event.target.value;
        tmpFields[fieldIndex][fieldName] = value;
        setChildClass(tmpFields);
    };

    const handleNextClick = () => {
        apiInstance.post("tutoringprofile/learner-profile/", {
            user : user?.user_id,
            kid_number : ChildCount,
            tutoring_type : tutoringType,
        }
        ).then((res) => {
            console.log(res);
            navigate('/tutoring/learner-need', {
                state: {
                    childClass,
                    ChildCount,
                    tutoringType,
                    profileID : res.data.id
                },
            });
        }).catch((err) => {
            console.log(err);
        })
    }

            

    useEffect(() => {
        apiInstance.get("misc/language/")
            .then((res) => {
                console.log(res);
                const languageList = res.data.results;
                setLANGUAGE(languageList);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        apiInstance.get(`misc/level/?language=${childClass[ind].child_language}`)
            .then((res) => {
                console.log(res);
                const levelList = res.data.results;
                setLEVEL(levelList);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [childClass[ind]?.child_language]);

    useEffect(() => {
        apiInstance.get(`misc/classes/?level=${childClass[ind].child_level}&language=${childClass[ind].child_language}`)
            .then((res) => {
                console.log(res);
                const classList = res.data.results;
                setCLASS(classList);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [childClass[ind].child_level]);

    useEffect(() => {
        apiInstance.get(`misc/mode/`)
            .then((res) => {
                console.log(res);   
                const modeList = res.data.results;
                setMode(modeList)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const fixedData = (data) => {
        return data.map((item) => ({
            value: item.id,
            label: item.name || item.title,
        }));
    }


    

    return (
        <Container>
            <Center>
                <Text size={30} fw={700} fs="italic">
                    Let us Find the best tutor for your Kid!!
                </Text>
            </Center>
            <Text size={25} fw={600} fs="italic">
                Tell Us about your child{ChildCount > 1 && 'ren'}
            </Text>
            <Grid my={20}>
                <Title order={4} mt={10}>
                    How Many Children needs lessons?
                </Title>
                <Group position="center" ml={rem(54)}>
                    <ActionIcon
                        color="cyan" size="xl" radius="lg" variant="filled"
                        disabled={ChildCount === 1}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={handleRemoveField}
                    >
                        <IconMinus size="1rem" stroke={1.5} />
                    </ActionIcon>
                    <NumberInput
                        variant='unstyled'
                        radius="xl"
                        size="lg"
                        value={ChildCount}
                        onChange={setChildCount}
                        styles={{ input: { width: rem(54), textAlign: 'center' } }}
                    />
                    <ActionIcon
                        color="cyan" size="xl" radius="lg" variant="filled"
                        onClick={handleAddField}
                        onMouseDown={(event) => event.preventDefault()}
                    >
                        <IconPlus size="1rem" stroke={1.5} />
                    </ActionIcon>
                </Group>
            </Grid>
            <Grid my={20}>
                {childClass.map((field, index) => {
                    return (
                        <Grid.Col key={index} md={6} sm={12} mt={10}>
                            <Paper shadow="xl" p="xl" withBorder>
                                <Text size={20} m mb={10} fw={700} fs="italic">
                                    Child {index + 1}
                                </Text>
                                <Select
                                    label="Child Primary Language"
                                    placeholder="Select Language"
                                    defaultValue={field.child_language}
                                    onChange={(event) =>
                                        {handleInputChange(event, index, "child_language")
                                        setInd(index)
                                    }
                                    }
                                    mx="auto"
                                    size="lg"
                                    rightSection={<IconChevronDown size="1rem" />}
                                    rightSectionWidth={40}
                                    data={fixedData(LANGUAGE)}

                                />
                                <Select
                                    label="Child Level"
                                    placeholder="Select Level"
                                    defaultValue={field.child_level}
                                    onChange={(event) =>
                                        handleInputChange(event, index, "child_level")
                                    }
                                    mx="auto"
                                    size="lg"
                                    rightSection={<IconChevronDown size="1rem" />}
                                    rightSectionWidth={40}
                                    data={
                                        fixedData(LEVEL)
                                    }
                                />
                                <Select
                                    label="Child Class"
                                    placeholder="Select Class"
                                    defaultValue={field.child_class}
                                    onChange={(event) =>
                                        handleInputChange(event, index, "child_class")
                                    }
                                    mx="auto"
                                    size="lg"
                                    rightSection={<IconChevronDown size="1rem" />}
                                    rightSectionWidth={40}
                                    data={
                                        fixedData(CLASS)
                                    }
                                />
                            </Paper>
                        </Grid.Col>
                    );
                })}
            </Grid>
            <Radio.Group
                name="tutoring-type"
                label="Do you prefer in-person or online lessons?"
                size="lg"
                value={tutoringType}
                onChange={setTutoringType}
            >
                <Group mt="xs">
                    {
                        mode?.map((item) => (
                            <Radio value={`${item.title}`} label={item.title} size="lg" />
                        ))
                    }
                </Group>
                
            </Radio.Group>
            <Button mt={20}
                variant="default"
                radius="lg"
                size="lg"
                uppercase
                onClick={handleNextClick}
            >
                Next
            </Button>
        </Container >
    )
}

export default KidLearner