import React, { useState, useEffect } from "react";
import { apiInstance } from '../../../config/api'
import DataTable from 'react-data-table-component';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons';
import Modals from '../../partials/Modals';
import { useDisclosure } from '@mantine/hooks';
import EditSchedule from '../edit/EditSchedule';
import dayjs from 'dayjs';
import { notifications } from '@mantine/notifications';
import { Modal } from '@mantine/core';

const customStyles = {
  rows: {
    style: {
      minWidth: 'auto',
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {

      fontSize: '1.1rem',
      fontWeight: 'semi-bold',

    },
  },
};

function ScheduleTable({ data, setData }) {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [opened, { open, close }] = useDisclosure(false);


  const openModal = (item) => {
    setSelectedItem(item);
    setDeleteModal(true);
  }

  const openEditModal = (item) => {
    setSelectedItem(item);
    open();
  }


  const onDelete = (id) => {
    apiInstance.delete(`profile/schedule/${id}/`)
      .then((res) => {
        console.log(res);
        setData((prevData) => prevData.filter((item) => item.id !== id));
        notifications.show({
          title: 'Item Deleted',
          message: 'Successfully Deleted Item',
          color: 'green',
          autoClose: true,
        })
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Deleting Item',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }
  const columns = [
    {
      name: 'Day',
      selector: row => row.day,
      sortable: true,
      width: 'auto'
    },
    {
      name: 'From',
      selector: row => row.start_time,
      sortable: true,
    },
    {
      name: 'To',
      selector: row => row.end_time,
      sortable: true,
    },

    {
      name: 'Actions',
      cell: (row) => (<div className="flex items-center gap-x-6">
        <button
          onClick={() => openEditModal(row)}
          className="text-black-500 transition-colors duration-200 dark:hover:text-indigo-500  hover:text-indigo-500 focus:outline-none">
          <IconEdit />
        </button>
        <button
          onClick={() => openModal(row)}
          className="text-red-500 transition-colors duration-200 hover:text-indigo-500 focus:outline-none">
          <IconTrash />
        </button>

      </div>),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

  ]
  const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
  return (
    <section className='my-4 mx-3'>
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}>
        <EditSchedule onClose={close} setData={setData} editItem={selectedItem} />
      </Modal>

      <Modals open={deleteModal} setOpen={setDeleteModal}
        title={"Delete Item"}
        content={"Are you sure you want to delete this item?"}
        action_title={"Delete"}
        action={() => {
          onDelete(selectedItem?.id);
          setDeleteModal(false);
          setSelectedItem(null);
        }}
      />
      <div className="px-2">
        <DataTable
          direction="auto"
          responsive
          highlightOnHover
          pointerOnHover
          columns={columns}
          data={data}
          striped
          pagination
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          persistTableHead
          paginationServer
          paginationPerPage={10}
          progressPending={data.length ? false : true}
          customStyles={customStyles}
        />
      </div>


    </section>
  )
}

export default ScheduleTable