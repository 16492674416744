import React from 'react';
import { Link } from 'react-router-dom';

const ClassDashboard = () => {
    return (
        <div className="bg-gray-100 p-6">
            <div className="max-w-3xl mx-auto">
                <h2 className="text-2xl font-semibold mb-4">Class Dashboard</h2>

                {/* Class Overview */}
                <div className="bg-white rounded-md p-4 mb-4 shadow-md">
                    <h3 className="text-lg font-semibold mb-2">Class Overview</h3>
                    <p>Description of the class and other details go here.</p>
                </div>

                {/* Class Statistics */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-white rounded-md p-4 shadow-md">
                        <h3 className="text-lg font-semibold mb-2">Students Enrolled</h3>
                        <p>Number of students currently enrolled in the class.</p>
                    </div>
                    <div className="bg-white rounded-md p-4 shadow-md">
                        <h3 className="text-lg font-semibold mb-2">Upcoming Assignments</h3>
                        <ul>
                            <li>Assignment 1 - Due on November 15</li>
                            <li>Assignment 2 - Due on November 20</li>
                            {/* Add more assignments */}
                        </ul>
                    </div>
                </div>

                {/* Class Resources */}
                <div className="bg-white rounded-md p-4 mt-4 shadow-md">
                    <h3 className="text-lg font-semibold mb-2">Class Resources</h3>
                    <ul>
                        <li><a href="/resource1.pdf" className="text-blue-500 hover:underline">Resource 1</a></li>
                        <li><a href="/resource2.pdf" className="text-blue-500 hover:underline">Resource 2</a></li>
                        {/* Add more resources */}
                    </ul>
                </div>

                {/* Class Discussion Forum */}
                <div className="bg-white rounded-md p-4 mt-4 shadow-md">
                    <h3 className="text-lg font-semibold mb-2">Class Discussion Forum</h3>
                    {/* Add a discussion forum component here */}
                </div>
                {/* Class Management */}
                <div className="bg-white rounded-md p-4 shadow-md mb-4 mt-4">
                    <h3 className="text-lg font-semibold mb-2">Class Management</h3>
                    <Link to="/class/lessons" className="text-blue-500 hover:underline block mb-2">Manage Lessons</Link>
                    <Link to="/class/assignments" className="text-blue-500 hover:underline block mb-2">Manage Assignments</Link>
                    <Link to="/class/students" className="text-blue-500 hover:underline block">Manage Students</Link>
                </div>
            </div>
        </div>
    );
};

export default ClassDashboard;
