import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import AddButton from '../components/partials/AddButton';
import CreatClassRoom from '../components/classroom/CreatClassRoom';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { apiInstance } from '../config/api';
import ClassRoomTable from '../components/classroom/ClassRoomTable';


function ClassRoom() {
  const [opened, { open, close }] = useDisclosure(false);
  const [classRooms, setClassRooms] = useState([])

  useEffect(() => {
    apiInstance.get('tutorclass/tutorclass/?me=true')
      .then((res) => {
        console.log(res);
        setClassRooms(res.data.results);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
    , []);

  return (
    <div>
      <AddButton openModal={open} text="Create New ClassRoom" />
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title="Create a New Classroom">
        <CreatClassRoom onClose={close} setData={setClassRooms} />
      </Modal>

      

      {
        classRooms.length ? (

          <h3>
            <ClassRoomTable data={classRooms} setData={setClassRooms} />
          </h3>
        ) : (
          <div className="h-screen flex flex-col items-center mt-12 ">
            <img
              src="https://static.preply.com/space/images/no-tutorings-state.dafce7c556bcf76bcca0f9adc27a720c.png" // Replace with the path to your Disney-themed logo or image
              alt="Tantorial"
              className="w-64 mb-8"
            />
            <h4 className="text-2xl font-bold mb-2">Your Classes will appear here</h4>
            <p className="text-lg mb-4">Find students and teach lessons to get started</p>
            <Link to="/" className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-4 rounded-full hover:shadow-md transition duration-300 ease-in-out">
              Add Students to your class
            </Link>
          </div>
        )
      }

    </div>

  )
}

export default ClassRoom