import React from 'react'
import { Route, Routes } from "react-router-dom";
import MainLayout from '../layout/home/MainLayout'
import HomePage from '../pages/main/HomePage';
import Welcome from '../layout/home/Welcome';
import PageNotFound from '../pages/error/PageNotFound';
import UserProfile from '../pages/main/UserProfile';

import Students from '../pages/filterpage/Students';
import TutorSearch from '../pages/filterpage/TutorSearch';
import Category from '../pages/filterpage/Category';
import SearchResult from '../pages/filterpage/SearchResult';
import AllTutors from '../pages/filterpage/AllTutors';
import LandingPage from '../pages/LandingPage';

function MainRoute() {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route index element={<LandingPage />} />
                <Route path="home" element={<Welcome />} />
                <Route path="userprofile/:id/:name" element={<UserProfile />} />
                <Route path="category" element={<Category />} />
                <Route path="all-students" element={<Students />} />
                <Route path="tutor-search" element={<TutorSearch />} />
                <Route path="search" element={<SearchResult />} />
                <Route path="tutors" element={<AllTutors />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>

    )
}

export default MainRoute