import React from 'react'
import useApiData from '../../hooks/useApiData'
import { ProfileCard } from '../../components/ProfileCard'

function SearchResult() {
  const { data: userProfiles, loading: userProfilesLoading } = useApiData(`profile/userprofile`)

  return (
    <div>
      {
        userProfilesLoading ? (
          <div className="flex justify-center items-center my-auto">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (userProfiles?.map((profile, index) => (
          <ProfileCard profile={profile} key={index} />
        )))
      }
 
    </div>
  )
}

export default SearchResult