import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../config/api'
import { notifications } from '@mantine/notifications';
import Select from 'react-select';

function CreatClassRoom({ onClose, setData, editClass }) {
    const [classroomData, setClassroomData] = useState({});

    const [paymentPlan, setPaymentPlan] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setClassroomData({
            ...classroomData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    useEffect(() => {
        apiInstance.get('misc/payment-plan/')
            .then((res) => {
                console.log(res);
                setPaymentPlan(res.data.results);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    useEffect(() => {
        apiInstance.get('tutorclass/tutorclass/' + editClass?.id + '/')
            .then((res) => {
                console.log(res);
                setClassroomData(res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, [editClass]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { tutor, ...newClassroomData } = classroomData;
        apiInstance.patch('tutorclass/tutorclass/' + editClass?.id + '/', newClassroomData)
            .then((res) => {
                console.log(res.data);
                setData((prevData) => prevData.map((item) => item.id === res.data.id ? res.data : item));
                notifications.show({
                    title: 'Classroom Updated',
                    message: 'Successfully Updated Classroom',
                    color: 'green',
                    autoClose: true,
                })
                onClose();
            })
            .catch((err) => {
                console.log(err.response);

                notifications.show({
                    title: 'Error Updating Classroom',
                    message: err.response.data.errors,
                    color: 'red',
                    autoClose: true,
                })
            });
    }



    const fixedData = (data) => {
        return data.map((item) => ({
            value: item.id,
            label: item.title,
        }));
    }



    return (
        <div className="max-w-md mx-auto p-6 bg-white rounded-md">
            <h2 className="text-2xl font-semibold mb-1">Edit Classroom</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-1">
                    <label htmlFor="title" className="block text-gray-600">Class name:</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={classroomData.title}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                    />
                </div>
                <div className="mb-1">
                    <label htmlFor="description" className="block text-gray-600">Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={classroomData.description}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        required
                    />
                </div>
                <div className="mb-1">
                    <label htmlFor="duration" className="block text-gray-600">Duration:</label>
                    <div className="flex">
                        <input
                            type="number"
                            id="duration"
                            name="duration"
                            value={classroomData.duration}
                            onChange={handleChange}
                            className="w-1/2 p-2 border rounded-l-md"
                            required
                        />
                        <Select
                            name="Duration Unit"
                            options={[
                                { value: 'years', label: 'Years' },
                                { value: 'months', label: 'Months' },
                                { value: 'weeks', label: 'Weeks' },
                                { value: 'days', label: 'Days' },
                            ]}
                            placeholder="Duration Unit"
                            className=" border border-l-0 rounded-r-md"
                            defaultInputValue={classroomData.duration_unit}
                            onChange={(e) => {
                                setClassroomData({
                                    ...classroomData,
                                    duration_unit: e.value,
                                })
                            }
                            }

                        />
                    </div>
                </div>
                <div className="mb-1">
                    <label htmlFor="free" className="block text-gray-600">
                        <input
                            type="checkbox"
                            id="free"
                            name="free"
                            checked={classroomData.free}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Free
                    </label>
                </div>
                {
                    classroomData.free === false && (
                        <>
                            <div className="mb-1">
                                <label htmlFor="price" className="block text-gray-600">Price:</label>
                                <input
                                    type="number"
                                    id="price"
                                    name="price"
                                    value={classroomData.price}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded-md"
                                />
                            </div>
                            <div className='mb-1'>
                                <label htmlFor="price" className="block text-gray-600">Payment Plan:</label>
                                <Select
                                    name="Payment Plan  "
                                    options={
                                        fixedData(paymentPlan)
                                    }
                                    isSearchable
                                    placeholder="Payment Plan"
                                    defaultInputValue={classroomData.payment_plan?.title}
                                    onChange={(e) => {
                                        setClassroomData({
                                            ...classroomData,
                                            payment_plan: e.value,
                                        })
                                    }
                                    }

                                />
                            </div>
                        </>
                    )
                }

                <div className="mb-1">
                    <label htmlFor="free" className="block text-gray-600">
                        <input
                            type="checkbox"
                            id="trial"
                            name="trial"
                            checked={classroomData.trial}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        Trial
                    </label>
                </div>
                {
                    classroomData.trial === true && (
                        <div className="mb-1">
                            <label htmlFor="trial" className="block text-gray-600">
                                Trial Duration:
                            </label>
                            <input
                                type="number"
                                id="end_of_trial"
                                name="end_of_trial"
                                value={classroomData.end_of_trial}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-md"

                            />
                        </div>
                    )
                }
                <div className="mt-4">
                    <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                        Edit Classroom
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreatClassRoom