import React from 'react'
import { Link } from 'react-router-dom'

const TEST = [
    {
        "id": 1,
        "name": "John D",
        "image": "https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/402650366_2496780370504100_1397804746372959801_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH_QvkQZfmDec9R2X5EOr5-nig2kvU3DeyeKDaS9TcN7Knv6PL31OARQj5N6Im_AbEM6HNPNT3FFcCIHIuNhqZl&_nc_ohc=NW7F7ViGUkEAX9N5APe&_nc_ht=scontent-lhr6-2.xx&oh=00_AfCfleS4VWudwXr6hYkb28A52OjIIh7DqTYuSJPp08jveA&oe=65630DB3",
        "testimonial": "Tantorial has been a game-changer for my learning experience. The personalized recommendations and real-time tutor matching have helped me find the perfect tutors to fit my needs. The gamified learning experience makes it fun and engaging to learn, and the comprehensive progress tracking helps me stay on top of my goals. I highly recommend Tantorial to anyone looking for a great online tutoring platform."
    },
    {
        "id": 2,
        "name": "J Smith",
        "image": "https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/402650366_2496780370504100_1397804746372959801_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH_QvkQZfmDec9R2X5EOr5-nig2kvU3DeyeKDaS9TcN7Knv6PL31OARQj5N6Im_AbEM6HNPNT3FFcCIHIuNhqZl&_nc_ohc=NW7F7ViGUkEAX9N5APe&_nc_ht=scontent-lhr6-2.xx&oh=00_AfCfleS4VWudwXr6hYkb28A52OjIIh7DqTYuSJPp08jveA&oe=65630DB3",
        "testimonial": "I was skeptical about online tutoring at first, but Tantorial has proven me wrong. The tutors are experienced and knowledgeable, and they are always willing to go the extra mile to help me succeed. The platform is easy to use and the scheduling is flexible, which makes it easy to fit tutoring sessions into my busy schedule. I am so glad I decided to try Tantorial!"
    },
    {
        "id": 3,
        "name": "Desmond T",
        "image": "https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/402650366_2496780370504100_1397804746372959801_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH_QvkQZfmDec9R2X5EOr5-nig2kvU3DeyeKDaS9TcN7Knv6PL31OARQj5N6Im_AbEM6HNPNT3FFcCIHIuNhqZl&_nc_ohc=NW7F7ViGUkEAX9N5APe&_nc_ht=scontent-lhr6-2.xx&oh=00_AfCfleS4VWudwXr6hYkb28A52OjIIh7DqTYuSJPp08jveA&oe=65630DB3",
        "testimonial": "Tantorial is the best online tutoring platform I have ever used. The personalized learning approach ensures that I am getting the most out of my tutoring sessions, and the gamified elements make it fun and engaging to learn. I have already made significant progress in my studies since I started using Tantorial, and I am confident that I will continue to achieve my goals."
    }
]


function Testimonial() {
    return (
        <div className='z-0 isolate'>
            <section class="overflow-hidden relative min-h-screen grid grid-cols-1 lg:grid-cols-12 place-content-center lg:place-items-center lg:gap-16 max-w-7xl mx-auto px-6 py-10">
                <div class="relative z-10 mb-10 lg:mb-0 col-span-4">
                    <h1 class="relative z-10 sm:text-5xl text-2xl 2xl:text-6xl font-bold sm:leading-snug 2xl:leading-tight">Bringing value <br />across different learners.</h1>
                    <p class="mt-4 mb-7 text-gray-500 max-w-sm 2xl:text-lg 2xl:mt-4 2xl:mb-8">Over 10K lessons booked on <a href="/" class="text-red-400 hover:underline">Tantorial</a> and over FCFA 20M paid out to tutors.</p>
                    <Link to='/home' class="inline-block  my-2 rounded-full bg-red-50 py-2.5 px-10 font-bold text-red-500 hover:shadow-lg 2xl:py-3 2xl:px-12">Find a tutor today ? </Link>
                    <Link to='/auth/register/?tutor=true' class="inline-block my-2 rounded-full bg-indigo-50 py-2.5 px-10 font-bold text-blue-500 hover:shadow-lg 2xl:py-3 2xl:px-12">Join Tantorial as a tutor? </Link>
                </div>
                <div class="relative z-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-1 gap-5 col-span-8">
                    {
                        TEST?.map((item, index) => (
                            <div class="relative z-10 bg-white shadow-[0_5px_30px_-15px_rgba(0,0,0,0.3)] rounded-sm flex flex-col lg:items-center justify-between lg:flex-row gap-10 p-7">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.082 475.081" x="0px" y="0px" width="25px" height="25px" >
                                        <g>
                                            <g>
                                                <path d="M 164.45 219.27 h -63.954 c -7.614 0 -14.087 -2.664 -19.417 -7.994 c -5.327 -5.33 -7.994 -11.801 -7.994 -19.417 v -9.132 c 0 -20.177 7.139 -37.401 21.416 -51.678 c 14.276 -14.272 31.503 -21.411 51.678 -21.411 h 18.271 c 4.948 0 9.229 -1.809 12.847 -5.424 c 3.616 -3.617 5.424 -7.898 5.424 -12.847 V 54.819 c 0 -4.948 -1.809 -9.233 -5.424 -12.85 c -3.617 -3.612 -7.898 -5.424 -12.847 -5.424 h -18.271 c -19.797 0 -38.684 3.858 -56.673 11.563 c -17.987 7.71 -33.545 18.132 -46.68 31.267 c -13.134 13.129 -23.553 28.688 -31.262 46.677 C 3.855 144.039 0 162.931 0 182.726 v 200.991 c 0 15.235 5.327 28.171 15.986 38.834 c 10.66 10.657 23.606 15.985 38.832 15.985 h 109.639 c 15.225 0 28.167 -5.328 38.828 -15.985 c 10.657 -10.663 15.987 -23.599 15.987 -38.834 V 274.088 c 0 -15.232 -5.33 -28.168 -15.994 -38.832 C 192.622 224.6 179.675 219.27 164.45 219.27 Z" />
                                                <path d="M 459.103 235.256 c -10.656 -10.656 -23.599 -15.986 -38.828 -15.986 h -63.953 c -7.61 0 -14.089 -2.664 -19.41 -7.994 c -5.332 -5.33 -7.994 -11.801 -7.994 -19.417 v -9.132 c 0 -20.177 7.139 -37.401 21.409 -51.678 c 14.271 -14.272 31.497 -21.411 51.682 -21.411 h 18.267 c 4.949 0 9.233 -1.809 12.848 -5.424 c 3.613 -3.617 5.428 -7.898 5.428 -12.847 V 54.819 c 0 -4.948 -1.814 -9.233 -5.428 -12.85 c -3.614 -3.612 -7.898 -5.424 -12.848 -5.424 h -18.267 c -19.808 0 -38.691 3.858 -56.685 11.563 c -17.984 7.71 -33.537 18.132 -46.672 31.267 c -13.135 13.129 -23.559 28.688 -31.265 46.677 c -7.707 17.987 -11.567 36.879 -11.567 56.674 v 200.991 c 0 15.235 5.332 28.171 15.988 38.834 c 10.657 10.657 23.6 15.985 38.828 15.985 h 109.633 c 15.229 0 28.171 -5.328 38.827 -15.985 c 10.664 -10.663 15.985 -23.599 15.985 -38.834 V 274.088 C 475.082 258.855 469.76 245.92 459.103 235.256 Z" />
                                            </g>
                                        </g>

                                    </svg>
                                    <p class="my-3 2xl:text-lg">
                                        {item.testimonial}
                                    </p>
                                    <p class="text-gray-400"><span class="name text-gray-900 capitalize font-bold">
                                        {item.name}
                                        </span> &#8212; Student  </p>
                                </div>
                                <div class="relative shrink-0">
                                    <img src={item.image} alt="Jane Doe" class="rounded-full w-24 h-24 2xl:w-28 2xl:h-28 object-cover" />
                                    <div class="rounded-full w-24 h-24 2xl:w-28 2xl:h-28 bg-gradient-to-r from-[#deb2b280] to-[#deb2b280] absolute inset-0"></div>
                                </div>
                            </div>
                        ))
                    }
                   
                    
                    <div class="hidden xl:block absolute bottom-[-6rem] right-[25rem] w-72 h-72 bg-red-50 rounded-full"></div>
                </div>
            </section>
        </div>
    )
}

export default Testimonial