import { Fragment, useState, useEffect } from 'react'
import {
    BriefcaseIcon,
    CalendarIcon,
    CurrencyDollarIcon,
    MapPinIcon,
    PencilIcon,
    AcademicCapIcon,
    UserIcon,
    ClockIcon,
    BookOpenIcon,
    KeyIcon,
    CheckIcon,
} from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/auth-context';
import dayjs from 'dayjs'
import Rating from '../../components/partials/Rating'
import { apiInstance } from '../../config/api';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const profileLinkdata = [
    { icon: PencilIcon, link: "profile/personal-profile", label: "Personal Profile" },
    { icon: AcademicCapIcon, link: "profile/education", label: "Education" },
    { icon: BriefcaseIcon, link: "profile/work-history", label: "Work Experience" },
    { icon: UserIcon, link: "profile/profile-summary", label: "Profile Summary" },
    { icon: MapPinIcon, link: "profile/tutoring-location", label: "Tutoring Location" },
    { icon: ClockIcon, link: "profile/tutoring-schedule", label: "Tutoring Schedule" },
    { icon: BookOpenIcon, link: "profile/tutoring-subject", label: "Tutoring Subjects" },
    { icon: BookOpenIcon, link: "profile/certification", label: "Certification" },
    { icon: CurrencyDollarIcon, link: "profile/rate", label: "Payment Rate" },
    { icon: KeyIcon, link: "profile/reset-password", label: "Change Password" },
];

export default function ProfileHome() {
    const { user } = useAuth();
    const [userProfile, setUserProfile] = useState({});

    useEffect(() => {
        apiInstance.get(`profile/userprofile/${user?.user_id}/`)
            .then((res) => {
                console.log(res);
                const profile = res.data;
                setUserProfile(profile);
            }
            )
            .catch((err) => {
                console.log(err);
            });
        
    }, [user]);

    return (
        <div className="bg-white border-b border-gray-200 sm:px-6">
            <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-2">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        {userProfile?.profilepersonal && userProfile?.profilepersonal[0]?.title} {". "} {userProfile?.profilepersonal && userProfile?.profilepersonal[0]?.first_name} {" "} {userProfile?.profilepersonal && userProfile?.profilepersonal[0]?.last_name}
                    </h2>
                    <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            {userProfile?.profilepersonal && userProfile?.profilepersonal[0]?.town?.title}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            {userProfile?.profilepersonal && userProfile?.profilepersonal[0]?.address}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            {/* <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                            <Rating 
                                rating={userProfile?.avg_rating}
                                numberOfReviews={userProfile?.num_reviews}
                            />
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            Joined since {userProfile?.profilepersonal && dayjs(userProfile?.profilepersonal[0]?.created_at).format('MMMM YYYY')}
                        </div>
                    </div>
                </div>
                {/* <div className="mt-5 flex lg:ml-4 lg:mt-0">
                <span className="hidden sm:block">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Edit
                    </button>
                </span>

                <span className="ml-3 hidden sm:block">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        <LinkIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        View
                    </button>
                </span>

                <span className="sm:ml-3">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        Publish
                    </button>
                </span>

                <Menu as="div" className="relative ml-3 sm:hidden">
                    <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                        More
                        <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                    >
                                        Edit
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                    >
                                        View
                                    </a>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
                
            </div> */}

            </div>
            <div className="bg-white-100 p-4">
                <div className="max-w-2xl mx-auto">
                    <h1 className="text-2xl font-bold mb-4">My Profile</h1>
                    <div className="space-y-4">
                        {profileLinkdata.map((item) => (
                            <Link
                                key={item.link}
                                to={`/${item.link}`}
                                className={`block p-3 rounded-lg shadow-md  bg-green-100 hover:bg-green-200 transition duration-300 font-bold text-lg`}
                            >
                                <item.icon className="h-5 w-5 inline-block mr-2" />
                                {item.label}
                                {!item.completed && <CheckIcon className="h-5 w-5 inline-block ml-5 text-green-500 " />}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )
}
