import { IconSearch } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config/api';
import axios from 'axios';



function FilterNav() {
    const [languages, setLanguages] = useState([]);
    const [levels, setLevels] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [towns, setTowns] = useState([]);

    const [searchData, setSearchData] = useState({
        language: null,
        level: null,
        subject: null,
        town: '',
    });


    useEffect(() => {
        axios.get(API_URL + 'misc/language/')
            .then((res) => {
                console.log(res);
                setLanguages(res.data.results);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }
        , []);

    useEffect(() => {
        axios.get(API_URL + 'misc/level/?language=' + searchData.language)
            .then((res) => {
                console.log(res);
                setLevels(res.data.results);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }
        , [searchData.language]);

    useEffect(() => {
        axios.get(API_URL + 'misc/subject/?level=' + searchData.level)
            .then((res) => {
                console.log(res);
                setSubjects(res.data.results);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }
        , [searchData.level]);

    useEffect(() => {
        axios.get(API_URL + 'misc/town/')
            .then((res) => {
                console.log(res);
                setTowns(res.data.results);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }
        , []);




    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`/search`);
    }

    const fixedData = (data) => {
        return data.map((item) => ({
            value: item.id,
            label: item.name || item.title,
        }));
    }

    return (
        <form method='GET' action='/search'>
            <div className="mx-auto items-center  justify-centers max-w-screen-xl flex flex-col md:flex-row mt-4 px-3">
                <div className="w-full h-12 sm:my-2 mx-2">
                    <Select
                        name="language"
                        options={
                            fixedData(languages)
                        }
                        isSearchable
                        placeholder="Language"
                        onChange={(e) => setSearchData({ ...searchData, language: e.value })}
                    />
                </div>
                <div className="w-full h-12 sm:my-2 mx-2">
                    <Select
                        name="level"
                        options={
                            fixedData(levels)
                        }
                        isSearchable
                        placeholder="Level"
                        onChange={(e) => setSearchData({ ...searchData, level: e.value })}
                    />
                </div>
                <div className="w-full h-12 sm:my-2 mx-2">
                    <Select
                        name="subject"
                        options={
                            fixedData(subjects)
                        }
                        isSearchable
                        placeholder="Subjects"
                        onChange={(e) => setSearchData({ ...searchData, subject: e.value })}
                    />
                </div>

                <div className="w-full h-12 sm:my-2 mx-2">
                    <Select
                        name="town"
                        options={
                            fixedData(towns)
                        }
                        isSearchable
                        placeholder="Town"

                        onChange={(e) => setSearchData({ ...searchData, town: e.value })}
                    />
                </div>
                <div className="w-full h-12 sm:my-2 mx-3">
                    <button
                        type="submit"
                        className="inline-flex w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <IconSearch />
                        Search
                    </button>
                </div>

            </div>

        </form>
    );
}

export default FilterNav;
