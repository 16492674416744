import React, { useState, useEffect } from "react";
import {
  Container,
  Modal,
} from "@mantine/core";

import { apiInstance } from '../../config/api';
import ProfileBack from "../../components/ProfileBack";
import Education from "../../components/userprofile/create/Education";
import AddButton from "../../components/partials/AddButton";
import EducationTable from "../../components/userprofile/datatable/EducationTable";
import { useDisclosure } from "@mantine/hooks";

function EducationAdd() {
  const [opened, { open, close }] = useDisclosure(false);
  const [myEducation, setMyEducation] = useState([]);

  useEffect(() => {
    apiInstance.get("profile/education/?me=true")
      .then((res) => {
        console.log(res);
        const educationList = res.data.results;
        setMyEducation(educationList);
      }
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <div>
      <ProfileBack />
      <AddButton openModal={open} text="Add New Education" />
      <Modal opened={opened} onClose={close}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}>
        <Education onClose={close}
          setData={setMyEducation}
        />
      </Modal>
      <EducationTable data={myEducation} setData={setMyEducation} />
    </div>
  );
}

export default EducationAdd;
