import React from 'react'
import { Accordion, rem } from '@mantine/core';
import { IconPrinter, IconPhoto, IconCameraSelfie } from '@tabler/icons';


function Resume({ education, workHistory, certifications }) {
  return (
    <div className="bg-white p-3 rounded-lg shadow-lg mt-3 py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
      <p className='text-2xl font-bold'>
        Tutor Resume
      </p>
      <div className='py-4'>
        <Accordion variant="contained">
          <Accordion.Item value="photos">
            <Accordion.Control
              icon={
                <IconPhoto
                  style={{ color: 'var(--mantine-color-red-filled', width: rem(20), height: rem(20) }}
                />
              }
            >
              <p className='text-1xl font-bold'>
                Education
              </p>
            </Accordion.Control>
            <Accordion.Panel>
              <div className="education-history">
                {
                  education?.map((item, index) => {
                    return (
                      <div key={item.id} className="education-item mt-2 mx-3">
                        <h4 className="font-semibold text-md mb-1">{item.school}, {item.country}</h4>
                        <p className="text-gray-600">{item.degree} in {item.field_of_study}</p>
                        <p className="text-gray-600">{item.start_date} - {
                          item.still_studying ? 'Present' : item.end_date
                        }</p>
                        <hr className='my-3' />
                      </div>
                    )
                  })
                }
              </div>

            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="print">
            <Accordion.Control
              icon={
                <IconPrinter
                  style={{ color: 'var(--mantine-color-blue-filled', width: rem(20), height: rem(20) }}
                />
              }
            >

              <p className='text-1xl font-bold'>
                Work History
              </p>
            </Accordion.Control>
            <Accordion.Panel>
              <div className="education-history">
                {
                  workHistory?.map((item, index) => {
                    return (
                      <div key={item.id} className="education-item mt-2 mx-3">
                        <h4 className="font-semibold text-md mb-1">{item.company}</h4>
                        <p className="text-gray-600">{item.position}</p>
                        <p className="text-gray-600">{item.start_date} to {
                          item.still_working ? 'Present' : item.end_date
                        }</p>
                        <p className="text-gray-600">{item.summary}</p>
                        <hr className='my-3' />
                      </div>
                    )
                  })
                }

              </div>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="camera">
            <Accordion.Control
              icon={
                <IconCameraSelfie
                  style={{ color: 'var(--mantine-color-teal-filled)', width: rem(20), height: rem(20) }}
                />
              }
            >

              <p className='text-1xl font-bold '>
                Certifications
              </p>
            </Accordion.Control>
            <Accordion.Panel>
              <div className="education-history">
                {
                  certifications?.map((item, index) => {
                    return (
                      <div key={item.id} className="education-item mt-2 mx-3">
                        <h4 className="font-semibold text-md mb-1">{item.name_of_certification}</h4>
                        <p className="text-gray-600">{item.name_of_organization}</p>
                        <p className="text-gray-600">{item.link_to_certification}</p>
                        <p className="text-gray-600">{item.date_of_completion}</p>
                        <hr className='my-3' />
                      </div>
                    )
                  })
                }
              </div>

            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  )
}

export default Resume