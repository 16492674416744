import React, { useState, useEffect } from "react";
import {
    Grid,
    Title,
    Text,
    Card,
    Container,
    Image,
    Button,
    Group,
    Modal,
} from "@mantine/core";
import useApiData from "../../hooks/useApiData";
import { IMG_URL } from "../../config/api";


function PopularTutors() {
    // Define state for the Modal
    const [opened, setOpened] = useState(false);
    const [content, setContent] = useState("");
    const [heading, setHeading] = useState("");
    const { data: userProfiles, loading: userProfilesLoading } = useApiData(`profile/userprofile?popular=true`);

    // Define function to open the Modal and set its content
    const openModal = (description, heading) => {
        setContent(description);
        setHeading(heading);
        setOpened(true);
    };

    // Define function to close the Modal
    const closeModal = () => {
        setOpened(false);
        setContent("");
    };
    return (
        <Container fluid size="xs" px="xs" py="lg" className="bg-blue-100">
            <div className="px-8">
                <Title
                    order={3}
                    className="lg:text-3xl font-serif text-center leading-8 text-slate-800 py-3"
                >
                    Popular Tutors:
                </Title>
                <Grid>

                    {userProfiles?.map((profile, index) => {
                        return (
                            <>
                                <Modal
                                    opened={opened}
                                    onClose={closeModal}
                                >
                                    <Title order={1}>{heading}</Title>
                                    <Text fz={20} className="text-justify">
                                        {profile?.user_data?.profilesummary[0]?.profile_summary}
                                    </Text>
                                </Modal>
                                <Grid.Col xs={12} md={4} >
                                    <Card
                                        shadow="sm"
                                        padding="lg"
                                        radius="md"
                                        withBorder
                                        className="bg-indigo-200 h-screen"
                                    >
                                        <Card.Section component="a" href="https://mantine.dev/">
                                            <Image
                                                src={`${IMG_URL}${profile?.user_data?.profilepersonal[0]?.profile_pic}`}
                                                height={160}
                                                alt={profile?.user_data?.profilepersonal[0]?.first_name}
                                                className="animate-none"
                                            />
                                        </Card.Section>

                                        <Group position="apart" mt="md" mb="xs">
                                            <Title order={3}>
                                                {profile?.user_data?.profilepersonal[0]?.first_name} {". "}
                                                {profile?.user_data?.profilepersonal[0]?.last_name?.charAt(0)}
                                            </Title>
                                        </Group>

                                        <Text lineClamp={5} size="lg" className="text-black-500 text-xl leading-8 tracking-tight">
                                            {profile?.user_data?.profilesummary[0]?.profile_summary}
                                        </Text>

                                        <Button
                                            radius="xl"
                                            size="lg"
                                            className="absolute bottom-5 rounded-md w-full bg-white text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"

                                            onClick={() => openModal(profile?.user_data?.profilesummary[0]?.profile_summary, profile?.user_data?.profilepersonal[0]?.first_name)}
                                        >
                                            View Profile
                                        </Button>
                                    </Card>
                                </Grid.Col>
                            </ >
                        );
                    })}
                </Grid>
            </div>
        </Container>
    );
}

export default PopularTutors;
