import React from 'react'
import StudentHomePage from '../../pages/StudentHomePage'

function Welcome() {


  return (
    <>
      <StudentHomePage />
      {/* <TutorDashboard /> */}
      
    </>
  )
}

export default Welcome