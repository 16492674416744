import React, { useState } from 'react'
import { Modal } from '@mantine/core';
import dayjs from 'dayjs';
import { apiInstance } from '../../config/api';
import { notifications } from '@mantine/notifications';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons';
import Modals from '../partials/Modals';
import { useDisclosure } from '@mantine/hooks';
import EditClassRoom from './EditClassRoom';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';


const customStyles = {
    rows: {
        style: {
            minWidth : 'auto',
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            fontSize: '1.2rem',
            fontWeight: 'bold',
        },
    },
    cells: {
        style: {

            fontSize: '1.1rem',
            fontWeight: 'semi-bold',
            
        },
    },
};

function ClassRoomTable({ data, setData }) {
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedClass, setSelectedClass] = useState(null)
    const [opened, { open, close }] = useDisclosure(false);
    const navigate = useNavigate();


    const onDelete = (id) => {
        apiInstance.delete(`tutorclass/tutorclass/${id}/`)
            .then((res) => {
                console.log(res);
                setData((prevData) => prevData.filter((item) => item.id !== id));
                notifications.show({
                    title: 'Classroom Deleted',
                    message: 'Successfully Deleted Classroom',
                    color: 'green',
                    autoClose: true,
                })
            })
            .catch((err) => {
                console.log(err.response);
                notifications.show({
                    title: 'Error Deleting Classroom',
                    message: err.response.data.error,
                    color: 'red',
                    autoClose: true,
                })
            });
    }

    const openModal = (item) => {
        setSelectedClass(item);
        setDeleteModal(true);
    }

    const openEditModal = (item) => {
        setSelectedClass(item);
        open();
    }

    const navigateToClass = (id, name) => {
        navigate(`/classroom/${id}/${name}`)
    }

    const columns = [
        {
            name: 'Class title',
            selector: 'title',
            sortable: true,
            width : 'auto'
        },
        {
            name: 'Duration',
            selector: 'duration',
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => row.free ? <span className="px-3 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
                Free
            </span> : <span className="px-3 py-1 font-semibold leading-tight text-indigo-700 bg-indigo-100 rounded-full dark:bg-indigo-700 dark:text-indigo-100">
                Paid
            </span>,
            sortable: true,
            
        },
        {
            name: 'Enrollments',
            selector: 'enrolled',
            sortable: true,
        },
        {
            name: 'Amount',
            selector: 'price',
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (<div className="flex items-center gap-x-6">
                <button
                    onClick={() => navigateToClass(row?.id, row?.title)}
                    className="text-blue-500 transition-colors duration-200 hover:text-indigo-500 focus:outline-none">
                    <IconEye />
                </button>
                <button
                    onClick={() => openEditModal(row)}
                    className="text-black-500 transition-colors duration-200 dark:hover:text-indigo-500  hover:text-indigo-500 focus:outline-none">
                    <IconEdit />
                </button>
                <button
                    onClick={() => openModal(row)}
                    className="text-red-500 transition-colors duration-200 hover:text-indigo-500 focus:outline-none">
                    <IconTrash />
                </button>

            </div>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },

    ]


    return (

        <section className='my-4 mx-3'>
            <Modal opened={opened} onClose={close}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
                title="Edit Classroom">
                <EditClassRoom onClose={close} setData={setData} editclassName={selectedClass} />
            </Modal>

            <Modals open={deleteModal} setOpen={setDeleteModal}
                title={"Delete Classroom"}
                content={"Are you sure you want to delete this Class?"}
                action_title={"Delete"}
                action={() => {
                    onDelete(selectedClass?.id);
                    setDeleteModal(false);
                    setSelectedClass(null);
                }}
            />
            <div className="px-2">
                <DataTable
                    direction="auto"
                    responsive
                    highlightOnHover
                    pointerOnHover
                    columns={columns}
                    data={data}
                    striped
                    pagination
                    persistTableHead	
                    paginationServer
                    paginationPerPage={10}
                    progressPending={data.length ? false : true}
                    customStyles={customStyles}
                    onRowClicked={(row) => navigateToClass(row?.id, row?.title)}
                />
            </div>


        </section>
    )
}

export default ClassRoomTable