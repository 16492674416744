import React from 'react'
import { Link } from 'react-router-dom'

function AccountActivationPage() {
  return (
      <div className="container mx-auto py-16">
          <div className="max-w-lg mx-auto bg-white p-6 rounded shadow-lg text-center">
              <h1 className="text-2xl font-semibold mb-4 text-gray-800">Welcome to Tantorial</h1>
              <p className="text-gray-600 mb-4">Thank you for signing up!  Your Email has been Activated.</p>
              <p className="text-gray-600 mb-4">You Can now login </p>
              <Link to='/auth/login' className="block bg-blue-500 text-white font-semibold py-2 px-4 rounded-full transition duration-300 hover:bg-blue-600">Login to my account</Link>
              {/* <p className="mt-4 text-gray-600">If you have already activated your email, you can log in <a href="login-link" className="text-blue-500 hover:underline">here</a>.</p> */}
          </div>
      </div>
  )
}

export default AccountActivationPage