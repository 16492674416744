import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon, HeartIcon, BellAlertIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/outline'

function NotificationDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-indigo-300 transition ease-in-out duration-150"
          onClick={toggleDropdown}
        >
          <BellAlertIcon
            className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"

          />
          <svg
            className={`w-5 h-5 ml-2 -mr-1 transition-transform transform ${
              isOpen ? 'rotate-180' : 'rotate-0'
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100">
          <div className="py-1">
            <button
              type="button"
              className="text-gray-700 block w-full px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
            >
              View Balance
            </button>
          </div>
          <div className="py-1">
            <button
              type="button"
              className="text-red-700 block w-full px-4 py-2 text-sm hover:bg-red-100 hover:text-red-900"
            >
              Withdraw
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationDropdown;
