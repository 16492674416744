import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';

function Location({ onClose, setData }) {
  const [submitting, setSubmitting] = useState(false);
  const [myLocation, setMyLocation] = useState(
    {
      country: '',
      online: false,
      region : '',
      town : '',
      quater : '',
    }
  );


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    apiInstance.post('profile/location/', myLocation)
      .then((res) => {
        console.log(res.data);
        setData((prevData) => [...prevData, res.data]);
        notifications.show({
          title: 'Location Created',
          message: 'Successfully Created Location',
          color: 'green',
          autoClose: true,
        })
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Add Location',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMyLocation({
      ...myLocation,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Add Location</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Country:</label>
          <input type="text" id="country" name="country" value={myLocation.country} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Region:</label>
          <input
            type="text"
            id="school"
            name="region"
            value={myLocation.region}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Town:</label>
          <input type="text" id="town" name="town" value={myLocation.town} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Quater:</label>
          <input type="text" id="quater" name="quater" value={myLocation.quater} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
  
        <div className="mb-1">
          <input type="checkbox"
            value={myLocation.online}
            name="online"
            onChange={
              (e) => {
                setMyLocation({
                  ...myLocation,
                  online: e.target.checked,
                })
              }
            }
            id="myCheckbox" className="rounded-lg p-2 text-indigo-500 bg-white border-2 border-indigo-400 hover:bg-indigo-100" />
          <span className="ml-2 text-sm text-gray-600">Available Online</span>
        </div>
        <div className="mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Add Location
          </button>
        </div>
      </form>
    </div>
  )
}

export default Location