import CategoryFilter from "../../components/partials/CategoryFilter";
import FilterNav from "../../components/partials/FilterNav";

import React from 'react'

function AllTutors() {
  return (
    <div>
        <FilterNav />
      <CategoryFilter/>
    </div>
  )
}

export default AllTutors