import { useState } from 'react'

import { Switch } from '@headlessui/react'
import { useAuth } from '../../../context/auth-context';
import { apiInstance } from '../../../config/api';
import { notifications } from '@mantine/notifications';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BookLesson(props) {
    const [agreed, setAgreed] = useState(false)
    const { user } = useAuth();
    const [data, setData] = useState({
        tutor: props.tutor_id,
        learner_name: '',
        learner_email: '',
        learner_phone: '',
        start_date: '',
        expected_end_date: '',
        duration: '',
        mode: '',
        summary: '',
        amount: 0,

    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        await apiInstance.post('schedule/shedule-lesson/', data)
            .then((res) => {
                console.log(res.data);
                notifications.show({
                    title: 'Lesson Booked',
                    message: 'Successfully Booked Lesson',
                    color: 'green',
                    autoClose: true,
                })
                props.onClose();
            })
            .catch((err) => {
                console.log(err.response);
                notifications.show({
                    title: 'Error Booking Lesson',
                    message: err.response.data.error,
                    color: 'red',
                    autoClose: true,
                })
            });
    }


    return (
        <div className="isolate bg-white px-6 py-24 ">
            <div
                className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-1xl sm:top-[-20rem]"
                aria-hidden="true"
            >
                <div
                    className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Book a lesson with <span className="text-indigo-600">{props.first_name}</span>
                </h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    A Tantorial Agent will contact you after you submit this form to confirm your lesson.
                </p>
            </div>
            <form onSubmit={handleSubmit} method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            Start Date
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="date"
                                name="start_date"
                                id="start_date"
                                value={data.start_date}
                                onChange={(e) => setData({ ...data, start_date: e.target.value })}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            Expected End Date
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="date"
                                name="expected_end_date"
                                id="expected_end_date"
                                value={data.expected_end_date}
                                onChange={(e) => setData({ ...data, expected_end_date: e.target.value })}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    {
                        user?.is_authenticated ? (
                            <>
                                <div className="sm:col-span-2">
                                    <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Full Name
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="learner_name"
                                            id="learner_name"
                                            value={data.learner_name}
                                            onChange={(e) => setData({ ...data, learner_name: e.target.value })}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="learner_email"
                                            id="learner_email"
                                            value={data.learner_email}
                                            onChange={(e) => setData({ ...data, learner_email: e.target.value })}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Phone number
                                    </label>
                                    <div className="relative mt-2.5">
                                        <input
                                            type="tel"
                                            name="learner_phone"
                                            id="learner_phone"
                                            value={data.learner_phone}
                                            onChange={(e) => setData({ ...data, learner_phone: e.target.value })}
                                            autoComplete="tel"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </>
                        ) : ""
                    }
                    <div className="sm:col-span-2 mb-2">
                        <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                            Duration Per day (in hours)
                        </label>

                        <select name="duration" id="duration" value={data.duration} onChange={(e) => setData({ ...data, duration: e.target.value })} className="h-full rounded-md w-full border rounded-md">
                            <option>Select Duration</option>
                            <option value="1" >1 Hour</option>
                            <option value="2" >2 Hours</option>
                            <option value="3" >3 Hours</option>
                            <option value="4" >4 Hours</option>
                            <option value="5" >5 Hours</option>
                            <option value="6" >6 Hours</option>
                        </select>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                            Propose Amount (in CFA)
                        </label>
                        <div className="relative mt-2.5">
                            <input
                                type="number"
                                name="amount"
                                id="amount"
                                value={data.amount}
                                onChange={(e) => setData({ ...data, amount: e.target.value })}
                                autoComplete="tel"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2 mb-2">
                        <label htmlFor="price" className="block text-gray-600">Mode:</label>
                        <select name="mode" id="mode" value={data.mode}
                            onChange={(e) => setData({ ...data, mode: e.target.value })}
                            className="h-full rounded-md w-full border rounded-md">
                            <option>Select Preferred Mode</option>
                            <option value="online" >Online</option>
                            <option value="in-person" >In Person</option>
                            <option value="hybrid" >Hybrid</option>
                        </select>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                            Message for the tutor (optional)
                        </label>
                        <div className="mt-2.5">
                            <textarea
                                placeholder='Hi, I would like to book a lesson with you.'
                                name="message"
                                id="message"
                                rows={4}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                            />
                        </div>
                    </div>
                    <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                        <div className="flex h-6 items-center">
                            <Switch
                                checked={agreed}
                                onChange={setAgreed}
                                className={classNames(
                                    agreed ? 'bg-indigo-600' : 'bg-gray-200',
                                    'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                )}
                            >
                                <span className="sr-only">Agree to policies</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        agreed ? 'translate-x-3.5' : 'translate-x-0',
                                        'h-4 w-4 transform rounded-full bg-white ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </div>
                        <Switch.Label className="text-sm leading-6 text-gray-600">
                            By selecting this, you agree to our{' '}
                            <a href="#" className="font-semibold text-indigo-600">
                                privacy&nbsp;policy
                            </a>
                            .
                        </Switch.Label>
                    </Switch.Group>
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Book Lesson with {props.first_name}
                    </button>
                </div>
            </form>
        </div>
    )
}
