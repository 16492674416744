import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';

function EditRate({ onClose, setData, editItem }) {
  const [submitting, setSubmitting] = useState(false);
  const [myRate, setMyRate] = useState([]);

  useEffect(() => {
    apiInstance.get('profile/rate/' + editItem?.id + '/')
      .then((res) => {
        console.log(res);
        setMyRate(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [editItem]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { user, ...newData } = myRate;
    apiInstance.patch('profile/rate/' + editItem?.id + '/', newData)
      .then((res) => {
        console.log(res.data);
        setData((prevData) => prevData.map((item) => item.id === res.data.id ? res.data : item))
        notifications.show({
          title: 'Item Edited',
          message: 'Successfully Edited Item',
          color: 'green',
          autoClose: true,
        })
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Editing Item',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMyRate({
      ...myRate,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Edit Rate</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Mode:</label>
          <input type="text" id="mode" name="mode" value={myRate.mode} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Amount:</label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={myRate.amount}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Rate:</label>
          <input type="text" id="charge" name="charge" value={myRate.charge} onChange={handleChange} className="w-full p-2 border rounded-md" />
        </div>

        <div className="mb-1">
          <input type="checkbox"
            value={myRate.negotiable}
            name="negotiable"
            onChange={
              (e) => {
                setMyRate({
                  ...myRate,
                  negotiable: e.target.checked,
                })
              }
            }
            id="myCheckbox" className="rounded-lg p-2 text-indigo-500 bg-white border-2 border-indigo-400 hover:bg-indigo-100" />
          <span className="ml-2 text-sm text-gray-600">Negotiable</span>
        </div>

        <div className="mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Edit Rate
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditRate