import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL, apiInstance } from '../../../config/api';
import { useAuth } from '../../../context/auth-context';
import {
  Container,
  Text,
  Grid,
  Button,
  Group,
  Select,
  Radio,
  Textarea, Paper, MultiSelect,
} from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';

import { IconChevronDown, } from '@tabler/icons';


function LearnerNeed() {
  const navigate = useNavigate()
  const { state } = useLocation();
  const childClass = state?.childClass;
  const ChildCount = state?.ChildCount;
  const tutoringType = state?.tutoringType;
  const profileID = state?.profileID;
  const [subSystem, setSubSystem] = useState();
  const [goals, setGoals] = useState([]);
  const [subgoals, setSubgoals] = useState([]); // [goal, subgoal
  const [subjects, setSubjects] = useState([]);
  const [subSections, setSubSections] = useState([]); // [goal, subgoal, subject


  const handleInputChange = (event, fieldIndex, fieldName) => {
    const tmpFields = [...childClass];
    const value = event?.target?.value ?? event;
    tmpFields[fieldIndex][fieldName] = value;
    console.log(childClass)
  };

  const handleBackClick = () => {
    window.history.back();
  };


  const handleNextClick = () => {
    const postPromises = childClass?.map((item) => {
      return apiInstance.post(`tutoringprofile/learner-need/`, {
        learner_profile: profileID,
        learner_class: item.child_class,
        learner_goal: item.child_goal,
        learner_subgoal: item.child_goal,
        learner_subject: item.child_subject,
        description: item.child_more,
        learner_level: item.child_level,
        learner_language: item.child_language,
        data: null,
        learner_subsection: subSystem

      });
    });

    // Use Promise.all() to wait for all POST requests to complete
    Promise.all(postPromises)
      .then((results) => {
        console.log(results); // An array of response objects
        navigate('/tutoring/prefer-tutor', {
          state: {
            childClass,
            ChildCount,
            tutoringType,
            profileID: profileID
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    apiInstance.get(`misc/goal/`)
      .then((res) => {
        const goalList = res.data.results
        setGoals(goalList)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    apiInstance.get(`misc/sub-goal/`)
      .then((res) => {
        const subgoalList = res.data.results
        setSubgoals(subgoalList)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    apiInstance.get(`misc/subsection/`)
      .then((res) => {
        setSubSections(res.data.results)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    apiInstance.get(`misc/subject/`)
      .then((res) => {
        const subjectList = res.data.results
        console.log("0000222", subjectList)
        // alert(JSON.stringify(subjectList))
        setSubjects(subjectList)
      })
      .catch((err) => {
        console.log("00000000")
        console.log(err)
      })
  }, [])

  const fixedDataGoal = (data) => {
    return data.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }

  const fixedData = (data, level) => {
    const dat = data.filter((item) => item.level === level);
    return dat.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }



  return (
    <Container mb={30}>
      <Text size={30} fw={700} fs="italic">
        Tell us your child's need {profileID}
      </Text>
      <Text size={20} fs="italic">
        Goals and Subjects
      </Text>

      {childClass?.map((field, index) => {
        return (
          <Paper key={index} shadow="xl" mt="lg" withBorder>
            <Text size={25} fw={600} fs="italic" mx={20} mt={20}>
              About Child in {field.child_class}
            </Text>
            <Grid mx={5}>
              <Grid.Col md={6} sm={12}>
                <Grid.Col span={12}>
                  <Select
                    label="What is the Goal"
                    placeholder="Select Goal"
                    id="select"
                    searchable
                    defaultValue={field.child_goal}
                    onChange={(event) =>
                      handleInputChange(event, index, "child_goal")
                    }
                    mx="auto"
                    size="lg"
                    rightSection={<IconChevronDown size="1rem" />}
                    rightSectionWidth={40}
                    data={
                    fixedDataGoal(goals)
                  }
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <MultiSelect
                    label="Subjects of interest"
                    placeholder="Select Subject"
                    id="select"
                    defaultValue={field.child_subject}
                    searchable
                    onChange={(event) =>
                      handleInputChange(event, index, "child_subject")
                    }
                    mx="auto"
                    size="lg"
                    rightSection={<IconChevronDown size="1rem" />}
                    rightSectionWidth={40}
                    data={
                      fixedData(subjects, field.child_level)
                    }
                  />

                </Grid.Col>
              </Grid.Col>
              <Grid.Col md={6} sm={12} mb={25}>
                <Textarea
                  placeholder="Tell us a bit about this child's needs"
                  label="Tell us more"
                  onChange={(event) =>
                    handleInputChange(event, index, "child_more")
                  }
                  withAsterisk
                  autosize
                  minRows={4}
                />
              </Grid.Col>
            </Grid>
          </Paper>
        );
      })}
      <Paper shadow="xl" p="xl" mt={20} withBorder>
        <Radio.Group
          name="tutoring-type"
          label="Subsection"
          size="lg"
          value={subSystem}
          onChange={setSubSystem}
        >
          <Group mt="xs">
            {
              subSections?.map((item) => (
                <Radio
                  key={item.id}
                  value={`${item.title}`}
                  label={item.title}
                  description={item.description}
                />
              ))

            }
          </Group>
        </Radio.Group>
      </Paper>
      <Group position="apart">
        <Button mt={20} variant="default" radius="lg" size="lg" onClick={handleBackClick} uppercase>
          Back
        </Button>
        <Button mt={20} variant="default" radius="lg" size="lg" onClick={handleNextClick} uppercase>
          Next
        </Button>
      </Group>
    </Container>
  )
}

export default LearnerNeed