export const team = [
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/EricTagne.jpg",
    name: "Eric Tagne",
    position: "Software Engineer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/FonchamTheo.jpeg",
    name: "Foncham Theo",
    position: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/AnicetTadonkemwa.jpg",
    name: "Anicet Tadonkemwa",
    position: "Intermediate Fullstack Developer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/AnselmeTchassem.jpeg",
    name: "Anselme Tchassem",
    position: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/MarcelTchamba.jpg",
    name: "Marcel Tchamba",
    position: "Quality Analyst",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/ElvinNdichia.jpeg",
    name: "Elvin Ndichia",
    position: "UX/UI Designer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LeonelNgoya.jpeg",
    name: "Leonel Ngoya",
    position: "Junior Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/AzehGideon.jpg",
    name: "Azeh Gideon",
    position: "Software Developer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LarissaAlima.jpg",
    name: "Larissa Alima",
    position: "Site Manager",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/habib.jpeg",
    name: "Habib Tafo",
    position: "Director",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/ValeryMelou.jpeg",
    name: "Valery Melou",
    position: "Head of Operations",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/CabrelYonkeu.jpg",
    name: "Cabrel Yonkeu",
    position: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LauraTalom.jpg",
    name: "Laura Talom",
    position: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LoicNguepi.png",
    name: "Loïc Nguepi",
    jobTitle: "Support Engineer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/EdmondMbange.jpg",
    name: "Edmond Mbange",
    jobTitle: "Product Owner",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/CalvineNsoh.jpg",
    name: "Calvine Nsoh",
    jobTitle: "Support Engineer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/EricTagne.jpg",
    name: "Eric Tagne",
    jobTitle: "Software Engineer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/FonchamTheo.jpeg",
    name: "Foncham Theo",
    jobTitle: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/AnicetTadonkemwa.jpg",
    name: "Anicet Tadonkemwa",
    jobTitle: "Intermediate Fullstack Developer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/AnselmeTchassem.jpeg",
    name: "Anselme Tchassem",
    jobTitle: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/MarcelTchamba.jpg",
    name: "Marcel Tchamba",
    jobTitle: "Quality Analyst",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/ElvinNdichia.jpeg",
    name: "Elvin Ndichia",
    role: "UX/UI Designer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LeonelNgoya.jpeg",
    name: "Leonel Ngoya",
    role: "Junior Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/AzehGideon.jpg",
    name: "Azeh Gideon",
    role: "Software Developer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LarissaAlima.jpg",
    name: "Larissa Alima",
    role: "Site Manager",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/habib.jpeg",
    name: "Habib Tafo",
    role: "Director",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/ValeryMelou.jpeg",
    name: "Valery Melou",
    role: "Head of Operations",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/CabrelYonkeu.jpg",
    name: "Cabrel Yonkeu",
    role: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LauraTalom.jpg",
    name: "Laura Talom",
    role: "Digital Consultant",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/LoicNguepi.png",
    name: "Loïc Nguepi",
    role: "Support Engineer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/EdmondMbange.jpg",
    name: "Edmond Mbange",
    role: "Product Owner",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/CalvineNsoh.jpg",
    name: "Calvine Nsoh",
    role: "Support Engineer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/EricTagne.jpg",
    name: "Eric Tagne",
    role: "Software Engineer",
  },
  {
    imgSrc: "https://www.mboadigital.tech/assets/images/FonchamTheo.jpeg",
    name: "Foncham Theo",
    role: "Digital Consultant",
  },
];

export const group_img = [
  "https://www.mboadigital.tech/assets/images/group-1.jpg",
  "https://www.mboadigital.tech/assets/images/group-2.jpg",
  "https://www.mboadigital.tech/assets/images/group-3.jpg",
  "https://www.mboadigital.tech/assets/images/group-4.jpg",
  "https://www.mboadigital.tech/assets/images/group-5.jpg",
];

export const frame_slide = [
  "https://www.mboadigital.tech/assets/images/mobile-frames1.png",
  "https://www.mboadigital.tech/assets/images/mobile-frames1.png",
  "https://www.mboadigital.tech/assets/images/mobile-frames1.png",
  "https://www.mboadigital.tech/assets/images/mobile-frames1.png",
  "https://www.mboadigital.tech/assets/images/mobile-frames1.png",
  "https://www.mboadigital.tech/assets/images/mobile-frames1.png",
];

export const recruitement_illustrator =
  "https://www.mboadigital.tech/assets/illustrations/recruitment-illustration.svg";

export const service = [
  {
    title: "Access to Qualified Tutors",
    description: `Tantorial provides learners with access to a network of experienced and qualified tutors from diverse backgrounds and expertise.`,
  },
  {
    title: "Flexible Learning Options",
    description: `Tantorial offers flexible scheduling and learning options, allowing learners to fit tutoring sessions into their busy schedules and learn at their own pace.`,
  },
  {
    title: "Personalized Learning Approach",
    description: `
    Tantorial's personalized learning approach ensures that each learner receives tailored instruction and support to achieve their unique goals.
    `,
  },
  {
    title: "Engaging and Effective Learning Experience",
    description: `
Tantorial's gamified elements and interactive tools create an engaging and effective learning environment that motivates learners and promotes knowledge retention.
    `,
  },
  {
    title: "Cost-Effective Learning Solution",
    description: `
    Tantorial offers competitive pricing and various payment options, making online tutoring accessible to a wider range of learners.
    `,
  },
  {
      title: 'On-demand availability',
      description: 'Tantorial provides learners with access to a network of experienced and qualified tutors from diverse backgrounds and expertise. so you can schedule lessons whenever you are free'
  }
];
