import React from "react";
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Grid, Box, Title, Text } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import DesignIcon from "../assets/design.svg";
import DevelopmentIcon from "../assets/develop.svg";
import LiveIcon from "../assets/live.svg";
import SupportIcon from "../assets/support.svg";
import { NetworkOff } from "tabler-icons-react";
import { Link } from "react-router-dom";


const carouselItems = [
  { title: "Personalized Learning", icon: DesignIcon },
  { title: "Real-time Tutor Matching", icon: DevelopmentIcon },
  { title: "Gamified Learning Experience", icon: LiveIcon },
  { title: "Comprehensive Progress Tracking", icon: SupportIcon },
  { title: 'Streamlined Administrative Processes', icon: NetworkOff }
];

const AutomationCard = () => {
  const autoplay = useRef(Autoplay({ delay: 1000 }));


  return (
    <Box className="md:px-5 py-4 px-8 h-100 bg-green-100">
      <Grid>
        <Grid.Col md={8}>
          <Title
            order={1}
            fz={90}
            className="mt-8 pt-8 px-8 md:text-5xl font-serif leading-8 text-cyan-500 text-3xl "
          >
            key features of Tantorial:
          </Title>
          <Text className="mt-8 px-8 mt-6 md:text-2xl text-lg leading-8 text-gray-600">
           Tantorial is a revolutionary platform that is poised to disrupt the traditional tutoring market
          </Text>
          <div className="mx-6 my-3 pt-3">
            <Link to='/auth/register/?tutor=true' className="text-3xl p-3 animate-bounce md:text-2xl text-lg leading-8 text-gray-600 rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
              Become a tutor  <span aria-hidden="true">→</span>
            </Link>

          </div>

        </Grid.Col>

        <Grid.Col md={12}>
          <Carousel
            withIndicators
            height={100}
            slideSize="33%"
            slideGap="md"
            loop
            dragFree
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            align="start"
            mx="auto"
            breakpoints={[
              { maxWidth: "md", slideSize: "50%" },
              { maxWidth: "sm", slideSize: "100%" },
            ]}
          >
            {carouselItems.map((item, index) => {
              return (
                <Carousel.Slide
                  key={item.title}
                  gap="xl"
                  className= "border-l-4 ml-4 pl-4"
                >
                  <Text className="font-bold text-2xl mt-2">
                    {item.title}
                  </Text>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default AutomationCard;
