import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileBack = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/profile');
    };
    return (
        <nav className="bg-gray-100 p-2">
            <div className="container mx-auto">
                <div className="flex justify-between items-center">
                    <button onClick={goBack} className="text-dark text-lg font-semibold">
                        {"<"} Go Back
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default ProfileBack;
