import React from 'react'

function Pricing() {

    let currencyFormater = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'XAF',
    })

  return (
      <div class="my-30 isolate pb-28 sm:pb-0">
          <div class=" bg-gray-900">
              <div class="max-w-7xl min-h-screen mx-auto p-4 sm:p-10 lg:py-20">
                  <div class="max-w-5xl mx-auto text-center text-white tracking-widest pb-10 lg:pb-20">
                      <p class="pb-4 text-xl text-gray-200">PRICING</p>
                      <h1 class="text-3xl sm:text-5xl font-black">The right price for you, who everyou are</h1>
                      <p class="text-xl sm:text-2xl font-light text-gray-200 px-10 py-6">
                        Our plans are designed to meet the requirements of both Students <br class="hidden lg:block" /> and Learners. Get the right plan that suits you.
                      </p>
                  </div>

                  <div class="flex flex-col lg:flex-row justify-center items-center  gap-8 lg:gap-0 lg:mt-4">
                      <div class="flex-1 w-full mb-6 bg-white rounded-xl shadow-xl lg:scale-95">
                          <div class="text-center p-12">
                              <p class="text-3xl lg:text-2xl xl:text-3xl pb-4">Assignment Package</p>
                              <div class="flex justify justify-center items-center">
                                  <span class="font-extrabold text-3xl  align-text-middle px-3">
                                        {currencyFormater.format(5000)}
                                  </span>
                                  <span class="font-normal text-xl text-gray-500 inline-block align-text-middle">/month</span>
                              </div>
                          </div>
                          <div class="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                              <ul class="space-y-4">
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">when an unknown printer took a galley.</span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">leap into electronic typesetting remaining. </span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">ontaining Lorem Ipsum passages and more.</span>
                                  </li>
                              </ul>
                              <button type="button" class="w-full text-center bg-white text-lg text-indigo-600 mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-indigo-600">Start your trial</button>
                          </div>
                      </div>
                      <div class="lg:max-w-max mb-6 relative w-full bg-white rounded-xl shadow-xl border-2 border-indigo-600 lg:scale-110 z-10">
                          <div class="text-center p-12">
                              <p class="text-3xl lg:text-2xl xl:text-3xl pb-4 font-semibold">Growth Package</p>
                              <div class="flex justify justify-center items-center">
                                  <span class="font-extrabold text-3xl align-text-middle px-3">
                                        {currencyFormater.format(10000)}
                                  </span>
                                  <span class="font-normal text-xl text-gray-500 inline-block align-text-middle">/month</span>
                              </div>
                          </div>
                          <div class="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                              <ul class="space-y-4">
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">when an unknown printer took a galley.</span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">leap into electronic typesetting remaining. </span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">ontaining Lorem Ipsum passages and more.</span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">leap into electronic typesetting remaining. </span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">ontaining Lorem Ipsum passages and more.</span>
                                  </li>
                              </ul>
                              <button type="button" class="w-full text-center bg-indigo-600 text-lg text-white mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-indigo-700">Start your trial</button>
                          </div>
                          <div class="absolute rounded-full w-40 bg-indigo-600 text-white text-center text-sm tracking-wider px-4 py-1 -top-3 inset-x-0 mx-auto">MOST POPULAR</div>
                      </div>
                      <div class="flex-1 w-full mb-6 bg-white rounded-xl shadow-xl lg:scale-95">
                          <div class="text-center p-12">
                              <p class="text-3xl lg:text-2xl xl:text-3xl pb-4">VIP Package</p>
                              <div class="flex justify justify-center items-center">
                                  <span class="font-extrabold text-3xl align-text-middle px-3">
                                        {currencyFormater.format(20000)}
                                  </span>
                                  <span class="font-normal text-xl text-gray-500 inline-block align-text-middle">/month</span>
                              </div>
                          </div>
                          <div class="bg-gray-100 rounded-b-xl border-t-2 border-gray-200/20 p-10">
                              <ul class="space-y-4">
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">when an unknown printer took a galley.</span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">leap into electronic typesetting remaining. </span>
                                  </li>
                                  <li class="flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                      <span class="text-gray-500">ontaining Lorem Ipsum passages and more.</span>
                                  </li>
                              </ul>
                              <button type="button" class="w-full text-center bg-white text-lg text-indigo-600 mt-8 p-3 rounded shadow-xl transition hover:text-white hover:bg-indigo-600">Start your trial</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Pricing