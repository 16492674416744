import React, { useState, useRef, useEffect } from 'react'
import {
  Container,
  Text,
  Button,
  Group,
  Select,
  Radio,
  Paper, Divider, Checkbox, Grid, Notification, ActionIcon, rem
} from '@mantine/core';
import { apiInstance } from '../../../config/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconCalendar, IconChevronDown, IconCheck, IconClock } from '@tabler/icons';
import { DateInput, TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';



function LessonSchedule() {
  const navigate = useNavigate()
  const ref = useRef()
  const { state } = useLocation();
  const ChildCount = state?.ChildCount;
  const profileID = state?.profileID;
  const [tutorDays, setTutorDays] = useState([]);
  const [tutoringPeriod, setTutoringPeriod] = useState(new Date());
  const [tutoringDuration, setTutoringDuration] = useState();
  const [tutoringDurationPerDay, setTutoringDurationPerDay] = useState();
  const [tutoringTime, setTutoringTime] = useState();
  const [tutoringTimeAllocation, setTutoringTimeAllocation] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false)
  const [DAYS, setDAYS] = useState([])
  const [DURATION, setDURATION] = useState([])
  const [DURATION_PER_DAY, setDURATION_PER_DAY] = useState([])


  const handleBackClick = () => {
    window.history.back();
  };

  const pickerControl = (
    <ActionIcon variant="subtle" color="gray" onClick={() => ref.current?.showPicker()}>
      <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </ActionIcon>
  );

  const total_hours = parseInt(ChildCount) * parseInt(tutoringDurationPerDay);
  const lessonHours = tutoringTimeAllocation === "individual" ? total_hours : tutoringDurationPerDay;
  const lessonDurationTotal = lessonHours * parseInt(tutoringDuration) * tutorDays?.length;

  const handleSubmit = () => {
   apiInstance.post(`tutoringprofile/learning-period/`, {
      learner_profile: profileID,
      start_date: dayjs(tutoringPeriod).format('YYYY-MM-DD'),
      day_per_week: tutorDays?.length,
      duration: tutoringDuration,
      hours_per_day: tutoringDurationPerDay,
      start_time: tutoringTime,
      days: tutorDays,
      total_hour: lessonDurationTotal,
      allocation: tutoringTimeAllocation,
    })
      .then((res) => {
        setSubmitting(false)
        setSubmitted(true)
        setTimeout(() => {
          navigate('/dashboard')
        }, 2000);
      }
      )
      .catch((err) => {
        setSubmitting(false)
        console.log(err)
      }
      )


  }

  useEffect(() => {
   apiInstance.get(`misc/day/`)
      .then((res) => {
        setDAYS(res.data.results)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
   apiInstance.get(`misc/duration/`)
      .then((res) => {
        const durationList = res.data.results
        setDURATION(durationList?.map((item) => ({ value: item.id, label: item.title.toUpperCase() })))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
   apiInstance.get(`misc/duration-per-day/`)
      .then((res) => {
        const durationList = res.data.results
        setDURATION_PER_DAY(durationList?.map((item) => ({ value: item.id, label: item.title.toUpperCase() })))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  return (
    <Container mb={30} >
      <Text maw={400}
        mx="auto" size={25} fw={700} fs="italic">
        Lesson Schedule
      </Text>
      <Text maw={400}
        mx="auto" size={20} fs="italic">
        Tell us how often you would need your tutor
      </Text>

      <Paper shadow="xl" p="xl" mt={20} withBorder>
        {
          submitting && (
            <Notification loading color="green" title="We notify you that" withBorder maw={400}
              mx="auto" my={20} >
              You are now obligated to give a star to Mantine project on GitHub
            </Notification>
          )
        }
        {
          submitted && (
            <Notification icon={<IconCheck size="1.2rem" />} withBorder color="green" title="We notify you that" maw={400}
              mx="auto" my={20}>
              You are now obligated to give a star to Mantine project on GitHub
            </Notification>
          )
        }
        <Checkbox.Group
          label="Which Days will you be needing a tutor?"
          description="Select the days you would like to have a tutor"
          withAsterisk
          radius="xl"
          size="xl"
          value={tutorDays}
          onChange={setTutorDays}
        >
          <Group mt="xs">
            {
              DAYS.map((day) => (
                <Checkbox key={day.id} value={`${day.title}`} label={day.title} />
              ))
            }
          </Group>
        </Checkbox.Group>
        <Text my={20} size={20} fw={700} fs="italic">
          You will need a tutors for {tutorDays?.length} days
        </Text>
        <Divider mt={20} mb={20} size="sm" />
        <Text my={20} size={20} fw={700} fs="italic">
          Lesson Period and Duration
        </Text>
        <Grid>

          <Grid.Col md={6} sm={6} xs={12}>
            <DateInput
              minDate={new Date()}
              maxDate={dayjs(new Date()).add(1, 'month').toDate()}
              value={tutoringPeriod}
              onChange={setTutoringPeriod}
              label="When Do you want to start?"
              placeholder="Select date"
              mx="auto"
              size="lg"
              disabled={submitting}
              icon={<IconCalendar />}
            />
          </Grid.Col>
          <Grid.Col md={6} sm={6} xs={12}>
            <Select
              label="For How Long?"
              placeholder="Select Lesson Duration"
              value={tutoringDuration}
              onChange={setTutoringDuration}
              mx="auto"
              size="lg"
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={40}
              disabled={submitting}
              data={DURATION}
            />
          </Grid.Col>
          <Grid.Col md={6} sm={6} xs={12}>
            <Select
              label="How Many Hours Per Day?"
              placeholder="Select Daily Duration"
              value={tutoringDurationPerDay}
              onChange={setTutoringDurationPerDay}
              mx="auto"
              size="lg"
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={40}
              data={DURATION_PER_DAY}
              disabled={submitting}
            />
          </Grid.Col>
          <Grid.Col md={6} sm={6} xs={12}>
            {/* {tutoringTime} */}
            <TimeInput label="Lesson Start time?"
              placeholder="Select time of the day"
              value={tutoringTime}
              onChange={(e) =>setTutoringTime(e.target.value)}
              mx="auto"
              size="lg"
              disabled={submitting}
              ref={ref} rightSection={pickerControl} 
              />
            {/* <Select
              label="Lesson Start time?"
              placeholder="Select time of the day"
              value={tutoringTime}
              onChange={setTutoringTime}
              mx="auto"
              size="lg"
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={40}
              data={TIME}
              disabled={submitting}
            /> */}
          </Grid.Col>
        </Grid>

        <Divider mt={20} mb={20} size="sm" />
        {
          tutoringDurationPerDay && (
            <Radio.Group
              name="tutorial-allocation"
              label={`How would you like to allocate your ${tutoringDurationPerDay} hours?`}
              size="lg"
              value={tutoringTimeAllocation}
              onChange={setTutoringTimeAllocation}
              disabled={submitting}
            >
              <Group mt="xs">
                <Radio value="individual" label={`${tutoringDurationPerDay} hours for per kids (${total_hours} in total)`} size="lg" />
                <Radio value="group" label={`${tutoringDurationPerDay} hours for all kids  (${tutoringDurationPerDay} in total)`} size="lg" />
              </Group>
            </Radio.Group>
          )
        }

        <Divider mt={20} mb={20} size="sm" />

        <Group
          mx="auto" position="apart">
          <Button mt={20} variant="default" radius="lg" size="lg" onClick={handleBackClick} uppercase>
            Back
          </Button>
          <Button mt={20} variant="default" radius="lg" size="lg" onClick={handleSubmit} uppercase>
            Save Profile
          </Button>
        </Group>
      </Paper>

    </Container>
  )
}

export default LessonSchedule