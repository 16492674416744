import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { API_URL } from '../../config/api'
import axios from 'axios'
import { notifications } from '@mantine/notifications';


function SendActivationEmail() {
    const [resent, setResent] = useState(false)

    const resend_email = (e) => (
        axios.post(`${API_URL}auth/users/resend_activation/`, { email: localStorage.getItem('email') })
            .then(response => {
                setResent(true)
                notifications.show({
                    title: 'Activation Email Sent',
                    message: 'Please Check your E-mail inbox to activate your account',
                    color: 'green',
                    autoClose: true,
                })
                setInterval(() => {
                    setResent(false)
                }, 60000);
            })
    )

    return (
        <div className="container mx-auto py-16 mt-5">
            <div className="max-w-lg mx-auto bg-white p-6 rounded shadow-lg text-center">
                <h1 className="text-2xl font-semibold mb-4 text-gray-800">Email Activation</h1>
                <p className="text-gray-600 mb-4">Thank you for signing up! Please activate your email to get started.</p>
                <p className="text-gray-600 mb-4">If you did not received the email please click the button </p>
                {
                    resent ? (
                        <button
                            className="block w-full bg-green-600  text-white font-semibold py-2 px-4 rounded-full transition duration-300 hover:animate-bounce">
                                Activation Email Sent
                        </button>
                    ) : (
                            <button
                                onClick={resend_email}
                                className="block w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-full transition duration-300 hover:bg-indigo-700">
                                Resend Activation Email
                            </button>
                    )
                }
               
                <p className="mt-4 text-gray-600">
                    If you have already activated your email, {" "}
                    <Link to='/auth/login' className="text-blue-500 hover:underline">log in</Link>.
                </p>
            </div>
        </div>
    )
}

export default SendActivationEmail