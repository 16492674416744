import React, {useState, useEffect} from 'react';
import StarRating from 'react-star-ratings';
import { apiInstance } from '../config/api';
import dayjs from 'dayjs';

const MyReviews = () => {
    const [reviewsAndRatings, setReviewsAndRatings] = useState([]);

    useEffect(() => {
        const fetchReviewsAndRatings = async () => {
            try {
                const response = await apiInstance.get(`like/reviews/?me=true`);
                if (response.status === 200) {
                    setReviewsAndRatings(response?.data?.results);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchReviewsAndRatings();
    }
    , []);

    return (
        <div className="container mx-auto mt-6">
            <h1 className="text-3xl font-semibold mb-4">My Reviews and Ratings</h1>
            {
                reviewsAndRatings.length === 0 && (
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <p className="text-gray-600 mb-2 text-2xl font-bold">No reviews yet</p>
                    </div>
                )
            }
            <ul className="space-y-4">
                {reviewsAndRatings.map((item) => (
                    <li key={item.id}>
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <p className="text-lg font-semibold mb-2">
                                {item.tutorName} <span className="text-gray-600"> {`${item.user?.first_name} ${item.user?.last_name}`}</span> {" "}
                                {dayjs(item.created_at).format('DD-MMM-YYYY HH:mm A')}
                            </p>
                            <p className="text-gray-600 mb-2">
                                <StarRating
                                    rating={item.rating}
                                    starRatedColor="gold"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                />
                            </p>
                            <p className="text-gray-600 mb-2">{item.content}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MyReviews;
