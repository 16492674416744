import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';


function EditWorkExperience({ editItem, onClose, setData}) {
    const [myWork, setMyWork] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        apiInstance.get('profile/workhistory/' + editItem?.id + '/')
            .then((res) => {
                console.log(res);
                setMyWork(res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, [editItem]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);
        const { user, ...newData } = myWork;
        apiInstance.patch('profile/workhistory/' + editItem?.id + '/', newData)
            .then((res) => {
                console.log(res.data);
                setData((prevData) => prevData.map((item) => item.id === res.data.id ? res.data : item))
                notifications.show({
                    title: 'Item Edited',
                    message: 'Successfully Edited Item',
                    color: 'green',
                    autoClose: true,
                })
                onClose();
            })
            .catch((err) => {
                console.log(err.response);
                notifications.show({
                    title: 'Error Editing Item',
                    message: err.response.data.error,
                    color: 'red',
                    autoClose: true,
                })
            });
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setMyWork({
            ...myWork,
            [name]: type === 'checkbox' ? checked : value,
        });
    };
  return (
      <div className="max-w-md mx-auto p-6 bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-1">Edit Work History</h2>
          <form onSubmit={handleSubmit}>
              <div className='mb-1'>
                  <label htmlFor="price" className="block text-gray-600">Country:</label>
                  <input type="text" id="country" name="country" value={myWork.country} onChange={handleChange} className="w-full p-2 border rounded-md" />

              </div>

              <div className="mb-1">
                  <label htmlFor="title" className="block text-gray-600">Establishment:</label>
                  <input
                      type="text"
                      id="company"
                      name="company"
                      value={myWork.company}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                  />
              </div>
              <div className='mb-1'>
                  <label htmlFor="price" className="block text-gray-600">Position:</label>
                  <input type="text" id="position" name="position" value={myWork.position} onChange={handleChange} className="w-full p-2 border rounded-md" />

              </div>
              <div className='mb-1'>
                  <label htmlFor="price" className="block text-gray-600">Description:</label>
                  <textarea type="text" id="summary" name="summary" value={myWork.summary} onChange={handleChange} className="w-full p-2 border rounded-md" />
              </div>
              <div className="mb-1">
                  <label htmlFor="title" className="block text-gray-600">Start Date:</label>
                  <input
                      type="date"
                      id="start_date"
                      name="start_date"
                      value={myWork.start_date}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                  />
              </div>
              <div className="mb-1">
                  <input type="checkbox"
                      value={myWork.still_working}
                      name="still_working"
                      onChange={
                          (e) => {
                              setMyWork({
                                  ...myWork,
                                  still_working: e.target.checked,
                              })
                          }

                      }
                      id="myCheckbox" className="rounded-lg p-2 text-indigo-500 bg-white border-2 border-indigo-400 hover:bg-indigo-100" />
                  <span className="ml-2 text-sm text-gray-600">Still Working</span>
              </div>
              <div className="mb-1">
                  <label htmlFor="title" className="block text-gray-600">End Date:</label>
                  <input
                      type="date"
                      id="end_date"
                      disabled={myWork.still_working}
                      name="end_date"
                      value={myWork.end_date}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                  />
              </div>

              <div className="mt-4">
                  <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                     Edit Work History
                  </button>
              </div>
          </form>
      </div>
  )
}

export default EditWorkExperience