import axios from 'axios';

export const API_URL = "https://api.tantorial.com/";
export const IMG_URL = "https://api.tantorial.com";

// export const API_URL = "http://127.0.0.1:8000/";
// export const IMG_URL = "http://127.0.0.1:8000";

// const token = localStorage.getItem('jwtToken');

export const apiInstance = axios.create({
    baseURL: API_URL,
});

apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


// export const apiInstance = axios.create({
//     baseURL: API_URL,
//     timeout: 5000,
//     headers: {
//         Authorization: token ? `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY5NzM2OTQ5OSwiaWF0IjoxNjk2NzY0Njk5LCJqdGkiOiI5Njk3NzliZDk5YTE0ZjFkOWI2ZGVmNjMxNjIxYzE0MiIsInVzZXJfaWQiOjIsImVtYWlsIjoidGFudG9yaWFsQHRhbnRvcmFpbC5jb20iLCJwaG9uZV9udW1iZXIiOiI2NzQxMjg1NzMiLCJ0dXRvciI6ZmFsc2V9.u-2tX9m2si3RdgJ9McdB1F7Ck1cDsYydUI9cZ90ZafY` : null,
//         'Content-Type': 'application/json',
//         accept: 'application/json',
//     },
// });