import React from "react";
import { IMG_URL } from "../../config/api";
import FavouriteButton from "../partials/FavouriteButton";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import Rating from "../partials/Rating";
import TutorLessonCount from "../partials/TutorLessonCount";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import BookLesson from "./book/BookLesson";

function BasicInfo({ profile }) {
    const [opened, { open, close }] = useDisclosure(false);
    return (
        <div className="mt-6 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 bg-white rounded-lg shadow-lg">
            <Modal opened={opened} onClose={close}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}>
                <BookLesson first_name={profile && profile?.profilepersonal[0]?.first_name}
                tutor_id={profile && profile?.profilepersonal[0]?.user?.id}
                onClose={close}
                 />
            
            </Modal>

            <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
                <img
                    src={IMG_URL + (profile && profile?.profilepersonal[0]?.profile_pic)}
                    alt={profile && profile?.profilepersonal[0]?.first_name}
                    className=" w-full object-cover object-center rounded-full p-5"
                    title={profile && profile?.profilepersonal[0]?.profile_pic}
                />
            </div>
            <div className="lg:mt-0 lg:col-span-2 py-5 px-3">
                <div className="flex flex-row">
                    <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        {profile && profile?.profilepersonal[0]?.first_name}{" "}
                        {profile && profile?.profilepersonal[0]?.last_name}
                    </h1>
                    <CheckBadgeIcon
                        className="mx-2 mt-2 h-5 w-5 flex-shrink-0 text-blue-400"
                        title="Verified"
                    />
                    <FavouriteButton 
                        likedUserId={profile && profile?.profilepersonal[0]?.user?.id}
                        isFavorite={profile && profile?.is_favorite}
                    />
                </div>
                <Rating 
                    rating={profile && profile?.avg_rating}
                    numberOfReviews={profile && profile?.num_reviews}
                />
                <TutorLessonCount />
                <div>
                    <h3 className="sr-only">Address</h3>

                    <div className="flex flex-row gap-4 sm:flex-row">
                        <div className="flex items-center text-sm text-gray-500">
                            <p className="text-lg font-medium text-gray-900">
                                {profile && profile?.profilepersonal[0]?.country?.title},{" "}
                                {profile && profile?.profilepersonal[0]?.region?.title},{" "}
                                {profile && profile?.profilepersonal[0]?.town?.title},{" "}
                                {profile && profile?.profilepersonal[0]?.address}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row">
                    <button
                        onClick={open}
                        className="mt-2 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white hover:bg-indigo-700"
                    >
                        Book this tutor
                    </button>
                    {/* <button
                        type="submit"
                        className="ml-3 mt-2 items-center justify-center rounded-md border border-transparent bg-pink-400 px-8 py-2 text-base font-medium text-white hover:bg-pink-700"
                    >
                        Message
                    </button> */}
                </div>

                <h2
                    className="text-xl font-semibold leading-8 tracking-tight mb-3"
                    style={{
                        whiteSpace: "pre-line",
                        textAlign: "justify",
                    }}
                >
                    {profile && profile?.profilesummary[0]?.profile_summary.slice(0, 500)}
                </h2>
                <h1 className="text-xl font-bold leading-8 tracking-tight mb-3">
                    Accomplishment
                </h1>
                <h3
                    className="text-black-500 text-base leading-8 tracking-tight mb-3"
                    style={{
                        whiteSpace: "pre-line",
                        textAlign: "justify",
                    }}
                >
                    {profile && profile?.profilesummary[0]?.accomplishment}
                </h3>
            </div>
        </div>
    );
}

export default BasicInfo;
