import React, { useState, useEffect } from 'react'
import { apiInstance } from '../config/api'
import { notifications } from '@mantine/notifications';
import { Link } from 'react-router-dom';

function FavouritePage() {
    const [myFavourites, setMyFavourites] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getFavourites = async () => {
            try {
                const response = await apiInstance.get('like/favourites');
                console.log(response)
                setMyFavourites(response.data.results);
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        getFavourites();
    }
        , []);

    const handleDeleteFavorite = async (likedUserId) => {
        try {
            const response = await apiInstance.post('like/favourites/remove_from_favorites/', { liked_user_id: likedUserId });
            if (response.status === 200) {
                // Update the state and trigger a callback
                setMyFavourites(myFavourites.filter((fav) => fav.liked.id !== likedUserId));
                notifications.show({
                    title: 'Remove Tutor for Saved',
                    message: 'Successfully removed',
                    color: 'green',
                    autoClose: true,

                })
            } else {
                // Handle other response statuses, if needed
                console.error('Unexpected response status:', response.status);
                notifications.show({
                    title: 'Default notification',
                    message: 'Hey there, your code is awesome! 🤥',
                    color: 'red',
                    autoClose: true,
                })
            }

        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-2xl font-semibold mb-4">My Saved Tutors</h1>
            {
                myFavourites.length ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">

                        {myFavourites?.map((fav, index) => (
                            <div className="bg-white rounded-lg p-4 shadow-md" key={index}>

                                <img src="user1.jpg" alt="User 1" className="w-24 h-24 rounded-full mx-auto mb-4" />

                                <h2 className="text-lg font-semibold text-center">User 1</h2>

                                <p className="text-gray-500 text-center">Location: City, Country</p>
                                <p className="text-gray-500 text-center">Joined: January 1, 2022</p>

                                <i className="text-red-500 fas fa-heart cursor-pointer text-xl mt-4"></i>
                                <button onClick={() => handleDeleteFavorite(fav?.liked?.id)}>
                                    Remove
                                </button>
                            </div>

                        ))}

                    </div>)
                    : (
                        <div className="h-screen flex flex-col items-center mt-12 ">
                            <img
                                src="https://static.preply.com/space/images/no-tutorings-state.dafce7c556bcf76bcca0f9adc27a720c.png" // Replace with the path to your Disney-themed logo or image
                                alt="Tantorial"
                                className="w-64 mb-8"
                            />
                            <h4 className="text-xl font-bold mb-2">Your Favourite Tutors Will Appear here</h4>
                            <p className="text-lg mb-4">Find tutors to get started</p>
                            <Link to="/" className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-semibold py-2 px-4 rounded-full hover:shadow-md transition duration-300 ease-in-out">
                                See All Tutors
                            </Link>
                        </div>
                    )
            }
        </div>
    )
}

export default FavouritePage