import React from 'react'
import StarRating from "react-star-ratings";

function Rating({ rating, numberOfReviews }) {
    return (
        <div>
            <p className="text-sm text-gray-600 flex items-center">
                <p className="text-gray-600 mb-2">
                    <StarRating
                        rating={rating ? rating : 0}
                        starRatedColor="gold"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="2px"
                    />
                </p>
                <p className="text-lg font-bold px-1">{rating} Star</p>
                <span className="text-lg font-semibold px-1">
                    {numberOfReviews} Reviews
                </span>
            </p>
           
        </div>

    )
}

export default Rating