import React from 'react'

const TEAM = [
{
  name: 'Parkson Tano Daniel',
  position: 'Founder & CEO',
    photo: 'https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/402650366_2496780370504100_1397804746372959801_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH_QvkQZfmDec9R2X5EOr5-nig2kvU3DeyeKDaS9TcN7Knv6PL31OARQj5N6Im_AbEM6HNPNT3FFcCIHIuNhqZl&_nc_ohc=NW7F7ViGUkEAX9N5APe&_nc_ht=scontent-lhr6-2.xx&oh=00_AfCfleS4VWudwXr6hYkb28A52OjIIh7DqTYuSJPp08jveA&oe=65630DB3',
  social: {
    facebook: 'https://www.facebook.com/tantorial',
    twitter: 'https://twitter.com/tantorial',
    instagram: 'https://www.instagram.com/tantorial',
    linkedin: 'https://www.linkedin.com/company/tantorial'
  }
},
  {
    name: 'Parkson Tano Daniel',
    position: 'Founder & CEO',
    photo: 'https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/402650366_2496780370504100_1397804746372959801_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH_QvkQZfmDec9R2X5EOr5-nig2kvU3DeyeKDaS9TcN7Knv6PL31OARQj5N6Im_AbEM6HNPNT3FFcCIHIuNhqZl&_nc_ohc=NW7F7ViGUkEAX9N5APe&_nc_ht=scontent-lhr6-2.xx&oh=00_AfCfleS4VWudwXr6hYkb28A52OjIIh7DqTYuSJPp08jveA&oe=65630DB3',
    social: {
      facebook: 'https://www.facebook.com/tantorial',
      twitter: 'https://twitter.com/tantorial',
      instagram: 'https://www.instagram.com/tantorial',
      linkedin: 'https://www.linkedin.com/company/tantorial'
    }
  },
  {
    name: 'Parkson Tano Daniel',
    position: 'Founder & CEO',
    photo: 'https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/402650366_2496780370504100_1397804746372959801_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH_QvkQZfmDec9R2X5EOr5-nig2kvU3DeyeKDaS9TcN7Knv6PL31OARQj5N6Im_AbEM6HNPNT3FFcCIHIuNhqZl&_nc_ohc=NW7F7ViGUkEAX9N5APe&_nc_ht=scontent-lhr6-2.xx&oh=00_AfCfleS4VWudwXr6hYkb28A52OjIIh7DqTYuSJPp08jveA&oe=65630DB3',
    social: {
      facebook: 'https://www.facebook.com/tantorial',
      twitter: 'https://twitter.com/tantorial',
      instagram: 'https://www.instagram.com/tantorial',
      linkedin: 'https://www.linkedin.com/company/tantorial'
    }
  },
  {
    name: 'Parkson Tano Daniel',
    position: 'Founder & CEO',
    photo: 'https://scontent-lhr6-2.xx.fbcdn.net/v/t39.30808-6/402650366_2496780370504100_1397804746372959801_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeH_QvkQZfmDec9R2X5EOr5-nig2kvU3DeyeKDaS9TcN7Knv6PL31OARQj5N6Im_AbEM6HNPNT3FFcCIHIuNhqZl&_nc_ohc=NW7F7ViGUkEAX9N5APe&_nc_ht=scontent-lhr6-2.xx&oh=00_AfCfleS4VWudwXr6hYkb28A52OjIIh7DqTYuSJPp08jveA&oe=65630DB3',
    social: {
      facebook: 'https://www.facebook.com/tantorial',
      twitter: 'https://twitter.com/tantorial',
      instagram: 'https://www.instagram.com/tantorial',
      linkedin: 'https://www.linkedin.com/company/tantorial'
    }
  },
]


function Team() {
  return (
    <section class="flex items-center h-100 bg-stone-100 xl:h-screen font-poppins dark:bg-gray-600 ">
      <div class="justify-center flex-1 px-4 py-6 mx-auto max-w-7xl lg:py-4 md:px-6">
        <div class="text-center mb-14">
          <span
            class="block mb-4 text-xl font-semibold leading-4 tracking-widest text-center text-blue-500 uppercase dark:text-gray-400">
            Team
          </span>
          <h1 class="text-3xl font-bold capitalize dark:text-white"> Meet Our Wonderful Team </h1>
        </div>
        <div class="flex flex-wrap justify-center -mx-4">
         {
          TEAM?.map((item, index) => (
            <div key={index} class="w-full px-4 mb-10 sm:w-1/2 lg:w-1/3 xl:w-1/4 ">
              <div class="mx-auto text-center ">
                <div
                  class="inline-block mb-3 overflow-hidden text-xs text-white bg-blue-500 rounded-full w-44 h-44 sm:w-64 sm:h-64">
                  <img class="object-cover w-full h-full transition-all hover:scale-110"
                    src={item.photo} alt={item.name} />
                </div>
                <h2 class="mb-2 text-xl font-semibold text-gray-800 dark:text-gray-300">
                  {item.name}
                </h2>
                <span
                  class="inline-block mb-6 text-base font-medium text-blue-500 dark:text-gray-400">
                    {item.position}
                </span>
                <div class="flex items-center justify-center">
                  <a class="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" target='_blank' rel='noreferrer' href={item.social.facebook}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="w-6 h-6 text-blue-800 bi bi-facebook dark:text-gray-400" viewBox="0 0 16 16">
                      <path
                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </a>
                  <a class="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" target='_blank' rel='noreferrer' href={item.social.twitter}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="w-6 h-6 text-blue-500 dark:text-gray-400 bi bi-twitter" viewBox="0 0 16 16">
                      <path
                        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </a>
                  <a class="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" target='_blank' rel='noreferrer' href={item.social.linkedin}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="w-6 h-6 text-blue-500 dark:text-gray-400 bi bi-linkedin" viewBox="0 0 16 16">
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                    </svg>
                  </a>
                  <a class="inline-block text-coolGray-300 hover:text-coolGray-400" target='_blank' rel='noreferrer' href={item.social.instagram}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="w-6 h-6 text-pink-600 dark:text-gray-400 bi bi-instagram"
                      viewBox="0 0 16 16">
                      <path
                        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          ))
         }
          

        </div>
      </div>
    </section>
  )
}

export default Team