import React, { useState, useEffect } from 'react'
import { apiInstance } from '../../../config/api'
import { notifications } from '@mantine/notifications';


function EditEducation({ onClose, setData, editItem }) {
  const [submitting, setSubmitting] = useState(false);
  const [myEducation, setMyEducation] = useState([]);

  useEffect(() => {
    apiInstance.get('profile/education/' + editItem?.id + '/')
      .then((res) => {
        console.log(res);
        setMyEducation(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [editItem]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { user, ...newData } = myEducation;
    apiInstance.patch('profile/education/' + editItem?.id + '/', newData)
      .then((res) => {
        console.log(res.data);
        setData((prevData) => prevData.map((item) => item.id === res.data.id ? res.data : item))
        notifications.show({
          title: 'Education Edited',
          message: 'Successfully Edited Education',
          color: 'green',
          autoClose: true,
        })
        onClose();
      })
      .catch((err) => {
        console.log(err.response);
        notifications.show({
          title: 'Error Editing Education',
          message: err.response.data.error,
          color: 'red',
          autoClose: true,
        })
      });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMyEducation({
      ...myEducation,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-1">Edit Education</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Country:</label>
          <input type="text" id="country" name="country" value={myEducation.country} onChange={handleChange} className="w-full p-2 border rounded-md" />
          {/* <CreatableSelect
            name="Country"
            options={
              fixedData(country)
            }
            isSearchable
            placeholder="Country"
            defaultValue={myEducation?.country?.id}
            onChange={(e) => {
              setMyEducation({
                ...myEducation,
                country: e.value,
              })
            }
            }

          /> */}
        </div>

        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">School:</label>
          <input
            type="text"
            id="school"
            name="school"
            value={myEducation.school}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Field of Study:</label>
          <input type="text" id="field_of_study" name="field_of_study" value={myEducation.field_of_study} onChange={handleChange} className="w-full p-2 border rounded-md" />
          {/* <CreatableSelect
            name="Field of Study"
            options={
              fixedData(fieldOfStudy)
            }
            isSearchable
            placeholder="Field of Study"
            onChange={(e) => {
              setMyEducation({
                ...myEducation,
                field_of_study: e.value,
              })
            }
            }

          /> */}
        </div>
        <div className='mb-1'>
          <label htmlFor="price" className="block text-gray-600">Degree:</label>
          <input type="text" id="degree" name="degree" value={myEducation.degree} onChange={handleChange} className="w-full p-2 border rounded-md" />
          {/* <CreatableSelect
            name="Degree"
            options={
              fixedData(degree)
            }
            isSearchable
            placeholder="Degree"
            onChange={(e) => {
              setMyEducation({
                ...myEducation,
                degree: e.value,
              })
            }
            }

          /> */}
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">Start Date:</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            value={myEducation.start_date}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className="mb-1">
          <input type="checkbox"
            value={myEducation.still_studying}
            name="still_studying"
            onChange={
              (e) => {
                setMyEducation({
                  ...myEducation,
                  still_studying: e.target.checked,
                })
              }

            }
            id="myCheckbox" className="rounded-lg p-2 text-indigo-500 bg-white border-2 border-indigo-400 hover:bg-indigo-100" />
          <span className="ml-2 text-sm text-gray-600">Still Studying</span>
        </div>
        <div className="mb-1">
          <label htmlFor="title" className="block text-gray-600">End Date:</label>
          <input
            type="date"
            id="end_date"
            disabled={myEducation.still_studying}
            name="end_date"
            value={myEducation.end_date}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
          />
        </div>

        <div className="mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Edit Education
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditEducation