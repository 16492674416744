import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from '../layout/home/MainLayout'
import AboutLearner from "../pages/tutoringProfile/AboutLearner";
import LearnerNeed from "../pages/tutoringProfile/forKid/LearnerNeed";
// import AddressInfo from "../pages/tutoring/AddressInfo";
// import ContactInfo from "../pages/tutoring/ContactInfo";
import KidLearner from "../pages/tutoringProfile/forKid/KidLearner";
import MySelfLearner from "../pages/tutoringProfile/forSelf/MySelfLearner";
import PreferTutor from "../pages/tutoringProfile/forKid/PreferTutor";
import LessonSchedule from "../pages/tutoringProfile/forKid/LessonSchedule";
function TutoringRoute() {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route index element={<AboutLearner />} />   
                <Route path="about-learner" element={<AboutLearner />} />
                <Route path="learner-need" element={<LearnerNeed />} />
                <Route path="for-kids" element={<KidLearner />} />
                <Route path="for-me" element={<MySelfLearner />} />
                <Route path="prefer-tutor" element={<PreferTutor />} />
                <Route path="lesson-schedule" element={<LessonSchedule />} />
            </Route>
        </Routes>
    )
}

export default TutoringRoute;